import React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import {
  LOGIN_SUCCESS,
  LOGOUT,
  SET_CATEGORY,
  SET_MENU,
  SET_BOOK,
  SET_SOFTWARE,
} from "../redux/actions/types";
import {
  currentSubcription,
  getNotifications,
  mainMenu,
  profil,
  allCategories,
} from "../services/main.service";

import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import Moment from "react-moment";
import "moment/locale/fr";
import { STORED_USER_KEY } from "../constants/app";
import swal from "sweetalert";

const Header = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [software, setSoftware] = React.useState(props.software);
  const [cookies, setCookie] = useCookies(["token"]);
  const [notifications, setNotifications] = React.useState([]);
  const history = useHistory();

  const logOut = (e) => {
    e.preventDefault();
    dispatch({
      type: LOGOUT,
      payload: {},
    });
    localStorage.setItem(STORED_USER_KEY, "");
    setCookie("token", "", { path: "/", maxAge: 0 });
    history.push("/");
  };

  const loginModal = () => {
    swal(t("common.login"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/login");
    });
  };
  React.useEffect(() => {
    if (cookies.token) {
      // TODO: move this to the approute components
      profil()
        .then((res) => {
          if (res.status == 200) {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { user: res.data },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      

      getNotifications().then((res) => {
        if (res.status == 200) {
          setNotifications(res.data.results);
        }
      });
    } else {
      localStorage.setItem("token", "");
      dispatch({
        type: LOGOUT,
        payload: {},
      });
    }
    if (!props.book) {
      mainMenu().then((res) => {
        if (res) {
          const datas = res.data?.results;

          for (let i = 0; i < datas?.length; i++) {
            if (datas[i].type == "DOCUMENT") {
              localStorage.setItem("livre", datas[i].pk);
              dispatch({
                type: SET_CATEGORY,
                payload: datas[i].pk,
              });
              dispatch({
                type: SET_BOOK,
                payload: datas[i],
              });
            } else if (datas[i].type == "SOFTWARE") {
              dispatch({
                type: SET_SOFTWARE,
                payload: datas[i],
              });
              setSoftware(datas[i]);
              localStorage.setItem("logiciel", datas[i].pk);
            }
          }
        }
      });
    }
    if (!props.menus) {
      allCategories().then((res) => {
        if (res.status == 200) {
          dispatch({
            type: SET_MENU,
            payload: res.data,
          });
        }
      });
    }
    $(".current_choice").click(function () {
      $(this).next("ul").slideToggle();
    });
    $(".current_choice + ul li").click(function () {
      var content = $(this).text();
      i18n.changeLanguage(content.toLowerCase());
      localStorage.setItem("lng", content.toLowerCase());
      $(this).parent("ul").prev(".current_choice").find("span").text(content);
      window.location = "";
    });
    $(document).on("click", ".addmenuicon", function () {
      var h_height = $("#header").outerHeight();
      $("body").toggleClass("menuhasopen");
      $(this).toggleClass("open");
      $(this).addClass("removemenuicon");
      $(this).next("#navbarNav").show().css({ "padding-top": h_height });
      $(this).removeClass("addmenuicon");
    });
    $(document).on("click", ".removemenuicon", function () {
      var h_height = $("#header").outerHeight();
      $("body").toggleClass("menuhasopen");
      $(this).toggleClass("open");
      $(this).addClass("addmenuicon");
      $(this).next("#navbarNav").hide().css({ "padding-top": h_height });
      $(this).removeClass("removemenuicon");
    });
  }, [props.loggedIn]);
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <header id="header" className="header">
        <div className="container">
          <div className="header_cover displayflex">
            <nav className="navbarsite displayflex">
              <div className="navleft displayflex">
                <div className="logosite navitem">
                  <Link to="/" title="Dastudy">
                    <img
                      className="lazy"
                      src="/assets/images/logo.png"
                      alt="Dastudy"
                    />
                  </Link>
                </div>

                <div className="langue_site navitem">
                  <label className="current_choice">
                    <span>{i18n.language == "en" ? "En" : "Fr"}</span>
                    <i className="bi bi-chevron-down"></i>
                    <i className="bi bi-chevron-up"></i>
                  </label>
                  <ul className="submenu">
                    <li>Fr</li>
                    <li>En</li>
                  </ul>
                </div>
              </div>
              <div
                id="menuicon"
                className="menuicon addmenuicon labelnav onmobile">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="navright navbarNav displayflex navitem"
                id="navbarNav">
                <div className="menusite">
                  <ul className="navbarmenu displayflex">
                    <li
                      className={
                        props.menu == 1 ? "nav-item active" : "nav-item"
                      }>
                      <Link to="/" className="nav-link">
                        {t("header.home")}
                      </Link>
                    </li>
                    <li
                      className={
                        props.menu == 2 ? "nav-item active" : "nav-item"
                      }>
                      <Link to={"/documents/"} className="nav-link">
                        {t("header.documents")}
                      </Link>
                    </li>

                    {/* <li
                      className={
                        props.menu == 4 ? "nav-item active" : "nav-item"
                      }
                    >
                      <Link to={software && "/softwares/"} className="nav-link">
                        {t("header.softwares")}
                      </Link>
                    </li> */}
                    <li
                      className={
                        props.menu == 6 ? "nav-item active" : "nav-item"
                      }>
                      <Link
                        to={"/analyse-upload/"}
                        className="nav-link">
                        {t("header.analyse")}
                      </Link>
                    </li>

                    <li
                      className={
                        props.menu == 5
                          ? "forum nav-item active"
                          : "forum nav-item"
                      }>
                      <Link className="nav-link " to={"/discussion"}>
                        {t("header.discussion")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="header_link">
                  <ul className="displayflex">
                    {props.loggedIn && (
                      <li
                        className={
                          props.menu == 6
                            ? "uploader menuhaschildren active"
                            : "uploader menuhaschildren"
                        }>
                        <Link className="nav-link" to="/upload-type">
                          {t("header.upload")}
                        </Link>
                      </li>
                    )}

                    {!props.loggedIn && (
                      <li className="uploader menuhaschildren">
                        <Link to="#" onClick={() => loginModal()}>
                          {" "}
                          {t("header.upload")}
                        </Link>
                      </li>
                    )}

                    <li
                      className={
                        props.menu == 7 ? "faire_don active" : "faire_don"
                      }>
                      <Link className="nav-link" to="/donate">
                        {t("header.don")}
                      </Link>
                    </li>
                    {!props.loggedIn && (
                      <li className="signin">
                        <Link
                          className="nav-link"
                          to="/login"
                          onClick={() => localStorage.setItem("menu", 1)}>
                          {t("header.signin")}
                        </Link>
                      </li>
                    )}

                    {props.loggedIn && (
                      <li
                        className={
                          props.menu == 8
                            ? "user_connect displayflex active"
                            : "user_connect displayflex"
                        }>
                        <Link
                          className="nav-link displayflex"
                          to="/account"
                          title="#">
                          <span className="user_picture">
                            <img
                              className="lazy"
                              src={
                                props.user?.profile_picture
                                  ? props.user?.profile_picture
                                  : "/assets/images/avatar.png"
                              }
                              style={{
                                objectFit: "cover",
                                height: "100%",
                                width: "100%",
                              }}
                              alt="Dastudy"
                            />
                            <span className="nb_notifs">
                              {notifications.length}
                            </span>
                          </span>
                          <span className="user_name">
                            {" "}
                            {props?.user?.first_name}
                          </span>
                        </Link>
                        <div
                          className="submenu"
                          style={{
                            maxHeight: "500px",
                            overflowY: "auto",
                            marginLeft: "-30px",
                          }}>
                          <div className="menu_connect">
                            <label className="label_menu">Menu</label>
                            <ul className="">
                              <li>
                                <Link
                                  to="/personal-information"
                                  title={t("header.finish")}>
                                  {t("header.finish")}
                                </Link>
                              </li>
                              {!props.isActiveSubcription && (
                                <li>
                                  <Link
                                    to="/subscription-plan"
                                    title={t("header.gotopremium")}>
                                    {t("header.gotopremium")}
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link
                                  to="/account"
                                  title={t("header.dashboad")}>
                                  {t("header.dashboad")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  title={t("common.logout")}
                                  onClick={(e) => logOut(e)}>
                                  {t("common.logout")}
                                </Link>
                              </li>
                            </ul>
                          </div>

                          <div className="menu_notifications">
                            <label className="label_menu">
                              {t("header.notification")}
                            </label>
                            <div className="list_notifs">
                              <div className="notif_item">
                                <a
                                  href={"/discussion"}
                                  style={{ color: "#000" }}>
                                  {t("header.notification_chat")}
                                </a>
                              </div>
                              {notifications.map((notif) => (
                                <div className="notif_item">
                                  {notif.verb}
                                  <div className="notif_time">
                                    {" "}
                                    <Moment locale={i18n.language} fromNow>
                                      {notif.timestamp}
                                    </Moment>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {/* <div className="more_notifs">
                              <a href="#" title="Voir plus de notifications">
                                {t("header.notification_more")}
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default connect((state) => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
  book: state.menus.book,
  software: state.menus.software,
  menus: state.menus.menus,
  isActiveSubcription: state.auth.isActiveSubcription,
}))(Header);
