import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";

import SidebarFilter from "../../../components/SidebarFilter";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import BookContent from "../../../components/app/books/BookContent";
import BookItem from "../../../components/items/BookItem";
import { documents } from "../../../services/main.service";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
const $ = window.jQuery;

const Books = (props) => {
  const [data, setData] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const { t } = useTranslation();
  const init=()=>{
    $(".sliderecentsposts").slick({
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: true,
      centerPadding: "150px",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "100px",
          },
        },
      ],
    });
  }
  React.useEffect(() => {
    documents(1).then((res) => {
      setLoad(false);
      if (res.status == 200){
         setData(res.data.results);
         init();
      }
      
    });
  }, []);
  return (
    <>
      <Header menu={2} title={t("common.books")} />

      <div id="content" className="content page_categorie page_documents">
        <div className="contentbar displayflex">
          <SidebarFilter path="books" />
          <BookContent />
          <SidebarAdd type="book" />
        </div>
        <div
          id="logigiels"
          className="logiciels homebloc slidebloc slidepadding"
        >
          <div className="container">
            <div className="homebloc_cover">
              <h2 className="homebloctitle">
                <span>
                  {t("common.similar_doc")}
                </span>
              </h2>
              <div className="homebloc_content">
                <div className="sliderecentsposts">
                  {data.map((item, i) => (
                    <BookItem item={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(Books);
