import {
    useLocation
  } from "react-router-dom";
  
const setLocalStorageItem = (key, value) => localStorage.setItem(key, value)

const getLocalStorageItem = (key) => localStorage.getItem(key)

const removeLocalStorageItem = (key) => localStorage.removeItem(key)

const removeAllLocalStorageItems = () => localStorage.clear()
const  useQuery =() => {
    return new URLSearchParams(useLocation().search);
  }
export const stringToSlug =(str) =>{
    str = str?.replace(/^\s+|\s+$/g, ''); // trim
    str = str?.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str?.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str?.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        ?.replace(/\s+/g, '-') // collapse whitespace and replace by -
        ?.replace(/-+/g, '-'); // collapse dashes

    return str;
}
 
export default {
    setLocalStorageItem,
    getLocalStorageItem,
    removeLocalStorageItem,
    removeAllLocalStorageItems,
    useQuery,    
}

