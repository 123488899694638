import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";
const Success = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header title="Payement reussi" />
      <div id="content" className="content page_faq">
        <div className="container">
          <div className="fa_content">
            <div className="faq_title">
            <Alert severity="success" className="mtb-32">
                Votre Payement a été éffectué avec succès. Merci pour votre confiance
            </Alert>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Success;
