import React from "react";
import {
  followUser,
  isFollow,
  unFollowUser,
  userPageId,
} from "../services/main.service";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

const UserFollow = (props) => {
  const [page, setPage] = React.useState();
  const [follow, setFollow] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  let history = useHistory();

  React.useEffect(() => {
    isFollow(props?.owner?.username).then((res) => {
      if (res.status == 200) {
        setFollow(res.data.following);
      }
    });

    userPageId(props?.owner?.username).then((res) => {
      if (res.status == 200) {
        setPage(res.data);
      }
    });
  }, [refresh]);

  const loginModal = () => {
    swal(t("common.login"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/login");
    });
  };

  const followOrUnfollow = (e) => {
    e.preventDefault();
    if (props?.user) {
      if (follow) {
        unFollowUser(props?.owner?.id).then((res) => {
          setRefresh(!refresh);
          setFollow(false);
        });
      } else {
        followUser(props?.owner?.id).then((res) => {
          setRefresh(!refresh);
          setFollow(true);
        });
      }
    } else {
      loginModal();
    }
  };

  const share = () => {
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    swal({
      title: "Partager la page",
      text: link,
      button: {
        text: "Copier le lien",
      }
    });
  };

  return (
    <>
      {props?.owner?.last_name != "Admin" && (
        <>
          <div className="lguser_infos">
            {!props.show && t("common.by")}{" "}
            {!props.show && (
              <strong>
                <Link to={"/" + props?.owner?.username}>
                  {props?.owner?.first_name} {props?.owner?.last_name}
                </Link>
              </strong>
            )}
            <span>
              {" " + (page?.follower_count || 0)} {t("common.follower")}{" "}
            </span>
          </div>

          <div
            className="lguser_button displayflex"
            style={props.margin && { marginTop: props.margin }}
          >
            {props.user && follow ? (
              <a
                className="borderbuton"
                href="#"
                title=""
                onClick={(e) => followOrUnfollow(e)}
              >
                {t("common.follow")}
              </a>
            ) : (
              <a
                className="blue_button"
                href="#"
                onClick={(e) => followOrUnfollow(e)}
              >
                {t("common.subscribe")}
              </a>
            )}
            <div className="lguser_btn">
              <div onClick={() => share()} className="share_btn" style={{cursor: "pointer"}}>
                <a title="#"></a>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(UserFollow);
