import React, { useEffect, useState } from "react";
import useAiChatAssistant from '../hooks/useAiChatAssistant';


export default function AiChatAssistant() {

    // const token = localStorage.getItem('token')
    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzEwNDgwODI2LCJpYXQiOjE3MDkyNzEyMjYsImp0aSI6ImZlNjMzZTgyMDE3YTQ5MmM4YTZlNWFjYzkxN2YwYzc1IiwidXNlcl9pZCI6IjZiMjkwYzgzLWU2MWYtNDJjZi05MDczLTEzNzdiOTc0NTJhMyJ9.FL-8n-91c8_MipgGUujMy9kPDZNOx0rfb4DALO6kDhw"
    const [text, setText] = useState('')

    const { ask, isConnected, messageHistory, message } = useAiChatAssistant({
        authToken: token,
        onError: (e) => console.log({ e }),
    })

    const onSubmit = (e) => {
        e.preventDefault();
        ask(text, "FR", "e3e86bc1-69a1-4d6a-a049-9d88d1d2060d")
    }

    useEffect(
        () => console.log({ message }),
        [message]
    )


    return (
        <div>
            <h3>Ici</h3>
            <span>{message}</span>
            <hr />
            {messageHistory.map((item, id) => <div key={id}> {item}</div>)}
            <form onSubmit={onSubmit}>
                <input disabled={!isConnected} required type='text' value={text} onChange={(e) => setText(e.target.value)} />
                <button disabled={!isConnected} type="submit">Submit</button>
            </form>
        </div>
    )
}