import React, { Component } from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import {
  allCategories,
  deleteDocument,
  getLink,
  getMyUploads,
  languages,
  updateDocument,
  useQuery,
  userPageContent,
} from "../../../services/main.service";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import $ from "jquery";
import swal from "sweetalert";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const Uploads = (props) => {
  const [myUploads, setMyUploads] = React.useState();
  const [data, setData] = React.useState([]);
  const [type, setType] = React.useState("");
  const [load, setLoad] = React.useState(true);
  const [image, setImage] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [full, setFull] = React.useState("");
  const [item, setItem] = React.useState();
  const [isFree, setIsfree] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [isPrivate, setIsPrivate] = React.useState(false);
  const [capture, setCapture] = React.useState("");
  const [typeLink, setTypeLink] = React.useState("documents/");

  const query = useQuery();
  const { t } = useTranslation();
  const history = useHistory();
  const { pageId } = useParams();

  const [langues, setLangues] = React.useState([]);
  React.useEffect(() => {
    if (type && myUploads) {
      if (type == "book") setData(myUploads.documents);
      else if (type == "video") setData(myUploads.videos);
      else if (type == "software") setData(myUploads.software);
      allCategories(
        type == "book"
          ? localStorage.getItem("livre")
          : type == "video"
          ? localStorage.getItem("tutoriel")
          : localStorage.getItem("logiciel")
      ).then((res) => {
        if (res.status == 200) setCategories(res.data);
      });
    } else {
      if (pageId) {
        userPageContent(pageId).then((res) => {
          if (res.status == 200) {
            setMyUploads(res.data);
            setData(res.data?.documents);
            setType("book");
            setLoad(false);
          }
        });
      } else {
        getMyUploads().then((res) => {
          if (res.status == 200) {
            setMyUploads(res.data);
            setData(res.data?.documents);
            setType("book");
            setLoad(false);
          }
        });
      }
      languages().then((res) => {
        if (res.status == 200) setLangues(res.data.results);
      });
    }
  }, [type]);

  const refresh = () => {
    getMyUploads().then((res) => {
      if (res.status == 200) setMyUploads(res.data);
      setData(res.data?.documents);
      if (type == "book") setData(res.data?.documents);
      else if (type == "video") setData(res.data?.videos);
      else if (type == "software") setData(res.data?.software);
      setLoad(false);
    });
  };
  const priceStatus = (val) => {
    if (val == "payant") {
      setIsfree(true);
      dev();
    } else setIsfree(true);
  };

  const dev = () => {
    swal("Cette fonctionalité est en cours de dévélopement!", {
      icon: "info",
    });
  };
  const handleChange = (val) => {
    if (props.isActiveSubcription) {
      setIsPrivate(val);
    } else if (val) {
      setIsPrivate(false);
      paymentModal();
    } else setIsPrivate(false);
  };
  const handleClick = (e, item) => {
    e.preventDefault();
    if (item.is_validated) {
      history.push(
        typeLink + item?.category_obj?.slug + "/detail/" + item.slug
      );
    } else {
      swal(
        `Ce ${
          typeLink == "documents/" ? "document" : "logiciel"
        } n'est pas encore approuvé par l'administration!`,
        {
          icon: "info",
        }
      );
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const datas = new FormData(e.target);
    setLoading(true);
    updateDocument(datas, type, item.id)
      .then((res) => {
        setLoading(false);
        if (res.status == 200) {
          NotificationManager.success(
            "Vos données ont été mis a jour avec succès"
          );
          refresh();
          $(".close").click();
        } else {
          NotificationManager.error("Erreur survenue lors de l'upload");
        }
      })
      .catch((err) => {
        NotificationManager.error("Erreur survenue lors de l'upload");
        console.log(err);
      });
  };
  const paymentModal = () => {
    swal(t("common.gopremium"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/subscription-plan");
    });
  };
  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  const copy = (e, item) => {
    e.preventDefault();
    if (props.isActiveSubcription) {
      const link =
        "https://app.dastudy.net/" +
        typeLink +
        item?.category_obj?.slug +
        "/detail/" +
        item.slug;
      copyToClipboard(link);
      NotificationManager.success("Copié!");
    } else {
      paymentModal();
    }
  };
  const deleteItem = (e, id) => {
    e.preventDefault();
    swal({
      title: "Voulez-vous réellement supprimer?",
      text: "",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Oui",
          closeModal: false,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: true,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) {
        deleteDocument(type, id)
          .then((res) => {
            refresh();
            swal("Suppression terminée!", {
              icon: "success",
            }).then((val) => {});
          })
          .catch((err) => {
            swal("Erreur survenue lors de la suppression", {
              icon: "error",
            });
          });
      }
    });
  };
  return (
    <>
      <Header menu={8} />
      <div id="content" className="content">
        <div className="account_page pagecontent mew_publicites">
          <div className="container">
            <div className="account_page_cover displayflex">
              <div className="mes_favoris_left">
                <div className="mes_uploads_top displayflex">
                  <h1 className="titleh1 text-black">
                    {pageId ? "Contenu de la page" : t("profil.uploads")}
                  </h1>
                </div>
                <div className="filterbloc displayflex ">
                  <nav className="nav nav-pills">
                    <a
                      className="nav-item nav-link active"
                      href="#documents"
                      data-toggle="tab"
                      onClick={() => {
                        setType("book");
                        setTypeLink("documents/");
                      }}
                    >
                      {t("common.documents")}(
                      {myUploads?.documents?.length || 0})
                    </a>
                    {/* <a
                      className="nav-item nav-link"
                      href="#tutoriels"
                      data-toggle="tab"
                      onClick={() => setType("video")}
                    >
                      {t("common.tutorials")}
                    </a> */}
                    <a
                      className="nav-item nav-link"
                      href="#logiciels"
                      data-toggle="tab"
                      onClick={() => {
                        setType("software");
                        setTypeLink("softwares/");
                      }}
                    >
                      {t("common.softwares")}({myUploads?.software?.length || 0}
                      )
                    </a>
                  </nav>
                  <div className="nbresults_infos results_uploads">
                    {t("common.result")}{" "}
                    <span>
                      {data?.length} - {data?.length}
                    </span>{" "}
                    {t("common.to")} <span>{data?.length}</span>
                  </div>
                </div>
                <div className="list_mes_uploads " style={{ marginTop: 20 }}>
                  {load && (
                    <div className="result_item">
                      <Skeleton count={5} height={100} />
                    </div>
                  )}
                  {data?.map(
                    (item, key) =>
                      (item.owner.id == props.user?.id || !item.is_private) && (
                        <div className="blocuploadsdate result_item" key={key}>
                          <div className="uploads_listperdate">
                            <div className="result_content displayflex">
                              <div className="result_content_left displayflex">
                                <div
                                  className="result_img"
                                  style={{ maxWidth: "40%" }}
                                >
                                  <img
                                    className="lazy"
                                    src={item?.cover_picture}
                                    alt="Dastudy"
                                    style={{
                                      objectFit: "contain",
                                      height: "200px",
                                    }}
                                  />
                                </div>
                                <div className="result_texte displayflex">
                                  <div className="result_texte_top">
                                    <Link
                                      to={"#"}
                                      onClick={(e) => handleClick(e, item)}
                                      className="result_name"
                                    >
                                      {item.name}
                                    </Link>
                                    <div className="uploadresult_desc">
                                      {item.description}
                                    </div>
                                    <div className="result_from">
                                      {item.owner?.first_name}
                                    </div>
                                  </div>
                                  <div className="result_texte_bottom">
                                    <div className="uploadresult_date">
                                      {item.pub_date && (
                                        <Moment fromNow>
                                          {item?.pub_date}
                                        </Moment>
                                      )}
                                    </div>
                                    <div className="result_download">
                                      {item.number_seen}{" "}
                                      {type == "software" ? "vue(s)" : "vue(s)"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {!query.get("u") &&
                                item.owner?.id == props.user?.id && (
                                  <div className="result_content_right displayflex">
                                    <div className="result_button displayflex">
                                      <span
                                        className="delete_btn"
                                        onClick={(e) => deleteItem(e, item.id)}
                                      ></span>
                                      <a
                                        className="modify_btn"
                                        href="#"
                                        title="Modifier"
                                        data-toggle="modal"
                                        data-target="#modifyupload"
                                        onClick={() => {
                                          setIsfree(item.is_free);
                                          setIsPrivate(item.is_private);
                                          setItem(item);
                                        }}
                                      ></a>
                                      {item.is_private && (
                                        <a
                                          onClick={(e) => copy(e, item)}
                                          title="Copier le lien privé"
                                          href="#"
                                          style={{
                                            fontSize: "25px",
                                            marginLeft: "15px",
                                          }}
                                        >
                                          <i class="bi bi-clipboard-check"></i>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
              <SidebarAdd number={3} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div
        className="modal fade"
        id="modifyupload"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="modif_uploadform"
                onSubmit={onSubmit}
                encType="multipart/form-data"
              >
                <div className="file_fieldgroup row">
                  <div className="file_field form-group col-xs-12 col-sm-6 col-md-6">
                    <div className="formfile">
                      <label htmlFor="input-file2" className="displayflex">
                        <span>
                          {(image || item?.cover_picture) && (
                            <img
                              className="lazy"
                              src={image ? image : item?.cover_picture}
                              alt=""
                              style={{ objectFit: "cover", height: 100 }}
                            />
                          )}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t("upload.update_image"),
                            }}
                          ></span>
                        </span>
                      </label>
                      <input
                        id="input-file2"
                        type="file"
                        onChange={(e) =>
                          setImage(URL.createObjectURL(e.target.files[0]))
                        }
                        accept="image/*"
                        name="cover_picture"
                      />
                    </div>
                  </div>

                  <div className="modify_file file_field form-group col-xs-12 col-sm-6 col-md-6">
                    <div className="formfile">
                      <label htmlFor="input-file1" className="displayflex">
                        {full}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("upload.update_file"),
                          }}
                        ></span>
                      </label>
                      <input
                        id="input-file1"
                        type="file"
                        name="full_content"
                        onChange={(e) =>
                          setFull(e.target?.value?.split("\\")[2])
                        }
                        accept="media_type"
                      />
                    </div>
                  </div>
                  {type == "software" && (
                    <div className="file_field form-group col-xs-12 col-sm-6 col-md-6">
                      <div className="formfile">
                        <label htmlFor="input-file3" className="displayflex">
                          <span>
                            {(capture || item?.screenshoot) && (
                              <img
                                className="lazy"
                                src={capture ? capture : item?.screenshoot}
                                alt=""
                                style={{ objectFit: "cover", height: 100 }}
                              />
                            )}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t("upload.click1"),
                              }}
                            ></span>
                          </span>
                        </label>
                        <input
                          id="input-file3"
                          type="file"
                          onChange={(e) =>
                            setCapture(URL.createObjectURL(e.target.files[0]))
                          }
                          accept="image/*"
                          name="screenshoot"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    required
                    className="form-control"
                    placeholder={t("upload.title")}
                    defaultValue={item?.name}
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name="description"
                    required
                    className="textarea form-control"
                    placeholder={t("upload.description")}
                    defaultValue={item?.description}
                  ></textarea>
                </div>
                {type == "book" && (
                  <div className="form-group select_field">
                    <select
                      className="form-control"
                      defaultValue={item?.nature}
                      required
                      name="nature"
                    >
                      <option>{t("upload.nature")}</option>
                      <option value={0} selected={item?.nature == 0}>
                        {t("upload.livre")}
                      </option>
                      <option value={1} selected={item?.nature == 1}>
                        {t("upload.exercice")}
                      </option>
                    </select>
                  </div>
                )}
                {/* {type == "software" && (
                  <div className="form-group">
                    <input
                      name="licence"
                      required
                      className="textarea form-control"
                      placeholder="Licence"
                      defaultValue={item?.licence}
                      type="text"
                    />
                  </div>
                )}
                {type == "software" && (
                  <div className="form-group">
                    <textarea
                      name="readme"
                      required
                      className="textarea form-control"
                      placeholder="Readme"
                      defaultValue={item?.readme}
                    ></textarea>
                  </div>
                )}
                {type == "software" && (
                  <div className="form-group">
                    <input
                      name="web_site"
                      required
                      className="textarea form-control"
                      placeholder={t("upload.site")}
                      defaultValue={item?.web_site}
                      type="url"
                    />
                  </div>
                )} */}
                {(type == "book" || type == "software") && (
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={
                        t("upload.author") + "(" + t("common.facultatif") + ")"
                      }
                      name="author"
                      defaultValue={item?.author}
                    />
                  </div>
                )}
                <div className="form-group select_field">
                  <select className="form-control" name="category" required>
                    <option>{t("upload.category")}</option>
                    {categories?.map((cat, _i) => (
                      <option
                        value={cat.pk}
                        selected={cat.pk == item?.category}
                      >
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group select_field">
                  <select className="form-control" name="language">
                    <option>{t("upload.language")}</option>
                    {langues?.map((lang, key) => (
                      <option
                        value={lang.pk}
                        selected={lang.pk == item?.language}
                      >
                        {lang.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group select_field">
                  <select
                    className="form-control"
                    value={isPrivate}
                    onChange={(e) => handleChange(e.target.value)}
                    required
                    name="is_private"
                  >
                    <option>{t("upload.statut")}</option>
                    <option value={false} selected={!item?.is_private}>
                      Public
                    </option>
                    <option value={true} selected={item?.is_private}>
                      {t("upload.private")}
                    </option>
                  </select>
                </div>
                {type == "video" && (
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lien youtube du tutoriel"
                      name="youtube_url"
                      required
                      defaultValue={item?.youtube_url}
                    />
                  </div>
                )}
                <div className="formblocfield form-group row">
                  <div className="radiobuttons col-xs-12 col-sm-6 col-md-6">
                    <label>
                      <input
                        type="radio"
                        name="light"
                        checked={isFree}
                        value="gratuit"
                        onClick={(e) => priceStatus(e.target.value)}
                      />
                      <span className="radiobg"></span>
                      <span className="radio_option">{t("upload.free")}</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="light"
                        value="payant"
                        checked={!isFree}
                        onClick={(e) => priceStatus(e.target.value)}
                      />
                      <span className="radiobg"></span>
                      <span className="radio_option">{t("upload.pay")}</span>
                    </label>
                  </div>
                  {!isFree && (
                    <div className="col-xs-12 col-sm-3 col-md-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Prix"
                        name="price"
                        required
                        defaultValue={item?.price}
                      />
                    </div>
                  )}
                  {!isFree && (
                    <div className="select_group col-sm-3 col-md-3">
                      <div className="select_field">
                        <select className="form-control" name="devise">
                          <option value="FCFA">XAF</option>
                          <option value="€">Euro</option>
                          <option value="$">Dollar</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {isFree && <input type="hidden" name="price" value="0" />}
                </div>
                {type == "video" && (
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("upload.producer")}
                      required
                      name="producer"
                      defaultValue={item?.producer}
                    />
                  </div>
                )}
                <div className="form-group">
                  <div className="form-check custom-control custom-checkbox">
                    <label className="checkcustom displayflex">
                      <span>
                        {" "}
                        {t("login.read")}{" "}
                        <Link
                          to="/terms"
                          style={{ opacity: 0.8, color: "blue" }}
                        >
                          {t("login.accept")}
                        </Link>
                      </span>
                      <input type="checkbox" name="is_name" required />
                      <span
                        className="checkicon"
                        style={{ background: "#e9ecef" }}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="submit_field">
                  <input
                    name="_type"
                    type="hidden"
                    value={
                      type == "book"
                        ? "DOCUMENT"
                        : type == "video"
                        ? "VIDEO"
                        : "SOFTWARE"
                    }
                  />
                  <input type="hidden" name="is_free" value={isFree} />
                  <button
                    type="submit"
                    className="btnSubmit"
                    data-toggle="modal"
                    data-target="#soumettre_upload"
                  >
                    {t("upload.send")}
                    {loading && (
                      <span className="spinner-border spinner-border-sm ml-2"></span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  isActiveSubcription: state.auth.isActiveSubcription,
}))(Uploads);
