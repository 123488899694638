import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

const Footer = () => {
  const { t } = useTranslation();

  
  return (
    <>
      <hr />
      <footer id="footer">
        <div className="footer_top">
          <div className="container">
            <div className="row">
              <div className="footitem dastudy_footer col-xs-12 col-sm-3 col-md-4 col-lg-3">
                <div className="footitem_cover">
                  <div className="logofooter">
                    <Link to="/" title="Dastudy">
                      <img
                        className="lazy"
                        src="/assets/images/logo_footer.png"
                        alt="Dastudy"
                      />
                    </Link>
                  </div>
                  <p>{t("footer.about_text")}</p>
                </div>
              </div>
              <div className="footitem col-xs-12 col-sm-3 col-md-4 col-lg-3">
                <div className="footitem_cover">
                  <h5 className="foot_title">{t("footer.about")}</h5>
                  <ul className="footcotent">
                    <li>
                      <Link to="/team" title={t("footer.team")}>
                        {t("footer.team")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/feedsbacks" title={t("footer.feedsbacks")}>
                        {t("footer.feedsbacks")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/donate" title={t("footer.donate")}>
                        {t("footer.donate")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footitem col-xs-12 col-sm-3 col-md-4 col-lg-3">
                <div className="footitem_cover">
                  <h5 className="foot_title">{t("footer.help")}</h5>
                  <ul className="footcotent">
                    <li>
                      <Link
                        to="/feedsbacks"
                        title={t("footer.private_message")}
                      >
                        {t("footer.private_message")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" title="F.A.Q">
                        F.A.Q
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms" title={t("footer.terms")}>
                        {t("footer.terms")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footitem col-xs-12 col-sm-3 col-md-4 col-lg-3">
                <div className="footitem_cover">
                  <h5 className="foot_title">Contact</h5>
                  <ul className="footcotent">
                    <li>
                      <span>contact@dastudy.net</span>
                    </li>
                    <li>
                      <a
                        href="https://t.me/dastudy19"
                        target="_blank" rel="noopener noreferrer"
                        title={t("footer.telegram")}
                      >
                        {t("footer.telegram")}
                      </a>
                    </li>
                    <li>
                      <Link to="/feedsbacks" title={t("footer.contactus")}>
                        {t("footer.contactus")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="newsletter footitem col-xs-12 col-sm-3 col-md-3 col-lg-3">
                <div className="footitem_cover">
                  <h5 className="foot_title">{t("footer.newsletter")}</h5>
                  <div className="footcotent">
                    <p>Lorem ipsum dolor sit amet conse ctetu sit amet</p>
                    <form action="#" method="#">
                      <div className="input-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder={t("login.email")}
                        />
                        <button className="btn" type="submit">
                          Ok
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="footer_bottom_cover displayflex">
              <div className="foot_copyright">
                &copy; <script>document.write(new Date().getFullYear())</script>{" "}
                Dastudy LTD - {t("footer.allright")}
                <div
                  className="by"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <a
                    href="http://davinci-tech.net"
                    target="_blank" rel="noopener noreferrer"
                    style={{
                      color: "#fff",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    Designed by Davinci-Tech
                  </a>
                </div>
              </div>
              <div
                className="by1"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <a
                  href="http://davinci-tech.net"
                  target="_blank" rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    alignSelf: "center",
                    textAlign: "center",
                  }}
                >
                  Designed by Davinci-Tech
                </a>
              </div>
              <div className="social_link">
                <ul className="displayflex">
                  <li className="mail">
                    <a href="mailto:contact@dastudy.net"></a>
                  </li>
                  <li className="telegram">
                    <a href="https://t.me/dastudy19" target="_blank" rel="noopener noreferrer"></a>
                  </li>
                  <li className="linkedin">
                    <a
                      href="https://linkedin.com/company/dastudy"
                      target="_blank" rel="noopener noreferrer"
                    ></a>
                  </li>
                  <li className="twitter">
                    <a href="https://twitter.com/dastudy19" target="_blank" rel="noopener noreferrer"></a>
                  </li>
                  <li className="facebook">
                    <a
                      href="https://facebook.com/dastudynet"
                      target="_blank" rel="noopener noreferrer"
                    ></a>
                  </li>
                  <li className="instagram">
                    <a
                      href="https://instagram.com/dastudynet"
                      target="_blank" rel="noopener noreferrer"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <NotificationContainer />
    </>
  );
};

export default Footer;
