import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { requestAdd } from "../../../services/main.service";
import { useTranslation } from "react-i18next";
const MyAds = () => {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const onSubmit = (e) => {
    e.preventDefault();
    const datas = new FormData(e.target);
    setLoading(true);
    requestAdd(datas)
      .then((res) => {
        setLoading(false); 
        if (res.status == 201) {
          NotificationManager.success(
            "Votre message a été envoyé avec succès"
          );
        } else {
          NotificationManager.error(
            "Erreur survenue lors de l'envoie du message"
          );
        }
      })
      .catch((err) => {
        NotificationManager.error("Erreur survenue lors de l'envoie du message");
        console.log(err);
      });
  };
  return (
    <>
      <Header menu={8} />
      <div id="content" className="content">
        <div className="account_page pagecontent mew_publicites">
          <div className="container">
            <div className="account_page_cover">
              <div className="mes_pubs_top row">
                <div className="mes_pubs_top_left col-xs-12 col-sm-6 col-md-6">
                  <h1 className="titleh1 text-black">{t('profil.pub')}</h1>
                  <div className="" dangerouslySetInnerHTML={{__html: t("ad.text")}}></div>
                </div>
                <div className="mes_pubs_top_right col-xs-12 col-sm-6 col-md-6">
                  <img
                    className="lazy"
                    src="/assets/images/mespubs.png"
                    alt="Dastudy"
                  />
                </div>
              </div>
              <div className="mes_pubs_bottom">
                <h2>{t("ad.add")}</h2>
                <form onSubmit={onSubmit} className="row">
                  <div className="field_left col-xs-12 col-sm-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("login.name")}
                        required
                        name="fullname"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("ad.company")}
                        name="company_name"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        required
                        name="message"
                        className="textarea form-control"
                        placeholder={t("ad.message")}
                      ></textarea>
                    </div>
                  </div>
                  <div className="field_right col-xs-12 col-sm-6 col-md-6">
                    <div className="form-group">
                      <div className="form-group select_field">
                        <select className="form-control" name="country">
                          <option>{t("ad.country")}</option>
                          <option value="Cameroun">Cameroun</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        required
                        className="form-control"
                        placeholder={t("ad.city")}
                        name="city"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        required
                        className="form-control"
                        placeholder={t("ad.phone")}
                        name="phone_number"
                      />
                    </div>
                    <div className="form-group submitfield">
                      <button type="submit" className="btn_submit">
                      {t("upload.send")}
                        {loading && (
                          <span className="spinner-border spinner-border-sm ml-2"></span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <NotificationContainer />
    </>
  );
};
export default MyAds;
