import React from "react";
import { useParams } from "react-router-dom";
import BookDetail from "./books/BookDetail";
import Detail from "./softwares/Detail";

const ContentDetail = () => {
  const { type } = useParams();
  return <>{type === "documents" ? <BookDetail /> : <Detail />}</>;
};

export default ContentDetail;
