import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { home } from "../../services/main.service";
import BookItem from "../items/BookItem";
import SofwareGridItem from "../items/SoftwareGridItem";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Ad from "../ad";
import {
  ADD_CATEGORY,
  SET_SUB_CATEGORY
} from "../../redux/actions/types";
import CategoryItem from "../items/CategoryItem";
const $ = window.jQuery;

const Presentation = (props) => {
  const [data, setData] = React.useState([]);
  const { t } = useTranslation();
  const reader = useRef();
  if (reader.current) {
    reader.current.muted = true;
  }
  const init = () => {

    $(".sliderecentsposts").slick({
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 5,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "150px",
      responsive: [
        {
          breakpoint: 1499,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            centerPadding: "120px",
          },
        },
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            centerPadding: "90px",
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "100px",
          },
        },
      ],
    });

    $(".slidebook").slick({
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 3,
      centerMode: true,
      centerPadding: "150px",
      responsive: [
        {
          breakpoint: 1499,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            centerPadding: "120px",
          },
        },
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            centerPadding: "90px",
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "100px",
          },
        },
      ],
    });



  }
  const initPartner = () => {
    $("#sliderpartners").slick({
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 5,
      slidesToScroll: 3,
      centerMode: true,
      centerPadding: "150px",
      responsive: [
        {
          breakpoint: 1499,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            centerPadding: "120px",
          },
        },
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            centerPadding: "90px",
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "100px",
          },
        },
      ],
    });
  }
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: ADD_CATEGORY,
      payload: null,
    });
    dispatch({
      type: SET_SUB_CATEGORY,
      payload: null,
    });
    home().then((res) => {
      if (res.status == 200) {
        setData(res.data);
        init();
        initPartner();

      }
    });

  }, []);
  return (
    <>
      {/* ----------------- Présentation ------------------------------- */}
      <div id="blocinfos" className="blocinfos homebloc" >
        <div className="container">
          <div className="homebloc_cover displayflex home">
            <div className="infos_item infos_bibliotheque col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <Link
                to={"/documents"}
              >
                <div className="infos_itemcover">
                  <div className="infospicto displayflex">
                    <div
                      className="bg_opacity"
                      style={{ background: "#1382BF" }}
                    ></div>
                    <img
                      src="/assets/images/ico_bibliotheque.png"
                      alt="Dastudy"
                    />
                  </div>
                  <div className="infosexte">
                    <h3 className="infostitle">{t("home.numeric")}</h3>
                    <div className="infosdesc">{t("home.numeric_text")}</div>
                  </div>
                </div>
              </Link>
            </div>
            {/* <div className="infos_item infos_videotheque col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <Link
                to={
                  "/categories/tutorials?m=" + localStorage.getItem("tutoriel")
                }
              >
                <div className="infos_itemcover">
                  <div className="infospicto displayflex">
                    <div
                      className="bg_opacity"
                      style={{ background: "#A321DD" }}
                    ></div>
                    <img
                      src="/assets/images/ico_videotheque.png"
                      alt="Dastudy"
                    />
                  </div>
                  <div className="infosexte">
                    <h3 className="infostitle"> {t("home.videotheque")}</h3>
                    <div className="infosdesc">
                      {t("home.videotheque_text")}
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            <div className="infos_item infos_logitheque col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <Link
                to={
                  "/softwares"
                }
              >
                <div className="infos_itemcover">
                  <div className="infospicto displayflex">
                    <div
                      className="bg_opacity"
                      style={{ background: "#1BD37E" }}
                    ></div>
                    <img
                      src="/assets/images/ico_logitheque.png"
                      alt="Dastudy"
                    />
                  </div>
                  <div className="infosexte">
                    <h3 className="infostitle">{t("home.logitheque")}</h3>
                    <div className="infosdesc">{t("home.logitheque_text")}</div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="infos_item infos_forum col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <Link to={"/discussion"}>
                <div className="infos_itemcover">
                  <div className="infospicto displayflex">
                    <div
                      className="bg_opacity"
                      style={{ background: "#F8C10A" }}
                    ></div>
                    <img src="/assets/images/ico_forum.png" alt="Dastudy" />
                  </div>
                  <div className="infosexte">
                    <h3 className="infostitle">{t("header.discussion")}</h3>
                    <div className="infosdesc">{t("home.forum_text")}</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        id="categories"
        className="documents tutoriels homebloc slidebloc slidepadding"
      >
        <div className="container">
          <div className="homebloc_cover">
            <h2 className="homebloctitle">
              {t("home.category_title")}
              <span>{t("home.category_subtitle")}</span>
            </h2>
            <div className="homebloc_content">
              <div className="slidebook">
                {data?.categories?.map((item, i) => (
                  <CategoryItem item={item} key={i} type={item?.type == "SOFTWARE" ? "softwares" : "documents"} action={"cat"} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Ad position={2} />

      <div
        id="documents"
        className="documents tutoriels homebloc slidebloc slidepadding"
      >
        <div className="container">
          <div className="homebloc_cover">
            <h2 className="homebloctitle">
              {t("common.documents")}
              <span>
                {props?.user ? t("common.recent_read") : t("common.must_read")}
              </span>
            </h2>
            <div className="homebloc_content">
              <div className="slidebook">
                {data?.documents?.map((item, i) => (
                  <BookItem item={item} type="documents" cat={localStorage.getItem("livre")} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="tutoriels" className="tutoriels homebloc slidebloc slidepadding">
        <div className="container">
          <div className="homebloc_cover">
            <h2 className="homebloctitle">
              {t("common.tutorials")}
              <span>
                {props?.user ? t("common.recent_read") : t("common.must_read")}
              </span>
            </h2>
            <div className="homebloc_content">
              <div className="slidetuto">
                {data?.videos?.map((item, i) => (
                  <TutorialItem
                    item={item}
                    cat={localStorage.getItem("tutoriel")}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Ad position={3} />
      <div id="logigiels" className="logiciels homebloc slidebloc slidepadding">
        <div className="container">
          <div className="homebloc_cover">
            <h2 className="homebloctitle">
              {t("common.softwares")}
              <span>
                {props?.user
                  ? t("common.recent_download")
                  : t("common.must_download")}
              </span>
            </h2>
            <div className="homebloc_content">
              <div className="sliderecentsposts">
                {data.software?.map((item, i) => (
                  <SofwareGridItem
                    item={item}
                    cat={localStorage.getItem("logiciel")}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Ad position={4} />
      <div id="partenaires" className="partenaires slidebloc">
        <div className="hr_border"></div>
        <div className="container">
          <div className="homebloc_cover">
            <h2 className="homebloctitle">
              {/* <span>{t("home.company")}</span> */}
              {t("home.company_text")}
            </h2>
            <div className="homebloc_content">
              <div className="sliderpartners  displayflex" id="sliderpartners">

                {data?.partners?.map((item, i) => (
                  <div className="partner_item">
                    <a href={item.link} target="_blank" title={item.name}>
                      <img className="lazy" src={item.logo} alt={item.name} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------X------ Présentation ----------------X-------------- */}
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(Presentation);
