import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/fr";
import ShowMoreText from "react-show-more-text";
import { useQuery } from "../../services/main.service";
import { useTranslation } from "react-i18next";
import Ad from "../ad";
import { stringToSlug } from "../../helpers/functions";


const SofwareListItem = (props) => {
  const query = useQuery();
  const cat = props.cat ? props.cat : query?.get("m");
  const { t, i18n } = useTranslation();
  return (
    <div className="post_item col-xs-12 col-sm-12 col-md-12" style={props.marginTop&&{marginTop:props.marginTop}}>
      {props?.type == "pub" ? (
        <Ad category={localStorage.getItem("logiciel")} />
      ) : (
        <div className="post_item_cover displayflex">
          <div className="post_img">
            <Link
              to={
                "/softwares/" +
                stringToSlug(props.item?.category?.name) +
                "/detail/" +
                props.item?.slug
              }
              title="Avast antivirus"
            >
              <img src={props?.item?.cover_picture} alt="Dastudy" />
            </Link>
            <div className="download_btn">
              <Link
                to={
                  "/softwares/" +
                  stringToSlug(props.item?.category?.name) +
                  "/detail/" +
                  props.item?.slug
                }
                title="Avast antivirus"
              >
                {t("common.download")}
              </Link>
            </div>
          </div>
          <div className="post_text">
            <h3 className="post_name">
              <Link
                to={
                  "/softwares/" +
                  stringToSlug(props.item?.category?.name) +
                  "/detail/" +
                  props.item?.slug
                }
                title="Avast antivirus"
              >
                {props?.item?.name}
              </Link>
            </h3>
            <div className="post_cat">
              <a href="#" title="#">
                {props?.item?.category?.name}
              </a>
            </div>

            <div className="post_desc">
              <ShowMoreText lines={2} more=" " less=" ">
                {props?.item?.description}
              </ShowMoreText>
            </div>

            <div className="post_date">
              {props.item.pub_date && (
                <Moment locale={i18n.language} fromNow>
                  {props?.item?.pub_date}
                </Moment>
              )}
            </div>
            <div className="nb_download">
              {props?.item?.number_download} {t("common.downloaded")}
              <div className="pot_widthkey">
                {props?.item?.is_free === false && (
                  <span className="post_key active"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SofwareListItem;
