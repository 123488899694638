import React from 'react'

import './index.css'

const Spinner = () => {
    return <>
        <div className="spinner-loader">


            <div class="loadingio-spinner-spinner-mlkszeea3dd"><div class="ldio-2fcoqn7wff1">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div></div>


        </div>
    </>
}

export default Spinner