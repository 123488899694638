import React, { useState, useEffect } from "react";
import {
  Link as RouterLink,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import Alert from "@material-ui/lab/Alert";

import { APP_ROOT_URL } from "../../constants/url";

import { changePassword } from "../../redux/actions/auth";
import { log } from "../../helpers";
import storage from "../../helpers/functions";
import PasswordChecklist from "react-password-checklist";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [isValidate, setIsValidate] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let query = storage.useQuery();
  const { user_id, timestamp, signature } = useParams();
  const [passwordConfirm, setPasswordConfirm] = useState(null);

  const successMessage = useSelector((state) =>
    state.message.type === "ACCOUNT_VERIFIED" ? state.message : null
  );

  /**
   * Get login and password input values
   * @param {*} e
   * @param {*} setValue
   */
  const handleChange = (e, setValue) => setValue(e.target.value);

  /**
   *
   */
  const handleSubmit = (e) => {
    console.log("------------->SUBMIT");
    e.preventDefault();

    if (password && password != passwordConfirm) {
      setErrors(["Les mots de passe ne correspondent pas"]);
      return;
    }

    setLoading(true);

    dispatch(
      changePassword({
        password,
        user_id: query.get("user_id"),
        timestamp: query.get("timestamp"),
        signature: query.get("signature"),
      })
    ).then((response) => {
      //log('Login action response', response.status)

      if (response.status && response.status === 200) {
        //console.log('Redirection', APP_ROOT_URL)
        history.push("/login");
        setLoading(false);
        //window.location.reload()
      } else {
        log("Login action response", response);
        setLoading(false);
        if (response.status) {
          const responseError = response.data;
          const errorMessages = [];
          for (let i = 0; i < responseError.length; i++) {
            errorMessages.push(responseError[i]);
          }
          setErrors(errorMessages);
        } else {
          console.log(response.error);
          setErrors([response.error]);
          console.log(errors);
        }
      }
    });
  };

  return (
    <div style={{ background: "#1382BF" }} className="page_connexion">
      <header id="header" className="header">
        <div className="container">
          <div className="header_cover displayflex">
            <nav className="navbarsite displayflex">
              <div className="navleft displayflex">
                <div className="logosite navitem">
                  <a href="/" title="Dastudy">
                    <img
                      className="lazy"
                      src="/assets/images/logo.png"
                      alt="Dastudy"
                    />
                  </a>
                </div>
                <div className="langue_site navitem">
                  <label className="current_choice">
                    <span>Fr</span>
                    <i className="bi bi-chevron-down"></i>
                    <i className="bi bi-chevron-up"></i>
                  </label>
                  <ul className="submenu">
                    <li>Fr</li>
                    <li>En</li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>

      <div id="content" className="content">
        <div className="connexionpage">
          <div className="container">
            <div className="connect_cover">
              <h2 className="titleh1">Restauration du mot de passe</h2>
              <div className="connect_content">
                <div className="tab-content">
                  <div className="tab-pane active" id="seconnecter">
                    <div className="connectform">
                      <form onSubmit={handleSubmit}>
                        {/*---------- Affichage des erreurs de validation -------------- */}
                        {(!isValidate || errors.length > 0) && (
                          <Alert severity="error" className="mtb-32">
                            <ul style={{ padding: 0, paddingLeft: "10px" }}>
                              {errors.map((error, i) => (
                                <li key={i}>{error}</li>
                              ))}
                            </ul>
                          </Alert>
                        )}
                        {/*-----X---- Affichage des erreurs de validation --------X----- */}

                        {/*---------- Affichage des Message de succès -------------- */}
                        {successMessage && (
                          <Alert severity="success" className="mtb-32">
                            {successMessage.content}
                          </Alert>
                        )}
                        {/*-----X---- Affichage des Message de succès --------X----- */}

                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Nouveau mot de passe"
                            required
                            name="password"
                            onChange={(e) =>
                              e.target.value != null
                                ? handleChange(e, setPassword)
                                : null
                            }
                            defaultValue={password}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirmez le mot de passe"
                            required
                            name="cpassword"
                            onChange={(e) =>
                              e.target.value != null
                                ? handleChange(e, setPasswordConfirm)
                                : null
                            }
                            defaultValue={passwordConfirm}
                          />
                        </div>
                        <div className="form-group">
                          <PasswordChecklist
                            rules={["minLength", "number", "capital", "match"]}
                            minLength={6}
                            value={password}
                            valueAgain={passwordConfirm}
                            onChange={(val) => setIsValid(val)}
                            messages={{
                              minLength:
                                "Le mot de passe comporte au moins 6 caractères.",
                              number: "Le mot de passe comporte un chiffre.",
                              capital:
                                "Le mot de passe comporte une majuscule.",
                              match: "Les mots de passe correspondent.",
                            }}
                            className="text-left text-white"
                          />
                        </div>
                        <div className="checkbox_field displayflex">
                          <button
                            type="submit"
                            disabled={!isValid}
                            className="btnSubmit"
                          >
                            Modifier
                            {loading && (
                              <span className="spinner-border spinner-border-sm ml-2"></span>
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer id="footer">
        <div className="footer_bottom bottom_connexion">
          <div className="container">
            <div className="footer_bottom_cover displayflex">
              <div className="foot_copyright">
                &copy; <script>document.write(new Date().getFullYear())</script>{" "}
                Dastudy LTD - Tout droit réservé
              </div>
            </div>
          </div>
        </div>
      </footer>
      <style jsx>
        {`
          .form-control {
            background: #fff;
            border: 1px solid #00000029;
            font-size: 20px;
            color: #000;
            width: 100%;
            line-height: 50px;
            height: 50px;
            padding: 0 20px 5px;
            outline: none;
            box-shadow: none;
            border-radius: 0;
            cursor: pointer;
            opacity: 0.5;
          }
          .form-group {
            margin-bottom: 2rem;
          }
        `}
      </style>
    </div>
  );
};

export default ResetPassword;
