import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";

const AnalyseForm = ({ handleUpload, user }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = React.useRef(null);

  const loginModal = () => {
    swal(t("common.login"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/login");
    });
  };

  const handleSelectFile = () => {
    if (!user) {
      loginModal();
      return;
    }
    ref.current.click();
  };

  const handleFileChange = (e) => {
    if (!user) {
      loginModal();
      return;
    }
    const formData = new FormData();
    formData.append("full_content", e.target.files[0]);
    handleUpload(formData);
  };

  return (
    <div className="uploader-form">
      <input
        ref={ref}
        style={{ display: "none" }}
        type="file"
        onChange={handleFileChange}
      />

      <div onClick={handleSelectFile} className="analyse-uploader">
        <span className="upload-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
            />
          </svg>
        </span>
        <div>Cliquez ici pour déposer votre document PDF</div>
      </div>
    </div>
  );
};

export default AnalyseForm;
