import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import {
  allCategories,
  languages,
  uploadDocument,
  useQuery,
} from "../../../services/main.service";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import swal from "sweetalert";

const Upload = (props) => {
  const query = useQuery();
  const [isFree, setIsfree] = React.useState(true);
  const [type, setType] = React.useState(query.get("item"));
  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [langues, setLangues] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [isPrivate, setIsPrivate] = React.useState(false);
  const [full, setFull] = React.useState("");
  const [audio, setAudio] = React.useState("");
  const [capture, setCapture] = React.useState("");

  let history = useHistory();
  const { t } = useTranslation();
  const priceStatus = (val) => {
    if (val == "payant") {
      setIsfree(true);
      dev();
    } else setIsfree(true);
  };
  const paymentModal = () => {
      swal(t("common.gopremium"), {
        icon: "info",
        buttons: {
          confirm: {
            text: "OK",
            closeModal: true,
          },
          cancel: {
            text: "Annuler",
            visible: true,
          },
        },
        dangerMode: false,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) history.push("/subscription-plan");
      });
  };
  const dev = () => {
    swal("Cette fonctionalité est en cours de dévélopement!", {
      icon: "info",
    });
  };
  const handleChange = (val) => {
    if (props.isActiveSubcription) {
      setIsPrivate(val);
    } else if (val) {
      setIsPrivate(false);
      paymentModal();
    } else setIsPrivate(false);
  };
  React.useEffect(() => {
    setType(query.get("item"));
    allCategories(
      query.get("item") == "book"
        ? localStorage.getItem("livre")
        : query.get("item") == "video"
        ? localStorage.getItem("tutoriel")
        : localStorage.getItem("logiciel")
    ).then((res) => {
      if (res.status == 200) setCategories(res.data);
    });
    languages().then((res) => {
      if (res.status == 200) setLangues(res.data.results);
    });
  }, [query.get("item")]);

  const onSubmit = (e) => {
    e.preventDefault();
    const datas = new FormData(e.target);
    setLoading(true);
    uploadDocument(datas, type)
      .then((res) => {
        setLoading(false);
        if (res.status == 201) {
          NotificationManager.success(t("upload.success"));
          history.push("/uploads");
        } else {
          NotificationManager.error(t("upload.error"));
        }
      })
      .catch((err) => {
        NotificationManager.error(t("upload.error"));
        console.log(err);
      });
  };
  return (
    <>
      <Header menu={6} />
      <div id="content" className="content uploadpage">
        <div className="container">
          <div className="uploadpage_cover">
            <form
              className="upload_form"
              onSubmit={onSubmit}
              encType="multipart/form-data"
            >
              {(type == "book" || type == "software") && (
                <div className="formgroup envoiefichiers">
                  <div className="formgroup_title">
                    <p className="grouptitle">{t("upload.send_file")}</p>
                    <div className="formgroup_cover">
                      {t("upload.select_file")}
                    </div>
                  </div>
                  {/* <div className="file_field form-group">
                    <div className="formfile">
                      <label htmlFor="input-file" className="displayflex">
                        <small></small>
                        <span>
                          {type == "book"
                            ? t("upload.preview")
                            : t("upload.demo")}
                          {preview}
                        </span>
                      </label>
                      <input
                        id="input-file"
                        type="file"
                        name="preview_content"
                        accept="media_type"
                        onChange={(e) =>
                          setPreview(e.target?.value?.split("\\")[2])
                        }
                      />
                    </div>
                  </div> */}
                  <div className="modify_file file_field form-group">
                    <div className="formfile">
                      <label htmlFor="input-file1" className="displayflex">
                        <small></small>
                        <span>
                          {t("upload.full")}* {full}
                        </span>
                      </label>
                      <input
                        id="input-file1"
                        type="file"
                        required
                        name="full_content"
                        onChange={(e) =>
                          setFull(e.target?.value?.split("\\")[2])
                        }
                        accept="media_type"
                      />
                    </div>
                  </div>

                  {type == "book" && (
                    <div className="modify_file file_field form-group">
                      <div className="formfile">
                        <label htmlFor="input-audio" className="displayflex">
                          <small></small>
                          <span>
                            {t("upload.audio")+"("+t("common.facultatif")+")"} {audio}
                          </span>
                        </label>
                        <input
                          id="input-audio"
                          type="file"
                          name="audio_version"
                          onChange={(e) =>
                            setAudio(e.target?.value?.split("\\")[2])
                          }
                          accept="audio/*"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="formgroup">
                <div className="formgroup_title">
                  <p className="grouptitle">{t("upload.cover")}</p>
                  <div className="formgroup_cover">{t("upload.content")}</div>
                </div>
                <div className="uploadfile file_field form-group displayflex">
                  <div className="formfile">
                    <label htmlFor="input-file2" className="displayflex">
                      {image && (
                        <img
                          className="lazy"
                          src={image}
                          alt=""
                          style={{ objectFit: "cover", height: 100 }}
                        />
                      )}
                      <span
                        dangerouslySetInnerHTML={{ __html: t("upload.click") }}
                      ></span>
                      {/* <strong>1080x1080</strong> */}
                    </label>
                    <input
                      id="input-file2"
                      type="file"
                      onChange={(e) =>
                        setImage(URL.createObjectURL(e.target.files[0]))
                      }
                      accept="image/*"
                      name="cover_picture"
                    />
                  </div>
                  {type == "software" && (
                    <div className="formfile" style={{ marginLeft: "20px" }}>
                      <label htmlFor="input-file3" className="displayflex">
                        {capture && (
                          <img
                            className="lazy"
                            src={capture}
                            alt=""
                            style={{ objectFit: "cover", height: 100 }}
                          />
                        )}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("upload.click1"),
                          }}
                        ></span>
                        {/* <strong>1080x1080</strong> */}
                      </label>
                      <input
                        id="input-file3"
                        type="file"
                        onChange={(e) =>
                          setCapture(URL.createObjectURL(e.target.files[0]))
                        }
                        accept="image/*"
                        name="screenshoot"
                      />
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    required
                    className="form-control"
                    placeholder={t("upload.title")+"*"}
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name="description"
                    required
                    className="textarea form-control"
                    placeholder={t("upload.description")+"*"}
                    maxLength={500}
                  ></textarea>
                </div>
                {type == "book" && (
                  <div className="form-group select_field">
                    <select className="form-control" required name="nature">
                      <option>{t("upload.nature")+"*"}</option>
                      <option value={0}>{t("upload.livre")}</option>
                      <option value={1}>{t("upload.exercice")}</option>
                    </select>
                  </div>
                )}
                {/* {type == "software" && (
                  <div className="form-group">
                    <input
                      name="licence"
                      required
                      className="textarea form-control"
                      placeholder="Licence"
                      type="text"
                    />
                  </div>
                )}
                {type == "software" && (
                  <div className="form-group">
                    <textarea
                      name="readme"
                      required
                      className="textarea form-control"
                      placeholder="Readme"
                    ></textarea>
                  </div>
                )}
                {type == "software" && (
                  <div className="form-group">
                    <input
                      name="web_site"
                      required
                      className="textarea form-control"
                      placeholder={t("upload.site")}
                      type="url"
                    />
                  </div>
                )} */}
                {(type == "book" || type == "software") && (
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("upload.author")+"("+t("common.facultatif")+")"}
                      name="author"
                    />
                  </div>
                )}
                <div className="form-group select_field">
                  <select className="form-control" name="category" required>
                    <option>{t("upload.category")+"*"}</option>
                    {categories?.map((cat, i) => (
                      <option value={cat.pk}>{cat.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group select_field">
                  <select className="form-control" name="language" required>
                    <option>{t("upload.language")}*</option>
                    {langues?.map((lang, key) => (
                      <option key={key} value={lang.pk}>{lang.name}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group select_field">
                  <select
                    className="form-control"
                    value={isPrivate}
                    quired
                    name="is_private"
                    onChange={(e) => handleChange(e.target.value)}
                  >
                    <option>{t("upload.statut")}</option>
                    <option value={false}>Public</option>
                    <option value={true}>{t("upload.private")}</option>
                  </select>
                </div>
                {type == "video" && (
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("upload.youtube_url")}
                      name="youtube_url"
                      required
                    />
                  </div>
                )}
                <div className="formblocfield form-group row">
                  <div className="radiobuttons col-xs-12 col-sm-6 col-md-6">
                    <label>
                      <input
                        type="radio"
                        name="light"
                        checked={isFree}
                        value="gratuit"
                        onClick={(e) => priceStatus(e.target.value)}
                      />
                      <span className="radiobg"></span>
                      <span className="radio_option">{t("upload.free")}</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="light"
                        value="payant"
                        checked={!isFree}
                        onClick={(e) => priceStatus(e.target.value)}
                        desabled
                      />
                      <span className="radiobg"></span>
                      <span className="radio_option">{t("upload.pay")}</span>
                    </label>
                  </div>
                  {!isFree && (
                    <div className="col-xs-12 col-sm-3 col-md-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder={t("upload.price")}
                        name="price"
                        required
                      />
                    </div>
                  )}
                  {!isFree && (
                    <div className="select_group col-sm-3 col-md-3">
                      <div className="select_field">
                        <select
                          className="form-control"
                          name={t("upload.devise")}
                        >
                          <option value="FCFA">XAF</option>
                          {/* <option value="€">Euro</option>
                          <option value="$">Dollar</option> */}
                        </select>
                      </div>
                    </div>
                  )}
                  {isFree && <input type="hidden" name="price" value="0" />}
                </div>
                {type == "video" && (
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("upload.producer")}
                      required
                      name="producer"
                    />
                  </div>
                )}
                <div className="form-group">
                  <div className="form-check custom-control custom-checkbox">
                    <label className="checkcustom displayflex">
                      <span>
                        {t("login.read")}{" "}
                        <Link
                          to="/terms"
                          style={{ opacity: 0.8, color: "blue" }}
                        >
                          {t("login.accept")}
                        </Link>
                      </span>
                      <input type="checkbox" name="is_name" required />
                      <span
                        className="checkicon"
                        style={{ background: "#e9ecef" }}
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="submit_field">
                <input
                  name="_type"
                  type="hidden"
                  value={
                    query.get("item") == "book"
                      ? "DOCUMENT"
                      : query.get("item") == "video"
                      ? "VIDEO"
                      : "SOFTWARE"
                  }
                />
                <input type="hidden" name="is_free" value={isFree} />
                <button
                  type="submit"
                  className="btnSubmit"
                  data-toggle="modal"
                  data-target="#soumettre_upload"
                >
                  {t("upload.send")}
                  {loading && (
                    <span className="spinner-border spinner-border-sm ml-2"></span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <NotificationContainer />
      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  isActiveSubcription: state.auth.isActiveSubcription,
}))(Upload);
