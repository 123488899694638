import React from "react";
import { useQuery, saveReview } from "../services/main.service";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import Like from "./Like";


const Comments = (props) => {
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState(props?.data);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const limit = 3;
  const pagginationHandler = (page) => {
    setPage(page.selected + 1);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const datas = new FormData(e.target);
    setLoading(true);
    saveReview({
      content: datas.get("message"),
      ressource: props?.id,
    })
      .then((res) => {
        setLoading(false);
        setMessage("");
        const result = [res.data];

        setData([...result, ...data]);
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    setStart((page - 1) * limit);
    setEnd(page * limit);
  }, [page]);
  return (
    <>
      {props.user && (
        <div className="lguser_comment">
          <form onSubmit={onSubmit}>
            <textarea
              className="textarea form-control"
              placeholder="Laisser un commentaire"
              required
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <div className="submitfield">
              <button type="submit" className="blue_button btn_submit">
                {loading && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
      <div className="details_list_users">
        {data?.slice(start, end).map((item, i) => (
          <div className="lguser displayflex" key={i}>
            <div className="lguser_picture">
              <img
                src={
                  item?.author?.profile_picture
                    ? item?.author?.profile_picture
                    : "/assets/images/avatar.png"
                }
                alt="Dastudy"
              />
            </div>
            <div className="lguser_texte displayflex">
              <div className="lguser_texte_left displayflex">
                <div className="lguser_name">{item?.author?.first_name}</div>
                <div className="lguser_excert">{item?.content}</div>
                <div className="lguser_date">
                  {new Date(item?.created).toLocaleString()}
                </div>
              </div>
              <div className="lguser_cmtsandlikes lguser_btn displayflex">
                {/* <div className="nb_comments">30</div> */}
                {item && <Like likes={item.likes?.likes} id={item?.pk} type="reviews" />}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel={"..."}
          breakClassName={"break-me"}
          activeClassName={"active"}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          initialPage={page - 1}
          pageCount={data?.length / limit}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={pagginationHandler}
        />
      </div>
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(Comments);
