import React from "react";
import { allCategories, useQuery } from "../services/main.service";
import Skeleton from "react-loading-skeleton";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { SET_CATEGORY, ADD_CATEGORY, SET_SUB_CATEGORY } from "../redux/actions/types";
import { stringToSlug } from "../helpers/functions";

const SidebarFilter = (props) => {
  const [menus, setMenus] = React.useState([]);
  const [allmenus, setAllMenus] = React.useState(props.menus||[]);
  const [load, setLoad] = React.useState(true);
  let history = useHistory();
  const { t } = useTranslation();
  const {type} =useParams()
  const query = useQuery();
  const dispatch = useDispatch();
  let category = query?.get("category");
  let main = props.path=="books"?props.book?.pk:props.software?.pk
  React.useEffect(() => {
    setAllMenus(props.menus||[])
    allCategories(main).then((res) => {
      setLoad(false);
      if (res.status == 200) setMenus(res.data);
    });
    dispatch({
      type: SET_CATEGORY,
      payload: category && category != "null" ? category : main,
    });
  }, [main,props.menus ]);

  const handleClick=(menu)=>{
    if(allmenus.length>0 && allmenus.find(item=>item.parent==menu.pk)==undefined ) handleChange(menu,menu);
  }

  const handleChange = (sub,menu) => {
    dispatch({
      type: SET_CATEGORY,
      payload: sub.pk,
    });
     dispatch({
        type: ADD_CATEGORY,
        payload: menu,
      });
      if(sub.pk!=menu.pk){
        dispatch({
          type: SET_SUB_CATEGORY,
          payload: sub,
        });
      }else{
        dispatch({
          type: SET_SUB_CATEGORY,
          payload: null,
        });
      }
    history.push({
      pathname: "/" +type+"/"+stringToSlug(menu.name)+"/"+stringToSlug(sub.name),
      search: "?page=1",
    });
  };
  const ancre = (cat) => {
    return (
      <>
        <div
          style={{ cursor: "pointer", fontWeight: 'bold' }}
          className="filtertitle"
        >
         {cat.name}
        </div>
        <div className="filterbox" style={{marginLeft: '10px', display: 'none'}}>
          <ul>
            {allmenus.map((item, key) =>
              item.parent == cat.pk &&
              allmenus.find((menu) => menu.parent == item.pk) != undefined ? (
                ancre(item)
              ) : item.parent == cat.pk && (
                <li
                  style={{ cursor: "pointer" }}
                  className="sub_menu"
                  onClick={() => {
                    handleChange(item,cat);
                  }}
                >
                  {item.name} <span className="float-righ">({item.resource_count})</span>
                </li>
              )
            )}
          </ul>
        </div>
      </>
    );
  };
  return (
    <>
      {/* ------------Sidebar --------------*/}
      <div className="sidebarfilter mb-10">
        <label className="label_menu">Menu</label>
        <div className="sidebarfilter_bloc">
          <h4 className="titlebloc normal">
            {t("common.category")}
            <span className="cat_button">
              <i className=""></i>
            </span>
          </h4>
          <h4 className="titlebloc mobile filtertitle pb-10">
            {t("common.category")}
            <span className="cat_button">
              <i className=""></i>
            </span>
          </h4>
          <div className="fcnt_left_cover fixed-sidebar">
            {load && (
              <div className="filterpanel">
                <Skeleton count={20} />
              </div>
            )}
            {menus?.map((menu, index) => (
              <div key={index} className="filterpanel">
                <div
                  className={
                    category== menu.pk || category == menu.parent
                      ? "filtertitle active"
                      : "filtertitle"
                  }
                  onClick={() => { handleClick(menu);}}
                >
                  {menu.name}
                </div>
                <div
                  className="filterbox"
                  style={{
                    display:
                       category== menu.pk || category == menu.parent
                        ? "block"
                        : "none",
                        marginLeft: '10px'
                  }}
                >
                  <ul className={menu.pk}>
                    {allmenus.map((sub, key) =>
                      sub.parent == menu.pk &&
                      allmenus.find((item) => item.parent == sub.pk) !=
                        undefined
                        ? ancre(sub)
                        : sub.parent == menu.pk && (
                            <>
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleChange(sub,menu);
                                }}
                                className="sub_menu"
                              >
                                {sub.name} <span className="float-righ">({sub.resource_count})</span>
                              </li>
                            </>
                          )
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* -----x-----Sidebar ------x-------*/}

    </>
  );
};

export default connect((state) => ({
  menus: state.menus.menus,
  category: state.menus.category,
  subCategory: state.menus.subCategory,
  book: state.menus.book,
  software: state.menus.software,
}))(SidebarFilter);
