import React from "react";
import { useTranslation } from "react-i18next";
import UserFollow from "./UserFollow";

const UserBlock = (props) => {
  const [menus, setMenus] = React.useState([]);
  const { t } = useTranslation();

  return (
    <>
      {/* ------------Sidebar --------------*/}
      <div className="sidebarfilter mb-10">
        <div
          className="sidebarfilter_bloc"
          style={{ background: "#fff", }}
        >
          <img
            className="lazy user-img"
            src={props.page?.profile_picture || "/assets/images/avatar.png"}
            style={{ objectFit: "cover" }}
          />

          <div
            className="fcnt_left_cover fixed-sidebar user-page"
            style={{ marginTop: "15px" }}
          >
            <h4 className="mt-10">{props.page?.first_name}</h4>
            <UserFollow
              owner={props.page}
              show={true}
              authorname={props.page?.first_name}
              margin="15px"
            />
            <h4 style={{ marginTop: "15px" }}>{t("footer.about")}</h4>
            <p>
            {props.page?.description}
            </p>
          </div>
        </div>
      </div>

      {/* -----x-----Sidebar ------x-------*/}
    </>
  );
};

export default UserBlock;
