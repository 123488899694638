import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import "./AnalyseUpload.css";

import {
  analysedDocument,
  getAnalysedDocumentById,
} from "../../../services/main.service";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import AnalyseDiscussion from "./AnalyseDiscussion";
import AnalyseReader from "./AnalyseReader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import UploadedDocItem from "./UploadedDocItem";
const $ = window.jQuery;


const Analyse = (props) => {
  const { id } = useParams();
  const [data, setData] = React.useState([]);
  const [loadingAnalysedDoc, setLoadingAnalyseDoc] = React.useState(false);
  const [analysedBook, setAnalysedBook] = React.useState(null);

  // const {isConnected, billingPlan, subscription } = useUserSession()


  const { t } = useTranslation();

  const init = () => {
    $(".sliderecentsposts").slick({
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: true,
      centerPadding: "150px",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "100px",
          },
        },
      ],
    });
  };

  React.useEffect(() => {
    (async () => {
      setLoadingAnalyseDoc(true);
      analysedDocument().then((res) => {
        setData(res.data.results);
        setLoadingAnalyseDoc(false);
        init();
      });
    })();
  }, []);

  React.useEffect(() => {
    getAnalysedDocumentById(id).then((res) => {
      if (res.status === 200) {
        setAnalysedBook(res.data);
      }
    });
  }, []);

  return (
    <>
      <Header menu={6} title={t("chatdoc.reader_page_title") } />
      <main className='container ' style={{ paddingTop: '70px' }}>
        <div className="row">
          <div className="col my-2 pt-4 d-none d-sm-block">
            {(analysedBook !== null) ?
              (
                <>
                  <h2> {analysedBook.name} </h2>
                  <p>{t('chatdoc.welcome_message')}</p>
                </>
              ) :
              <div />}
          </div>
        </div>
        <div className="row">

          <section className="col-sm d-none d-lg-block">
            <AnalyseReader analysedBook={analysedBook}  />

          </section>

          <section className="col-sm">
            {analysedBook === null ?
              <div>Loading Document !!</div>
              :
              <AnalyseDiscussion analysedBook={analysedBook} />
            }
          </section>

        </div>
        <div className="row">
          <div className="col-12">
            <h2>{t('chatdoc.previous_documents_title')}</h2>
            <small>{t('chatdoc.previous_documents_title')}</small>
          </div>
        </div>

        <div className="row mt-4">
          {data.toReversed().slice(0, 3).map( (book, i) => (
            <div key={i} className="col-xs-12 col-sm-3 mb-4">
              <UploadedDocItem item={book} index={i} maxWidth="27rem" />
            </div>
          ))}
          {data.length === 0 ?? <div className="col-12 border rounded d-flex flex-row"> <p>Pas de document existant</p> </div>}
        </div>

      </main>
      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(Analyse);
