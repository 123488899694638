import { SET_MENU, SET_BOOK,SET_SOFTWARE, ADD_CATEGORY, SET_SUB_CATEGORY } from '../actions/types'

const initialState = {menus:null,book: null, software:null, category:null, subCategory:null}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case SET_MENU:
            return { ...state, menus: payload }
        case SET_BOOK:
            return { ...state, book: payload }
        case SET_SOFTWARE:
            return { ...state, software: payload }
        case ADD_CATEGORY:
            return { ...state, category: payload } 
        case SET_SUB_CATEGORY:
            return { ...state, subCategory: payload }   
        default:
            return state
    }
}