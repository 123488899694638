import { SET_CATEGORY, CLEAR_CATEGORY } from '../actions/types'

const initialState = {category: ""}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case SET_CATEGORY:
            return { ...state, category: payload }

        case CLEAR_CATEGORY:
            return { ...state, category: initialState }

        default:
            return state
    }
}