import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Skeleton from "react-loading-skeleton";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { makeStripePay } from "../services/main.service";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { useHistory } from "react-router-dom";
const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [load, setLoad] = React.useState(false);
  const { t } = useTranslation();
  let history = useHistory();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error && error != undefined) {
      setLoad(false);
      NotificationManager.error(error.message);
    } else if (paymentMethod) {
      NotificationManager.info(
        "Payement effectué verification de vos données en cours"
      );
      makeStripePay(props.orderId, paymentMethod.id)
        .then((res) => {
          setLoad(false);
          if (res.status == 204) {
            NotificationManager.success("Payement effectué avec succès");
            props.setStripeKey("");
          
            if (props.isDonnation) props.setStep(3);
            else if (props.link){
               $(".close").click();
               history.push(props.link);
            }
          } else if (res.status == 404) {
            NotificationManager.warning("Payemment non effective");
          } else {
            NotificationManager.error("Erreur survenue lors du Payemment");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="card-pay">
        <CardElement />
      </div>
      <div className="btn_button displayflex">
        <button
          type="button"
          className="borderbuton"
          onClick={() => {
            props.setStripeKey("");
            props.setStep(1);
          }}
        >
          {t("common.back")}
        </button>
        <button type="submit" className="blue_button" disabled={!stripe}>
          {t("common.pay")}{" "}
          {load && (
            <span className="spinner-border spinner-border-sm ml-2"></span>
          )}
        </button>
      </div>
      <NotificationContainer />
    </form>
  );
};

const StripeForm = (props) => {
  const stripePromise = loadStripe(props.apiKey);
  return (
    <Elements stripe={stripePromise}>
      {!stripePromise ? <Skeleton height={50} /> : <CheckoutForm {...props} />}
    </Elements>
  );
};

export default StripeForm;
