import api, {
  ALL_CATEGORY,
  UNFOLLOW,
  FOLLOW,
  DOCUMENTS,
  SOFTWARE,
  MAIN_MENU,
  CATALOGUES,
  TUTORIAL,
  PROFIL,
  USER_CONTENT,
  HOME,
  REVIEWS,
  FORUM_POST,
  FORUM_COMMENT,
  UPLOAD,
  PRIVATE_DOCUMENT,
  PRIVATE_VIDEO,
  PRIVATE_SOFTWARE,
  MY_UPLOADS,
  PAGE,
  REQUEST_AD,
  BOOKMARK,
  SEARCH,
  SALES,
  PURCHASED,
  ADVERT,
  RANDOM,
  INTEREST,
  SUBCCRIPTION,
  SOLDE,
  ORDER,
  PAYPAL,
  MOMO,
  STRIPE,
  STRIPESUBMIT,
  BILLING_PLAN,
  PAYDUNYA,
  getAuthorization,
  CHECK_REGISTATION,
  MAKE_DONATION,
  DOWNLOAD,
  SUBMIT_FEEDBACK,
  SOCIAL,
  NOTIFICATIONS,
  COUNTRIES,
  LANGUES,
  PDF,
  PRIVATE_LINK,
  getToken,
  VIEWPAY_REFRESH,
  CINETPAY,
  FORUM_GET,
  FORUM_GET_TOKEN,
  PAYPAL_CREATE,
  PAYPAL_CAPTURE,
} from "../api/pythonBackend";
import { useLocation } from "react-router-dom";

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
/**
 * Get all menu (categories)
 */
export const allCategories = (parent = "") => {
  return api
    .get(ALL_CATEGORY + "?parent=" + parent + "&no-paginate")
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};
/**
 * Get all menu (categories)
 */
export const allCategories1 = () => {
  return api
    .get(ALL_CATEGORY + "?no-paginate")
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get all main menu
 */
export const mainMenu = () => {
  return api
    .get(MAIN_MENU)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get document
 */
export const documents = (page = 1, category = "", author = "") => {
  // localStorage.setItem("isLang", "ok")
  return api
    .get(DOCUMENTS + `?page=${page}&category=${category}&author=${author}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get document detail
 */
export const documentDetail = (id) => {
  return api
    .get(DOCUMENTS + `/${id}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get Software
 */
export const software = (page = 1, category = "", author = "") => {
  return api
    .get(SOFTWARE + `?page=${page}&category=${category}&author=${author}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get software detail
 */
export const softwareDetail = (id) => {
  return api
    .get(SOFTWARE + `/${id}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get forum post
 */
export const forumPost = (page = 1) => {
  return api
    .get(FORUM_GET + "?page_size=5&page=" + page)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

export const forumToken = () => {
  return api
    .get(FORUM_GET_TOKEN)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get forum detail
 */
export const forumPostDetail = (id) => {
  return api
    .get(FORUM_POST + `/${id}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get forum detail
 */
export const forumComments = (page, parent) => {
  return api
    .get(FORUM_COMMENT + `?page=${page}&parent=${parent}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};
/**
 * Get similar
 */
export const forumSimilar = (id) => {
  return api
    .get(FORUM_POST + `/${id}/similarpost`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};
/**
 * Get catalogue
 */
export const catalogues = (page = 1, category = "", type = "") => {
  return api
    .get(CATALOGUES + ```?page=${page}&menu=${category}&type=${type}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};
/**
 * Get document detail
 */
export const catalogueDetail = (id) => {
  return api
    .get(CATALOGUES + `/${id}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get tutotiels
 */
export const tutotiels = (page = 1, category = "", author = "") => {
  return api
    .get(TUTORIAL + `?page=${page}&category=${category}&author=${author}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get tutotiel detail
 */
export const tutotielDetail = (id) => {
  return api
    .get(TUTORIAL + `/${id}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user profil
 */
export const profil = () => {
  return api
    .get(PROFIL)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user conthend
 */
export const userContent = () => {
  return api
    .get(USER_CONTENT)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get homde contents
 */
export const home = () => {
  return api
    .get(HOME)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new review
 * @param {*} data
 *
 */
export const saveReview = (data) => {
  return api
    .post(REVIEWS, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new review
 * @param {*} data
 *
 */
export const saveForumPost = (data) => {
  return api
    .post(FORUM_POST, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new forum comment
 * @param {*} data
 *
 */
export const saveForumComment = (data) => {
  return api
    .post(FORUM_COMMENT, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new document
 * @param {*} data
 *
 */
export const uploadDocument = (data, type) => {
  return api
    .post(
      type == "book"
        ? PRIVATE_DOCUMENT
        : type == "video"
          ? PRIVATE_VIDEO
          : PRIVATE_SOFTWARE,
      data
    )
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * update user profil
 * @param {*} data
 *
 */
export const updateProfil = (data, userId) => {
  return api
    .patch(PROFIL + "/" + userId, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user uploads
 */
export const getMyUploads = () => {
  return api
    .get(MY_UPLOADS)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new document
 * @param {*} data
 *
 */
export const updateDocument = (data, type, id) => {
  return api
    .patch(
      type == "book"
        ? PRIVATE_DOCUMENT + "/" + id
        : type == "video"
          ? PRIVATE_VIDEO + "/" + id
          : PRIVATE_SOFTWARE + "/" + id,
      data
    )
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Delete a document
 * @param {*} data
 *
 */
export const deleteDocument = (type, id) => {
  return api
    .delete(
      type == "book"
        ? PRIVATE_DOCUMENT + "/" + id
        : type == "video"
          ? PRIVATE_VIDEO + "/" + id
          : PRIVATE_SOFTWARE + "/" + id
    )
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user page
 */
export const userPage = () => {
  return api
    .get(PAGE)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user page
 */
export const userPageId = (id) => {
  return api
    .get(PAGE + "/" + id)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};
/**
 * Get user followers
 */
export const userFollower = (userId) => {
  return api
    .get(PAGE + "/" + userId + "/followers")
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user following
 */
export const userFollowing = (userId) => {
  return api
    .get(PAGE + "/" + userId + "/following")
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user page content
 */
export const userPageContent = (userId) => {
  return api
    .get(PAGE + "/" + userId + "/contents")
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user page content
 */
export const isFollow = (userId) => {
  return api
    .get(PAGE + "/" + userId + "/isfollowing")
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new document
 * @param {*} data
 *
 */
export const requestAdd = (data) => {
  return api
    .post(REQUEST_AD, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get reviews
 */
export const getReviews = (id = "") => {
  return api
    .get(CATALOGUES + `/${id}/reviews`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new review
 * @param {*} data
 *
 */
export const saveBookmark = (resource) => {
  return api
    .post(BOOKMARK, { resource })
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get reviews
 */
export const getBookmark = (type = "", page = 1) => {
  return api
    .get(`${BOOKMARK}?page=${page}&type=${type}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Delete an item to bookmark
 * @param {*} data
 *
 */
export const deleteBookmark = (id) => {
  return api
    .delete(BOOKMARK + "/" + id)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Follow user
 */
export const followUser = (id) => {
  return api
    .post(`${FOLLOW}`, { user: id })
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * UnFollow user
 */
export const unFollowUser = (id) => {
  return api.delete(`${UNFOLLOW}/${id}`).then((response) => {
    return response
      ? response
      : {
        error:
          "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
      };
  });
};

/**
 * Search
 */
export const search = (query) => {
  return api
    .get(`${SEARCH}?q=${query}&no-paginate`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user sales
 */
export const sales = () => {
  return api
    .get(SALES)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get user purchased
 */
export const purchased = () => {
  return api
    .get(PURCHASED)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Advert
 */
export const advert = (menu = "") => {
  return api
    .get(menu ? `${ADVERT}?menu=${menu}` : RANDOM)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * like Or dislike
 */
export const likeOrDislike = (id, interest, type = "") => {
  if (type) {
    return api
      .post(`${type}/${id}/likes`, { interest: interest })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch((error) => {
        return error.response
          ? error.response
          : {
            error:
              "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
          };
      });
  } else {
    return api
      .post(`${INTEREST}`, { ressource: id, interest: interest })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch((error) => {
        return error.response
          ? error.response
          : {
            error:
              "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
          };
      });
  }
};

/**
 * get user likes
 */
export const getLikes = (menu = "") => {
  return api
    .get(INTEREST)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};
/**
 * get user likes
 */
export const isLikeReview = (id = "", type, interest) => {
  return api
    .get(`${type}/${id}/hasinterest`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};
/**
 * Subcription
 */
export const subcriptions = (page = 1) => {
  return api
    .get(`${SUBCCRIPTION}?page=${page}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * current subcription
 */
export const currentSubcription = () => {
  return api
    .get(SUBCCRIPTION + "/current")
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};
/**
 * solde
 */
export const solde = () => {
  return api
    .get(SOLDE)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Add order
 */
export const addOrder = (id, userId) => {
  return api
    .post(`${ORDER}`, { resources: [id], user: userId })
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * papayl pay
 */
export const paypal = (orderId) => {
  return api
    .get(PAYPAL + "?pk=" + orderId)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Pay width mobile money
 */
export const makeMomoPay = (idOrder) => {
  return api
    .get(`${CINETPAY}?pk=${idOrder}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 *get stripe token
 */
export const stripeToken = (resourceId) => {
  return api
    .get(`${STRIPE}?pk=${resourceId}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 *make stripe pay
 */
export const makeStripePay = (orderId, stripeToken) => {
  return api
    .post(`${STRIPESUBMIT}?pk=${orderId}`, { stripeToken: stripeToken })
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

export const momoRefresh = (orderId) => {
  return api
    .get(VIEWPAY_REFRESH + "?pk=" + orderId)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Order subcription
 */
export const subscribe = (billing_plan_price) => {
  return api
    .post(`${SUBCCRIPTION}`, {
      billing_plan_price: billing_plan_price,
    })
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
            error:
              "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
          };
    });
};

/**
 *get billing plan
 */
export const getBillingPlan = () => {
  return api
    .get(`${BILLING_PLAN}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * payduna pay
 */
export const paydunya = (orderId) => {
  return api
    .get(`${PAYDUNYA}?pk=${orderId}&auth_token=${getAuthorization()}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 *check registration
 */
export const checkRegistration = (data) => {
  return api
    .post(`${CHECK_REGISTATION}`, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Make donation
 */
export const makeDonation = (data) => {
  return api
    .post(`${MAKE_DONATION}`, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Download ressource
 */
export const download = (id) => {
  return api
    .get(`${DOWNLOAD}/${id}?token=Bearer ${getToken()}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new document
 * @param {*} data
 *
 */
export const submitFeedback = (data) => {
  return api
    .post(SUBMIT_FEEDBACK, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new document
 * @param {*} data
 *
 */
export const socialLogin = (type, data) => {
  return api
    .post(SOCIAL + type, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get Resources
 */
export const languages = () => {
  return api
    .get(`${LANGUES}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get Resources
 */
export const getNotifications = () => {
  return api
    .get(`${NOTIFICATIONS}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get Resources
 */
export const countries = () => {
  return api
    .get(`${COUNTRIES}?no-paginate`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get Cities
 */
export const cities = (pk) => {
  return api
    .get(`${COUNTRIES}/${pk}/cities`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Save a new document
 * @param {*} data
 *
 */
export const getPdf = (data) => {
  return api
    .post(PDF, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * get private link
 * @param {*} pk
 *
 */
export const getLink = (pk) => {
  return api
    .get(PRIVATE_LINK + pk)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

export const paypalCreate = (orderId) => {
  return api
    .get(PAYPAL_CREATE + "?pk=" + orderId)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

export const paypalCapture = (pk, data) => {
  return api
    .post(PAYPAL_CAPTURE + "?pk=" + pk, data)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
 * Get all menu (categories)
 */
export const randomItems = (menu) => {
  return api
    .get(ALL_CATEGORY + "/" + menu + "/random_items")
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une erreur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

export const subscribers = () => {
  return api
    .get(`/profile/my_referees`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une erreur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

export const deleteQuestionHistory = (id) => {
  return api
    .delete(`/ai-my-questions/${id}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une erreur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

export const analysedDocument = async () => {
  return api
    .get(`/analyse-ai-doc`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une erreur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

/**
* Retrieve the document being analysed by it's Id
* @author Will Oracion
**/
export const getAnalysedDocumentById = async (id) => {
  return api
    .get(`/analyse-ai-doc/${id}`)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((error) => {
      return error.response
        ? error.response
        : {
          error:
            "Une erreur est survenue lors du traitement. Veuillez réessayer plustard. Merci",
        };
    });
};

export const uploadDocumentForAnalysis = async (data, onUploadProgress) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => onUploadProgress(progressEvent),
  };

  return api.post(`/analyse-ai-doc`, data, config);
};

export const getAnalyzedBookQuestions = async (id) => {
  return api.get(`/analyse-ai-doc/${id}/questions`)
}
