import React from 'react'

const MenuCard = ({ type, toggleMenu }) => {
    return <>
        {/*-------------- Bouton menu- --------------- */}
        {(type == "books-sub" || type == "tutorials-sub") && (
            <div className="mt-24">

                <button
                    type="button"
                    id="sidebarCollapse"
                    class="btn btn-ds mt-1 ml-1"
                    onClick={toggleMenu}
                >
                    <i class="fa fa-align-justify"></i> Menu
                  </button>
                <button
                    type="button"
                    id="sidebarCollapse"
                    class="btn  mt-1 ml-1"
                >
                    Ajouter <i class="fas fa-plus"></i>
                </button>

                <div className="switch text-center">
                    <button>Books</button>
                    <button>Exercises</button>
                </div>

            </div>
        )}
        {/*-------x------ Bouton menu- -------x------- */}
    </>
}

export default MenuCard