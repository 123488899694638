import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import Payment from "../../../components/Payment";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getBillingPlan,
  subscribe,
  useQuery,
} from "../../../services/main.service";
import Skeleton from "react-loading-skeleton";
import {
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import swal from "sweetalert";
import SubscriptionPlan from "./SubscriptionPlan";
import {bppLabelMap} from './utils'


const Subscription2 = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const [orderId, setOrderId] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [cycles, setCycles] = React.useState([])
  const [selectedCycle, selectCycle] = React.useState({label:''})

  const [load, setLoad] = React.useState(false);
  
  const query = useQuery();

  const btnRef = React.useRef(null);

  const staticOffers = []

  const [offers, setOffers] = React.useState(staticOffers);

  // retrieve the liste of cycle
  
  React.useEffect(
    () => {
      
      if(data.length > 0){
        let _cycles = []
        let bpps = []
        data.map( (plan, index) => {
            _cycles =  [
              ..._cycles, 
              ...plan.billingplanprice_set
            ]
        } )
        _cycles.map((bpp, idx) => {
          bpps = [
            ...bpps,
            {
              cycle: bpp.price.recurring_cycle,
              label: bppLabelMap[bpp.price.recurring_cycle]
            }
          ]
        })
        setCycles(bpps.filter(
          (obj, index, arr) =>
          {
          return arr.findIndex(o =>
            {
            return JSON.stringify(o) === JSON.stringify(obj)
            }) === index
          } 
        ))
      }
      
    },
    [data]
  )

  React.useEffect(() => {
    if(cycles.length > 0){
      selectCycle(cycles[0])
    }
  }, [cycles])


  React.useEffect(() => {

    const updatedOffer = data.map((data) => {
      let monthly_price_multiplicator = 30 // assuming by default it's daily
      if (data.recurring_cycle === 'M') {
        monthly_price_multiplicator = 1
      }

      return {
        id: data.id,
        recurring_cycle: data.recurring_cycle,
        title: data.name,
        subTitle: data.description,
        prices: data.billingplanprice_set,
        price: {
          day: parseFloat(data.price),
          month: parseFloat(data.price * monthly_price_multiplicator)
        },
        defaultPrice: 'day',
        extendedPrice: "month",
        toggleble: false,
        sousbcribable: parseFloat(data.price) != 0,
        options: {
          downloadsCount: 5,
          audioReader: false,
          addFavorite: false,
          maxQuestion: 10,
          docAnalyse: {
            count: 1,
            fixedPage: 20,
            maxPage: 20,
          },
        },
        planItems: data.items
      }
    })

    setOffers(updatedOffer);
  }, [data, setOffers]);

  const paymentModal = () => {
    swal(t("common.gopremium1"), {
      icon: "info",
      dangerMode: false,
      closeOnClickOutside: false,
    });
  };

  React.useEffect(() => {
    if (query.get("limit") == "ok") paymentModal();
    getBillingPlan().then((res) => {
      if (res.status === 200 && res.data) {
        const plan = res.data.results;
        setData(plan);
      }
    });
  }, []);

  const handleClick = (offer) => {
    btnRef.current.click();
    setLoad(true);
    setPrice(offer.price);
    subscribe(offer.billing_plan_price).then((res) => {
      if (res.status === 201 || res.status === 200) {
        setOrderId(res.data?.order?.id);
        setLoad(false);
      }
      if (res.status === 400) {
        setLoad(false);
      }
      if (res.status === 401) {
        setLoad(false);
        swal("Erreur d'authentification lors du paiement", {
          icon: "warning",
          dangerMode: true,
          closeOnClickOutside: false,
        })
      }
      if (res.status >= 500) {
        setLoad(false);
        swal("Erreur Serveur lors du paiement.", {
          icon: "danger",
          dangerMode: true,
          closeOnClickOutside: false,
        })
      }
    });
  };

  return (
    <>
      <Header menu={8} />
      <div id="content" className="content pagecontent page_abonnement">
        <div className="container">
          <div className="offres_dabonnements espace_account">
            <div className="text-center">
              <h1 className="titleh1">{t("billing.title")}</h1>
              <p> {t("billing.text")}</p>
            </div>
            <div className="w-50 mx-auto d-flex flex-row justify-content-center">
              <div className="btn-group btn-group-lg" role="group" aria-label="Cycles de paiement">
                {cycles.map((cycle, id) => <button onClick={() => selectCycle(cycle)} key={id} type="button" className={`btn ${selectedCycle.label === cycle.label ? 'btn-primary' : 'btn-outline-primary'}`}>{cycle.label}</button>)}
              </div>
            </div>  
            <div className="table-responsive">
              <SubscriptionPlan
                offers={offers}
                onSubscribe={handleClick}
                selectedCycle={selectedCycle}
              />
            </div>
            <div className="premium_ellipse premieum_bottom">
              <img
                className="lazy"
                src="assets/images/premium_top1	.png"
                alt="Dastudy"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <button
        ref={btnRef}
        style={{ display: "none" }}
        type="button"
        data-toggle="modal"
        data-target="#donation"></button>
      <div
        className="modal fade"
        id="donation"
        tabIndex="-1"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h1 className="tileh1">{t("billing.pay")}</h1>
              <p>{t("billing.pay_text")}</p>
            </div>
            {!orderId ? (
              <Skeleton height={150} />
            ) : (
              <Payment orderId={orderId} link="/account" price={price} />
            )}
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
};

export default connect((state) => ({
  currentSubscription: state.auth.currentSubcription.currentSubcription,
}))(Subscription2);