import React from "react";
import {
  addOrder,
  makeDonation,
  makeMomoPay,
  momoRefresh,
  paydunya,
  paypal,
  paypalCapture,
  paypalCreate,
  stripeToken,
} from "../services/main.service";
import $ from "jquery";
import { connect } from "react-redux";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import StripeForm from "./StripeForm";
import swal from "sweetalert";
import { API_KEY, SITE_ID } from "../config/app";
import { PYTHON_API_ENDPOINT } from "../api/pythonBackend";
import axios from "axios";


const Payment = (props) => {
  const [step, setStep] = React.useState(props.isDonnation ? 0 : 1);
  const [img, setImg] = React.useState(1);
  const [load, setLoad] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [stripeKey, setStripeKey] = React.useState("");
  const [stripeOrderId, setStripeOrderId] = React.useState("");
  const [donationMessage, setDonationMessage] = React.useState("");
  const [devise, setDevise] = React.useState("");
  const [name, setName] = React.useState("");
  const [price, setPrice] = React.useState(props.price || 500) ;
  const [country, setCountry] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("CM");
  const [city, setCity] = React.useState("");
  const [isAnonymous, setIsAnonymous] = React.useState(false);
  const { t } = useTranslation();
  const [orderId, setOrderId] = React.useState(props.orderId);
  const [paypalOrder, setPaypalOrder] = React.useState(null);

  const paypal = window.paypal;

  const handleSelectFlag = (seletedCountryData) => {
    setCountryCode(seletedCountryData.iso2);
  };
  const alert = (val = "") => {
    swal(val || "Cette fonctionalité est en cours de dévélopement!", {
      icon: "info",
    });
  };
  var timeout = null;

  const next = () => {
    if (img === 1 || img === 2 || img === 3 || img === 6 || img === 4) {
      if (orderId) {
        if (img === 3) {
          paypalPay(orderId);
          // alert();
        } else if (img === 6) {
          setLoad(true);
          paydunaPay(orderId);
        } else if (img === 4) {
          setLoad(true);
          momoPay(orderId);
        } else {
          stripePay(orderId);
          // alert();
        }
      } else {
        addOrder(props.item?.id, props.user?.id)
          .then((res) => {
            if (res.status === 201) {
              if (img === 3) {
                paypalPay(res.data.id);
              } else if (img === 4) {
                momoPay(res.data.id);
              } else if (img === 6) {
                paydunaPay(res.data.id);
              } else {
                // stripePay(res.data.id);
                alert();
              }
            } else {
              setLoad(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoad(false);
          });
      }
    } else {
      // setStep(2);
      setLoad(false);
      setMessage("");
    }
  };
  const momoPay = (orderId) => {
    setLoad(true);
    makeMomoPay(orderId)
      .then((res) => {
        checkout(orderId, res.data?.cnet_transaction_id);
      })
      .catch((err) => {
        console.log(err);
        setMessage(err.message);
      });
  };

  const checkPay = (orderId) => {
    momoRefresh(orderId)
      .then((res) => {
        if (res.status === 200) {
          setMessage(res.data.details);

          if (res.data.code === "FAILURE") {
            clearInterval(timeout);
            setMessage(t("donate.error_pay"));
            setLoad(false);
          }
          if (res.data.code === "SUCCESS") {
            setStep(3);
            setLoad(false);
            clearInterval(timeout);
          }
        } else {
          clearInterval(timeout);
          setMessage(res.data.details);
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        clearInterval(timeout);
        setMessage(err.data.message);
        setLoad(false);
      });
  };

  const stripePay = (orderId) => {
    stripeToken(orderId)
      .then((res) => {
        if (res.status === 200) {
          setStripeKey(res.data.key);
          setStripeOrderId(orderId);
          setStep(2);
          setLoad(false);
        } else {
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  const paypalPay = (orderId) => {
    setLoad(true);
    setStep(2);
    setTimeout(() => {
      handlePaypal(orderId);
    }, 700);
  };

  const handlePaypal = (orderId) => {
    setLoad(false);
    paypal
      .Buttons({
        // Order is created on the server and the order id is returned
        createOrder: function () {
          return paypalCreate(orderId)
            .then((order) => order.data?.id)
            .catch(() => {
              alert("error");
            });
        },
        // Finalize the transaction on the server after payer approval
        onApprove: function (data) {
          return paypalCapture(orderId, {
            order_id: data?.orderID,
          }).then((orderData) => {
            // Successful capture! For dev/demo purposes:
            if (orderData.data && orderData.data.status === "COMPLETED") {
              setStep(3);
            }
            setLoad(false);
          });
        },
        onError: function (err) {
          // For example, redirect to a specific error page
          setLoad(false);
        },
        onClick: function () {
          // For example, redirect to a specific error page
          setLoad(true);
        },
        onCancel: function () {
          setLoad(false);
        },
        style: {
          color: "blue",
        },
        styles: {
          p: {
            color: "#fff !important",
          },
        },
      })
      .render("#paypal-button-container");
  };

  const paydunaPay = (orderId) => {
    paydunya(orderId)
      .then((res) => {
        if (res.status === 200) {
          setStep(2);
          $("#pay_btn1").html(res.data);
          setLoad(false);
        } else {
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  const saveDonation = (e) => {
    e.preventDefault();
    setLoad(true);
    const data = {
      price,
      message: donationMessage,
      // name:name?name:" ",
      // email,
      phone: $(".phone1").val(),
      devise,
      country,
      city,
      anonymous: isAnonymous,
    };
    makeDonation(data)
      .then((res) => {
        if (res.status === 201) {
          setOrderId(res.data.order.id);

          setStep(1);
          setLoad(false);
        } else {
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
  const checkout = (orderId, trans_id) => {
    var data = JSON.stringify({
      apikey: API_KEY,
      site_id: SITE_ID,
      transaction_id:
        trans_id || Math.floor(Math.random() * 100000000).toString(), //
      amount: price,
      currency: "XAF",
      alternative_currency: "XOF",
      description: props.isDonnation ? "Don" : "Abonnement",
      customer_name: name || props.user?.first_name || "Dons anonymes", //Le nom du client
      customer_surname: props.user?.username || "Dons anonymes", //Le prenom du client
      customer_email: props.user?.email || "contact@dastudy.net", //l'email du client
      customer_address: "BP 0024", //addresse du client
      customer_city: city || props.user?.town || "Douala", // La ville du client
      customer_country: "CM", // le code ISO du pays
      customer_state: "CM", // le code ISO l'état
      customer_zip_code: "06510", // code postal
      metadata: orderId,
      order_id: orderId,
      notify_url: PYTHON_API_ENDPOINT + "cpay-response",
      return_url: "https://app.dastudy.net/discussion",
      lang: "FR",
    });

    var config = {
      method: "post",
      url: "https://api-checkout.cinetpay.com/v2/payment",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoad(false);
        if (response.data && response.data.code === "201")
          window.location = response.data.data.payment_url;
      })
      .catch(function (error) {
        setLoad(false);
        console.log(error);
      });
  };
  React.useEffect(() => {
    $("#pay_btn1 button").on("click", function () {
      $(".close").click();
    });
  });
  return (
    <div className="modal-body">
      {step === 0 && (
        <div id="etape1" className="step etape1 modepaiements">
          <form onSubmit={saveDonation}>
            
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={isAnonymous}
                  onChange={(e) => setIsAnonymous(!isAnonymous)}
                  style={{ marginRight: "10px" }}
                />
                {t("donate.anonyme")}
              </label>
            </div>
            {!isAnonymous && (
              <div className="row">
                <div className="form-group col-md-12">
                  <label>{t("login.name")}</label>
                  <input
                    placeholder={t("login.name")}
                    type="text"
                    className="form-control"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label>{t("login.country")}</label>
                  <input
                    placeholder={t("login.country")}
                    type="text"
                    className="form-control"
                    required
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>{t("login.city")}</label>
                  <input
                    placeholder={t("login.city")}
                    type="text"
                    className="form-control"
                    required
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="form-group col-md-12">
                <label>{t("donate.amount")}(FCFA)</label>
                <input
                  placeholder={t("donate.amount")}
                  type="number"
                  className="form-control"
                  required
                  min="500"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label></label>
              <textarea
                placeholder={t("donate.message1")}
                className="form-control"
                value={donationMessage}
                onChange={(e) => setDonationMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="btn_button displayflex">
              <button
                type="button"
                className="borderbuton close"
                data-dismiss="modal"
                aria-label="Close"
              >
                {t("common.cancel")}
              </button>
              <button type="submit" className="blue_button">
                {t("login.continue")}
                {load && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
      {step === 1 && (
        <div id="etape1" className="step etape1 modepaiements">
          <div className="step_cover liste_modes displayflex">
            <div
              className={img === 4 ? "mode_item selected" : "mode_item"}
              onClick={() => setImg(4)}
            >
              <div className="mode_item_cover displayflex">
                <img
                  className="lazy"
                  src="assets/images/momo.jpg"
                  alt="Dastudy"
                />
              </div>
            </div>
            <div
              className={img === 1 ? "mode_item selected" : "mode_item"}
              onClick={() => setImg(1)}
            >
              <div className="mode_item_cover displayflex">
                <img
                  className="lazy"
                  src="assets/images/visa.jpg"
                  alt="Dastudy"
                />
              </div>
            </div>
             {/*<div
              className={img === 2 ? "mode_item selected" : "mode_item"}
              onClick={() => setImg(2)}
            >
              <div className="mode_item_cover displayflex">
                <img
                  className="lazy"
                  src="assets/images/mastercard.jpg"
                  alt="Dastudy"
                />
              </div>
            </div> */}
            <div
              className={img === 3 ? "mode_item selected" : "mode_item"}
              onClick={() => setImg(3)}
            >
              <div className="mode_item_cover displayflex">
                <img
                  className="lazy"
                  src="assets/images/paypal.jpg"
                  alt="Dastudy"
                />
              </div>
            </div>
            {/* <div
              className={img === 5 ? "mode_item selected" : "mode_item"}
              onClick={() => setImg(5)}
            >
              <div className="mode_item_cover displayflex">
                <img
                  className="lazy"
                  src="assets/images/mobile_mtn.jpg"
                  alt="Dastudy"
                />
              </div>
            </div>
            <div
              className={img === 6 ? "mode_item selected" : "mode_item"}
              onClick={() => setImg(6)}
            >
              <div className="mode_item_cover displayflex">
                <img
                  className="lazy"
                  src="assets/images/paydunya.jpg"
                  alt="Dastudy"
                />
              </div>
            </div> */}
          </div>
          <div className="btn_button displayflex">
            {props.isDonnation ? (
              <button
                type="button"
                className="borderbuton"
                onClick={() => setStep(0)}
              >
                {t("common.back")}
              </button>
            ) : (
              <button
                type="button"
                className="borderbuton close"
                data-dismiss="modal"
                aria-label="Close"
              >
                {t("common.cancel")}
              </button>
            )}
            <button
              type="button"
              className="blue_button"
              onClick={(e) => next()}
            >
              {t("login.continue")}{" "}
              {load && (
                <span className="spinner-border spinner-border-sm ml-2"></span>
              )}
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div id="etape2" className="step etape2">
          {img === 3 ? (
            <div id="paypal-button-container" className="w-50 m-auto">
            </div>
          ) : (
            <div className="step_cover">
              <div id="pay_btn"></div>
              <div id="pay_btn1"></div>
              {stripeKey && (
                <StripeForm
                  apiKey={stripeKey}
                  setStep={setStep}
                  setStripeKey={setStripeKey}
                  orderId={stripeOrderId}
                  link={props.link}
                  isDonnation={props.isDonnation}
                />
              )}

              {(img === 4 || img === 5) && (
                <div>
                  {message && (
                    <Alert severity="info" className="mtb-32">
                      {message}
                    </Alert>
                  )}
                  <IntlTelInput
                    preferredCountries={["cm"]}
                    onlyCountries={["cm", "ci"]}
                    onPhoneNumberChange={(e) => setIsValid(e)}
                    onSelectFlag={handleSelectFlag}
                    inputClassName="form-control phone"
                    nationalMode={true}
                    autoHideDialCode={false}
                    style={{
                      width: "100%",
                      border: isValid ? "solid .5px green" : "solid .5px red",
                    }}
                  />
                </div>
              )}
            </div>
          )}

          <div className="btn_button displayflex">
            {!stripeKey && (
              <button
                type="button"
                className="borderbuton"
                onClick={() => setStep(1)}
              >
                {t("common.back")} 
                {load && step === 2 && (
                <span className="spinner-border spinner-border-sm ml-2"></span>
              )}
              </button>
            )}
            {(img === 4 || img === 5) && (
              <button
                type="button"
                disabled={!isValid}
                className="blue_button"
                onClick={() => next()}
              >
                {t("common.pay")}{" "}
                {load && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}
              </button>
            )}
          </div>
        </div>
      )}
      {step === 3 && (
        <div id="etape3" className="step etape3 modepaiements">
          <Alert severity="success" className="mtb-32">
            <div
              dangerouslySetInnerHTML={{
                __html: props.isDonnation
                  ? t("donate.success")
                  : t("donate.success_pay"),
              }}
            ></div>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(Payment);
