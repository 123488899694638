import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  IS_SUBSCRIBE,
  FEATURE_MAP_UPDATE,
  SET_CURRENT_SUBSCRIPTION,
} from "../actions/types";

import storage from "../../helpers/functions";
import { STORED_USER_KEY } from "../../constants";
import { currentSubcription } from "../../services/main.service";

/**
 * Connected user from localStorage
 */
const user = localStorage.getItem(STORED_USER_KEY);
/**
 *  Auth state Initialisation
 */
const initialState = user
  ? { loggedIn: true, isActiveSubcription: false, user }
  : { loggedIn: false, isActiveSubcription: false, user: null };

/**
 * Auth reducer
 * manage redux auth actions
 * @param {*} state
 * @param {*} action
 */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return { ...state, loggedIn: false, user: payload.user };

    case REGISTER_FAIL:
      return { ...state, loggedIn: false };

    case LOGIN_SUCCESS:
      return { ...state, loggedIn: true, user: payload.user };
    case IS_SUBSCRIBE:
      return { ...state, isActiveSubcription: payload.isActiveSubcription };
    case LOGIN_FAIL:
      return { ...state, loggedIn: false, user: null };
    case SET_CURRENT_SUBSCRIPTION:
      return { ...state, currentSubcription: payload };

    case FEATURE_MAP_UPDATE:
      return { ...state, featureMap: payload };

    case LOGOUT:
      return { ...state, loggedIn: false, user: null };

    default:
      return state;
  }
}
