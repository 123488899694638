import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Header title="Termes & conditions" />
      <div id="content" className="content page_cms">
        <div className="container">
          {i18n.language == "fr" ? (
            <div className="page_cms_cover">
              <h1 className="titleh1">Termes & conditions</h1>

              <p>
                <strong>D&eacute;finitions</strong>
              </p>
              <p>
                Le site Internet DASTUDY, r&eacute;pondant &agrave;
                l&rsquo;adresse{" "}
                <a href="https://www.dastudy.net">https://www.dastudy.net</a>{" "}
                (ci-apr&egrave;s d&eacute;nomm&eacute; &laquo; le Site &raquo;),
                est un produit cr&eacute;&eacute;, d&eacute;velopp&eacute; et
                exploit&eacute; par la soci&eacute;t&eacute; DIGITSAF SARL
                (ci-apr&egrave;s d&eacute;nomm&eacute;e &laquo; DIGITSAF SARL
                &raquo;), immatricul&eacute;e au RCS de DOUALA sous le
                num&eacute;ro RC/Dla/2021/B/2116 et dont le si&egrave;ge social
                est au 21, Akwa Boulevard de la libert&eacute; &ndash; BP6081
                DOUALA.&nbsp;
              </p>
              <p>
                Le Site DASTUDY est une plateforme E-Learning qui propose
                gratuitement aux utilisateurs des outils d&rsquo;&eacute;tude et
                de divertissement, tout en mettant en avant la culture locale
                africaine. Ceci &agrave; travers divers services
                (&laquo;&nbsp;Documents&nbsp;&raquo; et
                &laquo;&nbsp;Logiciels&nbsp;&raquo;) et fonctionnalit&eacute;s
                (Lecture en ligne, T&eacute;l&eacute;chargement et
                &laquo;&nbsp;Forum&nbsp;&raquo;).&nbsp;
              </p>
              <p>
                Le Site DASTUDY propose &eacute;galement divers autres services
                et fonctionnalit&eacute;s plus &eacute;tendus et
                sp&eacute;cialis&eacute;s accessibles par abonnement payant
                (ci-apr&egrave;s d&eacute;nomm&eacute; le Service &laquo; Study
                Premium &raquo;).&nbsp;
              </p>
              <p>
                &nbsp;Les pr&eacute;sentes conditions g&eacute;n&eacute;rales
                d&rsquo;utilisation d&eacute;finissent les rapports contractuels
                et commerciaux entre DIGITSAF SARL, d&rsquo;une part, et tout
                abonn&eacute; au &agrave; l&rsquo;un ou l&rsquo;autre de ses
                Services - gratuits et payants - (ci-apr&egrave;s
                d&eacute;nomm&eacute; &laquo; l&rsquo;Abonn&eacute; &raquo;),
                d&rsquo;autre part.&nbsp;
              </p>
              <p>
                En utilisant le Site DASTUDY, vous confirmez avoir lu et
                accept&eacute; toutes nos conditions g&eacute;n&eacute;rales
                d&rsquo;utilisation telles que d&eacute;crites sur cette page
                web.&nbsp;
              </p>
              <p>
                <strong>
                  Conditions d&rsquo;utilisation des Services et
                  Fonctionnalit&eacute;s
                </strong>
              </p>
              <p>
                Le Site DASTUDY est accessible depuis tout navigateur, &agrave;
                partir de divers appareils &eacute;lectroniques (ordinateur
                personnel, tablette num&eacute;rique, t&eacute;l&eacute;phone
                portable, etc.)&nbsp;
              </p>
              <p>
                L&rsquo;utilisation du Site DASTUDY n&eacute;cessite une
                connexion &agrave; internet haut d&eacute;bit et &agrave;
                internet mobile le cas &eacute;ch&eacute;ant pour les
                t&eacute;l&eacute;phones portables. Est vivement
                recommand&eacute;e une connexion &agrave; Internet mobile au
                minimum de troisi&egrave;me g&eacute;n&eacute;ration (3G).
                &nbsp;
              </p>
              <p>
                Il est, cependant, pr&eacute;cis&eacute; que ces connexions ne
                sont pas prises en charge par DIGITSAF SARL, il appartient par
                cons&eacute;quent &agrave; l&rsquo;Abonn&eacute; de souscrire
                pr&eacute;alablement &agrave; une offre Internet Haut
                D&eacute;bit et/ou Internet mobile pour pouvoir utiliser le Site
                DASTUDY.&nbsp;
              </p>
              <p>
                En qualit&eacute; d&rsquo;Abonn&eacute;, il est possible
                d&rsquo;activer les diff&eacute;rents Services disponibles sur
                le Site DASTUDY sur un ordinateur personnel (PC ou Mac) et un
                t&eacute;l&eacute;phone mobile compatible. Toutefois, le Service
                Study Premium ne sera accessible simultan&eacute;ment
                qu&rsquo;&agrave; partir d&rsquo;une seule connexion &agrave; la
                fois (ordinateur personnel enregistr&eacute; ou
                t&eacute;l&eacute;phone mobile compatible
                enregistr&eacute;).&nbsp;
              </p>
              <p>
                Il est pr&eacute;cis&eacute; que DIGITSAF SARL met en
                &oelig;uvre des moyens techniques pour v&eacute;rifier
                l&rsquo;absence de connexions multiples simultan&eacute;es
                &agrave; un m&ecirc;me compte du Service Study Premium et
                d&eacute;tecter les tentatives de connexions multiples. Une
                telle man&oelig;uvre est formellement proscrite.&nbsp;
              </p>
              <p>
                <strong>Donn&eacute;es personnelles&nbsp;</strong>
              </p>
              <p>
                DIGITSAF SARL s&rsquo;engage &agrave; respecter la
                l&eacute;gislation en vigueur relative &agrave; la protection de
                la vie priv&eacute;e eu &eacute;gard au traitement
                automatis&eacute; des donn&eacute;es &agrave; caract&egrave;re
                personnel, notamment le R&egrave;glement g&eacute;n&eacute;ral
                de protection des donn&eacute;es personnelles entr&eacute; en
                vigueur le 25 Mai 2018.&nbsp;
              </p>
              <p>
                Dans le cadre de l&rsquo;utilisation du Site DASTUDY, des
                donn&eacute;es personnelles de l&rsquo;Abonn&eacute; peuvent
                &ecirc;tre demand&eacute;es, notamment son nom, adresse mail,
                date de naissance, ville, pays, etc. L&rsquo;Abonn&eacute;
                d&eacute;clare sur l&rsquo;honneur que les dites donn&eacute;es
                sont vraies.&nbsp;
              </p>
              <p>
                Les informations et donn&eacute;es recueillies concernant
                l&rsquo;Abonn&eacute; font l&rsquo;objet d&rsquo;un traitement
                informatique obligatoire car n&eacute;cessaires &agrave; la
                gestion de son abonnement. Elles peuvent &agrave; cette fin
                &ecirc;tre transmises aux soci&eacute;t&eacute;s charg&eacute;es
                de la gestion, de l&rsquo;ex&eacute;cution et du traitement des
                op&eacute;rations de paiement (dans le cadre de
                l&rsquo;utilisation de l&rsquo;un ou l&rsquo;autre des services
                Study Premium ou Study Shop). Ces informations et donn&eacute;es
                sont &eacute;galement conserv&eacute;es &agrave; des fins de
                s&eacute;curit&eacute;.&nbsp;
              </p>
              <p>
                Si l&rsquo;Abonn&eacute; y a consenti, les donn&eacute;es
                collect&eacute;es pourront &eacute;galement &ecirc;tre
                utilis&eacute;es dans le cadre de la gestion de nos relations
                commerciales afin d'&eacute;tablir des statistiques et aussi
                permettre &agrave; DIGITSAF SARL d&rsquo;am&eacute;liorer et
                personnaliser les services qu&rsquo;elle lui propose et les
                informations qu&rsquo;elle lui adresse.
              </p>
              <p>
                Conform&eacute;ment &agrave; la loi &laquo; informatique et
                libert&eacute;s &raquo; n&deg;78-17 du 6 janvier 1978
                l&rsquo;Abonn&eacute; b&eacute;n&eacute;ficie d&rsquo;un droit
                d&rsquo;acc&egrave;s et de rectification aux informations qui le
                concernent. S&rsquo;il souhaite exercer ce droit et obtenir
                communication des informations le concernant,
                l&rsquo;Abonn&eacute; est invit&eacute; &agrave; contacter
                DIGITSAF SARL via le Site en cliquant sur la rubrique &laquo;
                Nous contacter &raquo;) ou lui &eacute;crire.
              </p>
              <p>
                Si l&rsquo;Abonn&eacute; y a consenti (par exemple &agrave;
                l&rsquo;occasion de la cr&eacute;ation de son compte en vue de
                l&rsquo;inscrire au Service Study Premium),
                l&rsquo;Abonn&eacute; sera susceptible de recevoir la lettre
                d&rsquo;information (&laquo; newsletter &raquo;) de DIGITSAF
                SARL &agrave; son adresse de courrier &eacute;lectronique telle
                que renseign&eacute;e et, le cas &eacute;ch&eacute;ant,
                modifi&eacute;e par lui. Si l&rsquo;Abonn&eacute; ne souhaite
                plus recevoir cette lettre d&rsquo;information,
                l&rsquo;Abonn&eacute; peut &agrave; tout moment se
                d&eacute;sabonner en cliquant sur le lien pr&eacute;vu &agrave;
                cet effet en bas de chaque lettre d&rsquo;information ou en
                modifiant les donn&eacute;es le concernant &agrave; partir de
                son compte sur le Site en cliquant sur la rubrique &laquo;
                Modifier mes donn&eacute;es personnelles &raquo;.
              </p>
              <p>
                L&rsquo;Abonn&eacute; est inform&eacute; que les donn&eacute;es
                le concernant pourront &ecirc;tre divulgu&eacute;es en
                application d'une loi, d'un r&egrave;glement ou en vertu d'une
                d&eacute;cision d'une autorit&eacute; r&eacute;glementaire ou
                judiciaire comp&eacute;tente ou encore, si cela s'av&egrave;re
                n&eacute;cessaire pour DIGITSAF SARL, dans le cadre d'une
                proc&eacute;dure judiciaire.
              </p>
              <p>
                <strong>Cookies&nbsp;</strong>
              </p>
              <p>
                DIGITSAF SARL a temporairement recourt &agrave;
                l&rsquo;utilisation des cookies accours de la navigation de
                l&rsquo;Abonn&eacute; sur le Site DASTUDY. Un cookie ne permet
                pas d&rsquo;&ecirc;tre identifi&eacute; personnellement. En
                revanche, il enregistre des informations relatives &agrave; la
                navigation de l&rsquo;ordinateur sur le Site (les pages
                consult&eacute;es, la date et l'heure de la consultation, etc.)
                qui permettront d&rsquo;am&eacute;liorer
                l&rsquo;exp&eacute;rience utilisateur de l&rsquo;Abonn&eacute;.
                Cela permettra &agrave; DIGITSAF SARL de faciliter la navigation
                sur le site et &eacute;vitera &agrave; l&rsquo;Abonn&eacute; de
                devoir fournir, &agrave; chaque nouvelle visite, des
                informations d&eacute;j&agrave; communiqu&eacute;es &agrave; une
                date ant&eacute;rieure.&nbsp;
              </p>
              <p>
                Le logiciel de navigation permet d'informer de la
                pr&eacute;sence de &laquo; cookies &raquo; et
                l&rsquo;Abonn&eacute; peut s&rsquo;opposer &agrave;
                l'enregistrement de &laquo; cookies &raquo;. Cette
                proc&eacute;dure est pr&eacute;cis&eacute;e sur la rubrique
                &laquo; Donn&eacute;es Personnelles &raquo; du Site.
              </p>
              <p>
                <strong>Propri&eacute;t&eacute; intellectuelle&nbsp;</strong>
              </p>
              <p>
                Le Site DASTUDY et tous les &eacute;l&eacute;ments le composant
                (notamment logos, mot cl&eacute;
                &laquo;&nbsp;Dastudy&nbsp;&raquo;, noms de domaine, documents,
                logiciels, images, vid&eacute;os, textes et, ainsi que les
                visuels des conditionnements de ces enregistrements) sont la
                propri&eacute;t&eacute; exclusive de DIGITSAF SARL ou de ses
                conc&eacute;dants (notamment auteurs ou producteurs de livres et
                logiciels).&nbsp;
              </p>
              <p>
                Ces &eacute;l&eacute;ments sont prot&eacute;g&eacute;s par les
                lois relatives &agrave; la propri&eacute;t&eacute;
                intellectuelle et autres. L&rsquo;Abonn&eacute; ne pourra
                utiliser ces &eacute;l&eacute;ments que dans le cadre de
                l&rsquo;utilisation des services offerts sur le Site DASTUDY,
                conform&eacute;ment aux dispositions des pr&eacute;sentes
                conditions g&eacute;n&eacute;rales d&rsquo;utilisation.&nbsp;
              </p>
              <p>
                Le contenu (Documents et Logiciels) pr&eacute;sent sur le Site
                DASTUDY est gratuit, sauf mention expr&egrave;s du prix de vente
                par l&rsquo;auteur lors de sa mise en ligne. Une fois
                t&eacute;l&eacute;charg&eacute;, l&rsquo;utilisation du contenu
                &agrave; des fins commerciales est fortement proscrite et
                constituerait une violation du code de la
                propri&eacute;t&eacute; intellectuelle.&nbsp;
              </p>
              <p>
                Toute repr&eacute;sentation totale ou partielle du Site DASTUDY
                et/ou des &eacute;l&eacute;ments les composant (tels que
                d&eacute;crits ci-dessus) par quelque proc&eacute;d&eacute; que
                ce soit, sans l'autorisation formelle de DIGITSAF SARL est
                d&egrave;s lors interdite et constituerait une
                contrefa&ccedil;on sanctionn&eacute;e par les articles L. 335-2
                et suivants du Code de la propri&eacute;t&eacute;
                intellectuelle.
              </p>
              <p>
                L&rsquo;Abonn&eacute; s&rsquo;engage &agrave; n'employer aucune
                mesure technique de nature &agrave; permettre le contournement
                des mesures techniques de protection en vue de proc&eacute;der
                au t&eacute;l&eacute;chargement de fichiers num&eacute;riques
                payants et/ou accessible uniquement sur abonnement premium, et
                de permettre leur conservation dans l'unit&eacute; de stockage
                de son terminal de r&eacute;ception quel qu'il soit (PC,
                tablette num&eacute;rique, t&eacute;l&eacute;phone mobile,
                etc.).
              </p>
              <p>
                L&rsquo;Abonn&eacute; d&eacute;clare prendre acte que le fait de
                porter sciemment atteinte &agrave; une mesure technique de
                protection l'expose, par application des dispositions de
                l'article L. 335-4-1 du Code de la Propri&eacute;t&eacute;
                Intellectuelle au paiement d'une amende de 3.750 euros, et que
                le fait de procurer ou proposer sciemment &agrave; autrui,
                directement ou indirectement, des moyens con&ccedil;us ou
                sp&eacute;cialement adapt&eacute;s pour porter atteinte &agrave;
                une mesure technique de protection est pour sa part puni d'une
                peine d'emprisonnement de six mois et de 30.000 euros d'amende.
              </p>
              <p>
                <strong>
                  Suspension et/ou r&eacute;siliation anticip&eacute;e
                  d&rsquo;abonnement
                </strong>
              </p>
              <p>
                Sans pr&eacute;judice de tous dommages et int&eacute;r&ecirc;ts
                que DIGITSAF SARL pourrait solliciter, DIGITSAF SARL se
                r&eacute;serve le droit de suspendre l&rsquo;acc&egrave;s
                d&rsquo;un Abonn&eacute; au Service Study Premium et/ou
                r&eacute;silier de plein droit, sans pr&eacute;avis ni
                indemnit&eacute;, son abonnement au Service Study Premium en cas
                de :
              </p>
              <p>
                &nbsp;- Non-respect par l&rsquo;Abonn&eacute; des
                pr&eacute;sentes conditions g&eacute;n&eacute;rales
                d&rsquo;utilisation et de vente ;&nbsp;
              </p>
              <p>
                &nbsp;- Non-respect des droits de propri&eacute;t&eacute;
                intellectuelle de DIGITSAF SARL et/ou de ses conc&eacute;dants ;
              </p>
              <p>
                &nbsp;- Contournement ou tentative de contournement des mesures
                techniques de protection mises en place par DIGITSAF SARL ;
              </p>
              <p>
                &nbsp;- Connexions multiples simultan&eacute;es &agrave; un
                m&ecirc;me compte du Service Study Premium ou tentative de
                connexions multiples simultan&eacute;es ;
              </p>
              <p>
                &nbsp;- Fourniture de fausses informations lors de son
                inscription &agrave; l&rsquo;un des Services Study Premium ou
                Study Shop ;
              </p>
              <p>
                - Non-paiement total ou partiel par l&rsquo;Abonn&eacute; du
                prix de son abonnement au Service Study Premium ;
              </p>
              <p>
                - Agissements contraires aux int&eacute;r&ecirc;ts commerciaux
                de DIGITSAF SARL.&nbsp;
              </p>
              <p>
                De son c&ocirc;t&eacute;, l&rsquo;Abonn&eacute; pourra
                r&eacute;silier, sans pr&eacute;avis ni indemnit&eacute; au
                b&eacute;n&eacute;fice de DIGITSAF SARL, son forfait ou son
                abonnement pour motifs l&eacute;gitimes (en cas de manquement
                grave de DIGITSAF SARL &agrave; ses obligations principales
                d&eacute;finies dans les pr&eacute;sentes conditions
                g&eacute;n&eacute;rales).&nbsp;
              </p>
              <p>
                <strong>Liens hypertextes&nbsp;</strong>
              </p>
              <p>
                Le Site DASTUDY peut contenir des liens hypertextes renvoyant
                &agrave; des pages ou des sites tiers, dont le contenu ne
                saurait en rien engager l&rsquo;entreprise DIGITSAF SARL.&nbsp;
              </p>
              <p>
                Les liens hypertextes renvoyant vers le Site DASTUDY sont
                accept&eacute;s et cordialement encourag&eacute;s dans la mesure
                o&ugrave; ils &eacute;manent de sites respectant les conditions
                g&eacute;n&eacute;rales d&rsquo;utilisation telles que
                sus-d&eacute;finies.&nbsp;
              </p>
              <p>
                <strong>Modification des conditions&nbsp;</strong>
              </p>
              <p>
                DIGITSAF SARL se r&eacute;serve le droit de modifier &agrave; sa
                discr&eacute;tion les pr&eacute;sentes conditions
                g&eacute;n&eacute;rales d&rsquo;utilisation. DIGITSAF SARL
                informera chaque Abonn&eacute; par courrier &eacute;lectronique
                &agrave; l&rsquo;adresse renseign&eacute;e sur le compte de
                l&rsquo;Abonn&eacute; de toute modification des pr&eacute;sentes
                conditions g&eacute;n&eacute;rales d&rsquo;utilisation au
                minimum 20 (vingt) jours avant leur prise d&rsquo;effet.&nbsp;
              </p>
              <p>
                Dans l&rsquo;hypoth&egrave;se o&ugrave; l&rsquo;Abonn&eacute;
                n&rsquo;accepterait pas ces modifications, il sera libre de
                r&eacute;silier son abonnement &agrave; partir de son compte sur
                le Site, cette r&eacute;siliation prenant effet &agrave; la
                prochaine &eacute;ch&eacute;ance d&rsquo;abonnement mensuelle. A
                d&eacute;faut de r&eacute;siliation, les nouvelles conditions
                g&eacute;n&eacute;rales d&rsquo;utilisation et de vente seront
                d&egrave;s lors applicables &agrave; compter de la prochaine
                &eacute;ch&eacute;ance d&rsquo;abonnement suivant la prise
                d&rsquo;effet des nouvelles conditions g&eacute;n&eacute;rales
                d&rsquo;utilisation.
              </p>
              <p>
                <strong>Droit applicable et Juridiction</strong>
              </p>
              <p>
                Les conditions g&eacute;n&eacute;rales d&rsquo;utilisation
                telles que sus-d&eacute;finies seront r&eacute;gies et
                interpr&eacute;t&eacute;es conform&eacute;ment aux lois de
                l&rsquo;&Eacute;tat de Cameroun. En cas d&rsquo;&eacute;chec de
                r&eacute;solution &agrave; l&rsquo;amiable, tout litige devra
                &ecirc;tre port&eacute; &agrave; la juridiction non exclusive
                des tribunaux comp&eacute;tents Camerounais.
              </p>
            </div>
          ) : (
            <div className="page_cms_cover">
              <h1 className="titleh1">Terms & conditions</h1>
              <p>
                <strong>Definitions</strong>
              </p>
              <p>
                The&nbsp;DASTUDY website , responding to the address
                https://www.dastudy.net (hereinafter referred to as "the Site"),
                is a product designed, developed and operated by the company
                DIGITSAF Ltd.&nbsp;(hereinafter referred to as
                "&nbsp;DIGITSAF&nbsp;SARL"),&nbsp;i&nbsp;mmatricul&eacute;e the
                RCS DOUALA under number RC /Dla / 2021 / B / 2116 and whose
                registered office is at 21, Akwa Boulevard de la
                Libert&eacute;&nbsp;-&nbsp;BP&nbsp;6081&nbsp;DOUALA.
              </p>
              <p>
                The&nbsp;DASTUDY&nbsp;Site&nbsp;is an E-Learning platform
                that&nbsp;offers free&nbsp;study and entertainment tools&nbsp;to
                users&nbsp;, while highlighting local African culture.&nbsp;This
                through various services ("&nbsp;Documents&nbsp;" and
                "&nbsp;Software&nbsp;"&nbsp;) and functionalities (&nbsp;Online
                reading&nbsp;,&nbsp;Download&nbsp;and&nbsp;"&nbsp;Forum&nbsp;"&nbsp;).&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                The&nbsp;DASTUDY&nbsp;Site&nbsp;also offers&nbsp;various other
                more extensive and specialized services and functions
                accessible&nbsp;by paid subscription (hereinafter referred to
                as&nbsp;the &ldquo;&nbsp;Study Premium&nbsp;&rdquo; Service).
              </p>
              <p>
                These terms of use defined&nbsp;ssent&nbsp;the contractual and
                commercial relationship between&nbsp;D&nbsp;IGITSAF Ltd., one
                hand, and any subscriber&nbsp;to any
                of&nbsp;century&nbsp;art&nbsp;services -&nbsp;free and paid
                -&nbsp;(hereinafter referred to as "the Subscriber"), on the
                other hand.
              </p>
              <p>
                Using thee Site&nbsp;DASTUDY&nbsp;, you&nbsp;confirm that you
                have read and&nbsp;accept&nbsp;ed&nbsp;all&nbsp;our&nbsp;terms
                and conditions&nbsp;as described on this web page.
              </p>
              <p>Terms of use are services and Features</p>
              <p>
                The&nbsp;site&nbsp;DASTUDY&nbsp;is&nbsp;accessible&nbsp;from any
                browser,&nbsp;from&nbsp;various electronic
                devices&nbsp;(&nbsp;personal computer,&nbsp;tablet computer,
                phone&nbsp;mobile&nbsp;, etc.)
              </p>
              <p>&nbsp;</p>
              <p>
                Use of the&nbsp;DASTUDY&nbsp;Site&nbsp;requires a broadband
                internet connection and a mobile internet connection, if
                applicable, for mobile phones.&nbsp;Is
                highly&nbsp;recommended&nbsp;u&nbsp;did to mobile
                internet&nbsp;at least&nbsp;third generation (3G)&nbsp;.
              </p>
              <p>
                It is&nbsp;, however,&nbsp;specified that these connections are
                not supported by&nbsp;D&nbsp;IGITSAF&nbsp;SARL, it is
                consequently up to the Subscriber to subscribe beforehand to a
                High Speed ​​Internet and / or mobile Internet offer in order to
                be able to use the&nbsp;DASTUDY&nbsp;Site&nbsp;.
              </p>
              <p>
                As a Subscriber, it is possible to activate
                the&nbsp;various&nbsp;Services&nbsp;available on
                the&nbsp;DASTUDY&nbsp;Site&nbsp;on a personal computer (PC or
                Mac) and a compatible mobile phone.&nbsp;However, the
                Service&nbsp;Study Premium&nbsp;will be accessible
                simultaneously that&nbsp;to&nbsp;from a single connection at a
                time (registered PC or registered mobile phone is compatible).
              </p>
              <p>
                It is specified that&nbsp;D&nbsp;IGITSAF&nbsp;SARL implements
                technical means to verify the absence of multiple simultaneous
                connections to the same&nbsp;Study Premium&nbsp;Service
                account&nbsp;and to detect multiple connection
                attempts.&nbsp;Such a&nbsp;maneuver&nbsp;is strictly prohibited.
              </p>
              <p>
                <strong>Personal data</strong>
              </p>
              <p>
                D&nbsp;IGITSAF&nbsp;SARL undertakes to comply with the
                legislation in force relating to the protection of privacy with
                regard to the automated processing of personal data,
                in&nbsp;particular the&nbsp;General Regulations for the
                Protection of Personal Data which entered into force on May 25,
                2018&nbsp;.
              </p>
              <p>
                As part of using the Site DASTUDY, &nbsp;personal
                data&nbsp;Subscriber&nbsp;may be required&nbsp;,
                including&nbsp;its&nbsp;name, email address, birth date, city,
                country&nbsp;, etc&nbsp;.&nbsp;The Subscriber declares on his
                honor that the said data is true.
              </p>
              <p>
                The information and data collected concerning the Subscriber is
                subject to mandatory computer processing because it is necessary
                for the management of his subscription.&nbsp;For this purpose,
                they may be sent to the companies responsible for the
                management, execution and processing of payment
                transactions&nbsp;(in the context of the use of one or other of
                the&nbsp;Study Premium&nbsp;or Study Shop
                services)&nbsp;.&nbsp;This information and data is also kept for
                security purposes.
              </p>
              <p>
                If the Subscriber has consented, the data collected may also be
                used as part of the management of our commercial relations in
                order to establish statistics and also
                allow&nbsp;D&nbsp;IGITSAF&nbsp;SARL to improve and personalize
                the services it offers him. and the information she sends him.
              </p>
              <p>
                In accordance with the law "Informatique et Libert&eacute;s" n
                &deg; 78-17 of January 6, 1978, the Subscriber has the right to
                access and rectify information concerning him.&nbsp;If he wishes
                to exercise this right and obtain communication of information
                concerning him, the Subscriber is invited to
                contact&nbsp;D&nbsp;IGITSAF&nbsp;SARL via the Site by clicking
                on the &ldquo;Contact us&rdquo; section or write to him.
              </p>
              <p>
                If the Subscriber has consented thereto (for example when
                creating his account with a view to registering for
                the&nbsp;Study Premium&nbsp;Service&nbsp;), the Subscriber may
                receive the newsletter&nbsp;("newsletter")
                from&nbsp;D&nbsp;IGITSAF&nbsp;SARL to his e-mail address
                as&nbsp;entered&nbsp;and, if necessary, modified by him.&nbsp;If
                the Subscriber no longer wishes to receive this newsletter, the
                Subscriber can unsubscribe at any time by clicking on the link
                provided for this purpose at the bottom of each newsletter or by
                modifying the data concerning him from his account on the Site
                by clicking on the &ldquo;Modify my personal data&rdquo;
                section.
              </p>
              <p>
                The Subscriber is informed that the data concerning him may be
                disclosed in application of a law, a regulation or by virtue of
                a decision of a competent regulatory or judicial authority or,
                if this proves necessary for&nbsp;D&nbsp;IGITSAF&nbsp;SARL, in
                the context of legal proceedings.
              </p>
              <p>
                <strong>Cookies</strong>
              </p>
              <p>
                D&nbsp;IGITSAF&nbsp;Ltd&nbsp;has&nbsp;temporarily&nbsp;resorted
                to the use of&nbsp;cookies&nbsp;s&nbsp;hasten the
                navigation&nbsp;Subscriber&nbsp;on DASTUDY Site.&nbsp;A cookie
                does not allow you to be personally identified.&nbsp;On the
                other hand, it records information relating to the navigation of
                the computer on the Site (the pages consulted, the date and time
                of the consultation, etc.)&nbsp;which will improve the
                Subscriber's user experience&nbsp;.&nbsp;This will
                allow&nbsp;D&nbsp;IGITSAF&nbsp;SARL to facilitate navigation on
                the site and will prevent&nbsp;the Subscriber&nbsp;from having
                to provide, on each new visit, information already communicated
                at an earlier date.
              </p>
              <p>
                The navigation software makes it possible to inform of the
                presence of &ldquo;cookies&rdquo; and the Subscriber can oppose
                the recording of &ldquo;cookies&rdquo;.&nbsp;This procedure is
                specified in the &ldquo;Personal Data&rdquo; section of the
                Site.
              </p>
              <p>
                <strong>Intellectual property</strong>
              </p>
              <p>
                The &nbsp;Site DASTUDY&nbsp;and all elements thereof (including
                logos,&nbsp;keyword "&nbsp;Dastudy&nbsp;"&nbsp;domain
                names,&nbsp;documents,
                software,&nbsp;images,&nbsp;videos,&nbsp;texts and visual
                packaging of these records) are the exclusive property
                of&nbsp;D&nbsp;IGITSAF&nbsp;SARL or its licensors (in
                particular&nbsp;authors or producers of books and
                software).&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                These elements are protected by laws relating to intellectual
                property and others.&nbsp;The Subscriber shall not use these
                elements in the context of the use
                of&nbsp;art&nbsp;s&nbsp;ervice&nbsp;s offered on the Website
                DASTUDY&nbsp;accordance with the provisions of these terms of
                use.
              </p>
              <p>
                The&nbsp;content (&nbsp;Documents&nbsp;and&nbsp;Software&nbsp;)
                present&nbsp;on&nbsp;the
                &nbsp;Site&nbsp;DASTUDY&nbsp;is&nbsp;free unless stated purpose
                of the sale price by the author&nbsp;during his
                posting&nbsp;.&nbsp;Once downloaded, the use of the
                content&nbsp;for commercial purposes&nbsp;is strongly prohibited
                and constitutes&nbsp;would&nbsp;violate the Code of intellectual
                property.
              </p>
              <p>
                Any total or partial representation of
                the&nbsp;DASTUDY&nbsp;Site&nbsp;and / or the elements composing
                them (as described above) by any means whatsoever, without
                the&nbsp;formal&nbsp;authorization&nbsp;of&nbsp;D&nbsp;IGITSAF&nbsp;SARL
                is therefore prohibited and would constitute an infringement
                punishable by the Articles L. 335-2 and following of the
                Intellectual Property Code.
              </p>
              <p>
                The Subscriber undertakes not to employ any technical measure
                likely to allow the circumvention of technical protection
                measures in order to download&nbsp;digital files for a&nbsp;fee
                and / or accessible only on a premium subscription,&nbsp;and to
                allow their conservation in the 'storage unit for its reception
                terminal whatever it is (PC,&nbsp;digital tablet,&nbsp;mobile
                phone, etc.).
              </p>
              <p>
                The Subscriber declares to take note that the fact of knowingly
                infringing a technical protection measure exposes him, by
                application of the provisions of article L. 335-4-1 of the
                Intellectual Property Code to the payment of a fine. of 3,750
                euros&nbsp;,&nbsp;and that the fact of knowingly procuring or
                offering to others, directly or indirectly, means designed or
                specially adapted to infringe a technical protection
                measure&nbsp;is&nbsp;for its part punishable by imprisonment for
                six months. and a fine of 30,000 euros.
              </p>
              <p>
                <strong>
                  Suspension and / or early termination&nbsp;of subscription
                </strong>
              </p>
              <p>
                Without prejudice to any damages
                that&nbsp;D&nbsp;IGITSAF&nbsp;SARL may
                request,&nbsp;D&nbsp;IGITSAF&nbsp;SARL reserves the right to
                suspend a Subscriber's access to the&nbsp;Study
                Premium&nbsp;Service&nbsp;and / or to automatically terminate,
                without notice or compensation, his subscription to
                the&nbsp;Study Premium&nbsp;Service&nbsp;in case of:
              </p>
              <p>
                -&nbsp;Non&nbsp;-compliance by the Subscriber with these general
                conditions of use and sale&nbsp;;
              </p>
              <p>
                -&nbsp;Non-enforcement of intellectual property rights
                DIGITSAF&nbsp;Ltd and / or its licensor&nbsp;s&nbsp;;
              </p>
              <p>
                -&nbsp;Circumvention or attempt to circumvent the technical
                protection measures put in place
                by&nbsp;D&nbsp;IGITSAF&nbsp;SARL;
              </p>
              <p>
                -&nbsp;Multiple simultaneous connections to the same&nbsp;Study
                Premium&nbsp;Service account&nbsp;or attempted multiple
                simultaneous connections;
              </p>
              <p>
                -&nbsp;Providing false information when registering&nbsp;at one
                of the&nbsp;Service&nbsp;'s&nbsp;Study Study or Premium
                Shop&nbsp;;
              </p>
              <p>
                -&nbsp;No&nbsp;total or partial payment by the Subscriber of the
                price of his subscription to the&nbsp;Study
                Premium&nbsp;Service&nbsp;;
              </p>
              <p>
                -&nbsp;Acts contrary to the commercial interests
                of&nbsp;D&nbsp;IGITSAF&nbsp;SARL.
              </p>
              <p>
                For his part, the Subscriber may terminate, without notice or
                compensation for the benefit of&nbsp;D&nbsp;IGITSAF&nbsp;SARL,
                his package or his subscription for legitimate reasons (in the
                event of a serious breach by&nbsp;D&nbsp;IGITSAF&nbsp;SARL of
                its main obligations defined in these general conditions) .
              </p>
              <p>
                <strong>Hypertext links</strong>
              </p>
              <p>
                The&nbsp;DASTUDY&nbsp;S&nbsp;ite&nbsp;may contain hypertext
                links referring to&nbsp;third party&nbsp;pages or
                sites&nbsp;,&nbsp;the content of which does&nbsp;not&nbsp;in any
                way&nbsp;commit the company&nbsp;D&nbsp;IGITSAF&nbsp;SARL&nbsp;.
              </p>
              <p>
                The hypertext links
                to&nbsp;the&nbsp;e&nbsp;S&nbsp;ite&nbsp;DASTUDY&nbsp;are&nbsp;accepted
                and warmly encouraged&nbsp;since they&nbsp;emanate sites
                complying with&nbsp;the terms and conditions of use such as
                above-defined&nbsp;.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Modification of conditions</strong>
              </p>
              <p>
                DIGITSAF&nbsp;SARL reserves the right to modify
                these&nbsp;general conditions of use&nbsp;at its
                discretion&nbsp;.&nbsp;D&nbsp;IGITSAF&nbsp;SARL inform each
                Subscriber to the address by email on the Subscriber's
                account&nbsp;any changes to these terms and conditions at
                least&nbsp;2&nbsp;0&nbsp;(&nbsp;twenty&nbsp;) days before they
                take effect.
              </p>
              <p>
                In the event that the Subscriber does not accept these
                modifications, he will be free to terminate his subscription
                from his account on the Site, this termination taking effect on
                the next monthly subscription deadline.&nbsp;In the absence of
                termination, the new general conditions of use and sale will
                therefore be applicable from the next subscription expiry date
                following the entry into force of the new&nbsp;
                <a name="_Hlk73317863"></a>general conditions of use&nbsp;.
              </p>
              <p>Law and&nbsp;Jurisdiction</p>
              <p>
                The general conditions of use&nbsp;as defined above&nbsp;will be
                governed and interpreted in accordance with the laws of the
                State of&nbsp;Cameroon.&nbsp;In case&nbsp;of failure resolution
                amicably, any dispute&nbsp;devr&nbsp;to&nbsp;be worn&nbsp;to the
                exclusive jurisdiction of the
                courts&nbsp;competent&nbsp;Cameroonians&nbsp;.
              </p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Terms;
