import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthService } from '../../services'
import { APP_ROOT_URL } from '../../constants/url'


const Logout = () => {
    const history = useHistory()
    AuthService.logout()
    history.push(APP_ROOT_URL)
    window.location.reload()
}

export default Logout