import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import { userFollowing, userPage } from "../../../services/main.service";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MyPages = (props) => {
  const [page, setPage] = React.useState();
  const [following, setFollowing] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(0);
  const [load, setLoad] = React.useState(false);
  const limit = 10;
  const { t } = useTranslation();
  const pagginationHandler = (page) => {
    setCurrentPage(page.selected + 1);
    setLoad(true);
  };
  React.useEffect(() => {
    if (!load) {
      userPage().then((res) => {
        if (res.status == 200) setPage(res.data);
      });
      userFollowing(props.user?.username).then((res) => {
        if (res.status == 200) setFollowing(res.data);
      });
    } else {
      setStart((currentPage - 1) * limit);
      setEnd(currentPage * limit);
      setLoad(false);
    }
  }, [props.user, currentPage]);

  return (
    <>
      <Header menu={8} />
      <div id="content" className="content">
        <div className="account_page  pagecontent mes_pages mew_publicites">
          <div className="container">
            <div className=" displayflex">
              <div className="mes_favoris_left">
                <h1 className="titleh1 text-black">{t("profil.pages")}</h1>
                <div className="abonne displayflex">
                  <div className="abonne_infosleft displayflex">
                    <div className="abonne_picture">
                      <img
                        className="lazy"
                        src={
                          page?.profile_picture
                            ? page.profile_picture
                            : "/assets/images/avatar.png"
                        }
                        alt="Dastudy"
                      />
                    </div>
                    <div className="abonne_texte">
                      <div className="abonne_name">
                        {page?.first_name ? page.first_name : page?.username}
                      </div>
                      <div className="abonne_nbfollowers"> 
                        {page?.follower_count} {t("common.follower")}
                      </div>
                    </div>
                  </div>
                  <div className="abonne_link">
                    <Link to={"/"+page?.username} title="Voir la page">
                    {t("page.see")}
                    </Link>
                  </div>
                </div>
                <div className="accounttop abonne_intro displayflex">
                  <h2 className="titleh2">{t("page.following")}</h2>
                  <div className="nbresults_infos results_uploads">
                     {t("common.result")}
                    <span>
                      {start} - {end}
                    </span>{" "}
                    {t("common.to")} <span>{following?.length}</span>
                  </div>
                </div>
                <div className="listes_abonnes">
                  {following?.slice(start, end).map((item, i) => (
                    <div className="abonne displayflex">
                      <div className="abonne_infosleft displayflex">
                        <div className="abonne_picture">
                          <img
                            className="lazy"
                            src={
                              item?.profile_picture
                                ? item.profile_picture
                                : "/assets/images/avatar.png"
                            }
                            alt="Dastudy"
                          />
                        </div>
                        <div className="abonne_texte">
                          <div className="abonne_name"> {item?.first_name ? item.first_name : item?.email}</div>
                          <div className="abonne_nbfollowers">
                          {item?.follower_count} {t("common.follower")}
                          </div>
                        </div>
                      </div>
                      <div className="abonne_link">
                      <Link to={"/"+item.username} title="Voir la page">
                      {t("page.see")}
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="pagination">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    activeClassName={"active"}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    initialPage={currentPage - 1}
                    pageCount={following?.length / limit}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={pagginationHandler}
                  />
                </div>
              </div>
              <SidebarAdd number={1} />
            </div>
          </div>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default connect((state) => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
}))(MyPages);
