import React from "react";
import { getBookmark, saveBookmark } from "../services/main.service";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

const AddBookmark = (props) => {
  const [show, setShow] = React.useState(false);
  const [isInBoomark, setIsInBoomark] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  React.useEffect(() => {
    getBookmark().then((res) => {
      if (res.status == 200) {
        setIsInBoomark(
          res.data.results.filter((item) => item.resource.id == props.id)
            .length > 0
        );
        setShow(
          res.data.results.filter((item) => item.resource.id == props.id)
            .length > 0
        );
      }
    });
  }, []);
 const paymentModal=()=>{
    swal(t("common.gopremium"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete)=>{
        if(willDelete) history.push("/subscription-plan")
    })
  }
  const loginModal=()=>{
    swal(t("common.login"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete)=>{
        if(willDelete) history.push("/login")
    })
  }
  const addToBookmark = (e) => {
    e.preventDefault();
    if (props.user) {
      if (props.isActiveSubcription) {
        setShow(true);
        setIsInBoomark(true);
        saveBookmark(props.id).then((res) => {
          console.log(res);
        });
      } else {
        paymentModal();
      }
    } else {
      loginModal();
    }
  };
  return (
    <div className="ds-btn primary">
      {!isInBoomark && (
        <a href="#" title="Ajouter" onClick={(e) => addToBookmark(e)}>
          + {t("common.add_bookmark")}
        </a>
      )}

      {show && (
        <a href="#" title="Favoris">
          {t("common.bookmark")}
        </a>
      )}
    </div>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  isActiveSubcription: state.auth.isActiveSubcription,
}))(AddBookmark);
