import React, { useState, useEffect } from "react";
import {
  Link as RouterLink,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Alert from "@material-ui/lab/Alert";


import { LOGIN_URL } from "../../constants/url";
import { register } from "../../redux/actions/auth";
import { log } from "../../helpers";

import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Register = () => {
  const [username, setUsername] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [showRegislationSuccessCard, setShowRegislationSuccesCard] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();



  const handleChange = (e, setValue) => setValue(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    dispatch(
      register({
        username,
        email,
        firstName,
        lastName,
        password,
        password_confirm: passwordConfirm,
      })
    ).then((response) => {
      if (response && response.status === 201) {
        // alert('Regislation success')
        setShowRegislationSuccesCard(true);
        setLoading(false);
      } else {

        const responseError = response.data;
        const errorMessages = [];
        for (const property in responseError) {
          responseError[property].forEach((err) =>
            errorMessages.push(property + ": " + err)
          );
        }
        setErrors(errorMessages);
        setLoading(false);
      }
    });

    //history.push('/')
  };

  const classes = useStyles();

  if (showRegislationSuccessCard) {
    return (
      <Container component="main" maxWidth="xs">
        Hello
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            FELICITATION
          </Typography>

          <br />
          <br />

          <div className="card">
            <div className="card-body">
              <div className="alert alert-success" role="success">
                Votre compte a été crée avec succès. Un email vous a été envoyé
                à l'adresse <b>{email}</b> <br />
                Merci de bien vouloir consulter votre boite de reception.
              </div>
            </div>
          </div>
        </div>
        <Box mt={5}>
          <span style={{ color: "#F50057", marginLeft: "16px" }}>
            DASTUDY, Explorer votre passion
          </span>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            NOUVEAU COMPTE
          </Typography>

          <form className={classes.form} noValidate>
            {/*---------- Affichage des erreurs de validation -------------- */}
            {errors.length > 0 && (
              <Alert severity="error">
                {errors.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </Alert>
            )}
            {/*-----X---- Affichage des erreurs de validation --------X----- */}

            <br />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => handleChange(e, setUsername)}
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="Not d'utilisateur"
                  name="username"
                  autoComplete="lname"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => handleChange(e, setFirstName)}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  id="firstName"
                  label="First Name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => handleChange(e, setlastName)}
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={(e) => handleChange(e, setEmail)}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={(e) => handleChange(e, setPassword)}
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={(e) => handleChange(e, setPasswordConfirm)}
                  variant="outlined"
                  required
                  fullWidth
                  name="cpassword"
                  label="Confirmation"
                  type="password"
                  id="cpassword"
                  autoComplete="current-password"
                />
              </Grid>
              {/* 
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid>
                        */}
            </Grid>
            {/*                     
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Je m'inscris
                    </Button> */}

            <Grid xs={12} item>
              <br />
              {/* <ButtonLoader loadData={isValidate} onClick={handleSubmit} text="Je m'inscris" /> */}
              <button
                onClick={handleSubmit}
                style={{ backgroundColor: "#007bff", opacity: ".8" }}
                disabled={loading}
                className="btn btn-primary btn-block">
                {!loading ? (
                  <span>INSCRIPTION</span>
                ) : (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>
              <br />
            </Grid>

            <Grid container justify="flex-end">
              <Grid item>
                <RouterLink to={LOGIN_URL} variant="body2">
                  Vous avez un compte ? Connectez-vous
                </RouterLink>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <span style={{ color: "#F50057", marginLeft: "16px" }}>
            DASTUDY, Explorer votre passion
          </span>
        </Box>
      </Container>
    </>
  );
};

export default Register;
