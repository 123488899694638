import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";

import SidebarFilter from "../../../components/SidebarFilter";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import CategoryItem from "../../../components/items/CategoryItem";
import {
  allCategories,
  useQuery,
} from "../../../services/main.service";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Search from "../../../components/Search";
import Skeleton from "react-loading-skeleton";
import { useHistory, useParams } from "react-router-dom";
import { stringToSlug } from "../../../helpers/functions";
const $ = window.jQuery;

const Categories = (props) => {
  const [data, setData] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const { t } = useTranslation();
  const query = useQuery();
  const { type, sub_category } = useParams();
  let history = useHistory();
  const item=props.menus?.find(item=>stringToSlug(item.name)==sub_category)
  const cat = props.cat && props.type=="cat"? props.cat : item!=undefined && item.pk;
  let main = type=="documents"?props.book?.pk:props.software?.pk
  React.useEffect(() => {
    setLoad(true);
     allCategories(cat?cat:main).then((res) => {
      setLoad(false);
      if (res.status == 200) setData(res.data);
    });

  }, [type,cat]);
  return (
    <>
      <Header menu={type=="documents"?2:type=="softwares"&&4} title={t("common."+type+"_title")} />
      <div id="content" className="content page_categorie page_documents cat">
        <div className="contentbar displayflex">
          <SidebarFilter path={type=='documents'?"books":type} />
          <div className="centerbar">
            <div className="topbannersearch displayflex">
              <Search setData={setData} type={type=='documents'?"DOCUMENT":"SOFTWARE"} />
            </div>
            <div className="beadcrumbs displayflex">
              <ul className="displayflex">
                <li>
                  <a href="#" title="#">
                    {t("common."+type)}
                  </a>
                </li>
                <li>
                  <a href="#" title="#" onClick={()=>cat&&history.goBack()}>
                   {cat? props.category?.name : t("common.categories")}
                  </a>
                </li>
                {cat &&<li>{t("common.subCategories")}</li>}
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane active" id="livres">
                <div className="tutoriels documents  listebook row ">
                  {load && <div className="col-md-12"><Skeleton count={20} height={50} /></div>}
                  {!load && data.map((item, i) => (
                    <CategoryItem item={item} allmenus={props.menus||[]} type={type} action={props.type} />
                  ))}
                  {data.length == 0 && (
                    <div style={{ marginLeft: "20px" }}>
                      {t("common.noresult")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <SidebarAdd type={type=='documents'?"book":type}/>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  menus: state.menus.menus,
  category: state.menus.category,
  book: state.menus.book,
  software: state.menus.software,
}))(Categories);
