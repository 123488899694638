import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";

import SidebarFilter from "../../../components/SidebarFilter";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import BookContent from "../../../components/app/books/BookContent";
import BookItem from "../../../components/items/BookItem";
import { connect } from "react-redux";
import { documents } from "../../../services/main.service";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { stringToSlug } from "../../../helpers/functions";

const $ = window.jQuery;
 
const BookDetail = (props) => {
  const { category, id} = useParams();
  const [data, setData] = React.useState([]);
  const { t } = useTranslation();
  const cat=props.menus?.find(item=>stringToSlug(item.name)==category)

  React.useEffect(() => {
    documents(1,cat?.pk||"").then((res) => {
      if (res.status == 200) setData(res.data.results.filter(item=>item.id!=id));
      $(".catslidebook").slick({
        autoplay: true,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: true,
        centerPadding: "150px",
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              centerPadding: "60px",
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              centerPadding: "100px",
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "100px",
            },
          },
        ],
      });
    });
  }, []);

  return (
    <>
      <Header menu={2} title={t("common.books")} />
      <div id="content" className="content page_categorie page_documents">
        <div className="contentbar displayflex">
          <SidebarFilter path="books" />
          <BookContent type="detail" />
          <SidebarAdd number={1} type="book" />
        </div>
        <div
          id="documents"
          className="documents tutoriels homebloc slidebloc slidepadding"
        >
          <div className="container">
            <div className="homebloc_cover">
              <h2 className="homebloctitle">
                <span>
                  {props?.user
                    ? t("common.similar_doc")
                    : t("common.must_read")}{" "}
                </span>
              </h2>
              <div className="homebloc_content">
                <div className="catslidebook">
                  {data.map((item, i) => (
                    <BookItem key={i} item={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  menus: state.menus.menus
}))(BookDetail);
