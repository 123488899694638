import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import Alert from "@material-ui/lab/Alert";

import { validator } from "../../helpers";
import { useTranslation } from "react-i18next";

import { login, register, resetLink } from "../../redux/actions/auth";
import { log } from "../../helpers";
import $ from "jquery";
import { checkRegistration, profil } from "../../services/main.service";
import { LOGIN_SUCCESS } from "../../redux/actions/types";
import { useCookies } from "react-cookie";
import PasswordChecklist from "react-password-checklist";
import SocialLoging from "../SocialLoging";

const generateArrayOfYears = () => {
  var max = new Date().getFullYear();
  var min = max - 100;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};
const Login = () => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState("");
  const [isValidate, setIsValidate] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isReset, setIsreset] = useState(false);
  const [step, setStep] = useState(1);
  const [menu, setMenu] = useState(
    localStorage.getItem("menu") ? localStorage.getItem("menu") : 1
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["token"]);
  const signUpBtnRef = React.useRef(null);
  const signInBtnRef = React.useRef(null);

  const day = Array.from({ length: 31 }, (_, i) =>
    i + 1 < 10 ? "0" + (i + 1) : "" + (i + 1)
  );
  const month = Array.from({ length: 12 }, (_, i) =>
    i + 1 < 10 ? "0" + (i + 1) : "" + (i + 1)
  );
  const year = generateArrayOfYears();
  const [firstName, setFirstName] = useState(null);
  const [registrationCoupon, setRegistrationCoupon] = useState(null);
  const [email, setEmail] = useState(null);
  const [active, setActive] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [showRegislationSuccessCard, setShowRegislationSuccesCard] =
    useState(false);
  const [showResetSuccessCard, setShowResetSuccesCard] = useState(false);
  const rules = [
    {
      field: "email",
      validations: ["required"],
      name: "User email", // used to show in error message
    },
    {
      field: "password",
      validations: ["required"],
      name: "password",
    },
  ];
  const successMessage = useSelector((state) =>
    state.message.type === "ACCOUNT_VERIFIED" ? state.message : null
  );

  /**
   * Get login and password input values
   * @param {*} e
   * @param {*} setValue
   */
  const handleChange = (e, setValue) => setValue(e.target.value);

  /**
   *
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!(username && password)) {
      setErrors(["login and password is required"]);
      return;
    }

    setLoading(true);

    const email = username;
    const fields = { email, password };

    const validatorRes = validator(fields, rules);
    setIsValidate(validatorRes.isValid);
    setErrors(validatorRes.errors);

    if (isValidate) {
      dispatch(login(username, password)).then((response) => {
        if (response.status && response.status === 200) {
          setCookie("token", response.user.accessToken, {
            path: "/",
            maxAge: 3600 * 24 * 3,
          });
          profil()
            .then((res) => {
              if (res.status == 200) {
                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: { user: res.data },
                });
                if (localStorage.getItem("first_login") == "ok") {
                  localStorage.setItem("first_login", "");
                  window.location = "/discussion";
                } else {
                  window.location = "/discussion";
                  //history.goBack();
                }

                setLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setLoading(false);
          if (response.status) {
            const responseError = response.data.detail;
            setErrors([responseError]);
          } else {
            console.log(response.error);
            setErrors([response.error]);
            console.log(errors);
          }
        }
      });
    }
  };

  const saveUser = (e) => {
    e.preventDefault();
    // console.log(registrationCoupon);

    setLoading(true);
    dispatch(
      register({
        username,
        email,
        first_name: firstName,
        last_name: firstName,
        registration_coupon: registrationCoupon,
        password,
        password_confirm: passwordConfirm,
        // gender: data.get("sexe"),
        // country: data.get("pays"),
        // town: data.get("ville"),
        // profession: data.get("profession"),
        // birth_date:data.get("year") + "-" + data.get("month") + "-" + data.get("day"),
      })
    ).then((response) => {
      $("html, body").animate({ scrollTop: 100 }, "slow");
      if (response && response.status === 201) {
        localStorage.setItem("first_login", "ok");
        setShowRegislationSuccesCard(true);
        setLoading(false);
      } else {
        log("Register action response", response.data);
        if (response.status) {
          const responseError = response.data;
          const errorMessages = [];
          for (const property in responseError) {
            if (responseError[property] && responseError[property].length > 0) {
              responseError[property].forEach((err) =>
                errorMessages.push(property + ": " + err)
              );
            }
          }
          setErrors(errorMessages);
          setLoading(false);
        } else {
          console.log(response.error);
          setErrors([response.error]);
          console.log(errors);
          setLoading(false);
        }
      }
    });

    //history.push('/')
  };

  const sendResetPasswordLink = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    setLoading(true);
    dispatch(
      resetLink({
        login: username,
      })
    ).then((response) => {
      console.log(response);
      if (response && response.status == 200) {
        setShowResetSuccesCard(true);
        setLoading(false);
      } else {
        if (response.status) {
          const responseError = response.data.detail;
          setErrors([responseError]);
          setLoading(false);
        } else {
          console.log(response.error);
          setErrors([response.error]);
          console.log(errors);
          setLoading(false);
        }
      }
    });

    //history.push('/')
  };

  const checkData = (value, param) => {
    setLoading(true);
    checkRegistration({
      email: param == "email" ? value : email,
      username: param == "username" ? value : username,
    }).then((response) => {
      if (response && response.status === 204) {
        setErrors([]);
        setLoading(false);
      } else {
        log("Register action response", response.data);
        if (response.status) {
          const responseError = response.data;
          const errorMessages = [];
          for (const property in responseError) {
            if (
              (property == "email" || property == "username") &&
              responseError[property] &&
              responseError[property].length > 0
            ) {
              if (
                responseError[property] ==
                  "A user with that email already exists." ||
                responseError[property] ==
                  "Un utilisateur avec ce nom existe déjà."
              )
                responseError[property].forEach((err) =>
                  errorMessages.push(property + ": " + err)
                );
            }
          }
          setErrors(errorMessages);
          setLoading(false);
        } else {
          console.log(response.error);
          setErrors([response.error]);
          console.log(errors);
          setLoading(false);
        }
      }
    });
    //history.push('/')
  };
  useEffect(() => {
    $(".current_choice").click(function () {
      $(this).next("ul").slideToggle();
    });
    $(".current_choice + ul li").click(function () {
      var content = $(this).text();
      i18n.changeLanguage(content.toLowerCase());
      localStorage.setItem("lng", content.toLowerCase());
      $(this).parent("ul").prev(".current_choice").find("span").text(content);
    });
  }, []);

  const switchToLogin = () => {
    signUpBtnRef.current.click();
  };

  const switchToRegister = () => {
    signInBtnRef.current.click();
  };

  return (
    <div style={{ background: "#1382BF" }} className="page_connexion">
      <header id="header" className="header">
        <div className="container">
          <div className="header_cover displayflex">
            <nav className="navbarsite displayflex">
              <div className="navleft displayflex">
                <div className="logosite navitem">
                  <Link to="/" title="Dastudy">
                    <img
                      className="lazy"
                      src="/assets/images/logo.png"
                      alt="Dastudy"
                    />
                  </Link>
                </div>
                <div className="langue_site navitem">
                  <label className="current_choice">
                    <span>{i18n.language == "en" ? "En" : "Fr"}</span>
                    <i className="bi bi-chevron-down"></i>
                    <i className="bi bi-chevron-up"></i>
                  </label>
                  <ul className="submenu">
                    <li>Fr</li>
                    <li>En</li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>

      <div id="content" className="content">
        <div className="connexionpage">
          <div className="container">
            <div className="connect_cover">
              <h1 className="titleh1">{t("banner.explore")}</h1>
              <div className="connect_content">
                <nav className="nav nav-pills">
                  <a
                    ref={signInBtnRef}
                    className={
                      menu == 1
                        ? "nav-item nav-link  active"
                        : "nav-item nav-link"
                    }
                    href="#seconnecter"
                    data-toggle="tab">
                    {t("login.login")}
                  </a>
                  <a
                    ref={signUpBtnRef}
                    className={
                      menu == 2
                        ? "nav-item nav-link  active"
                        : "nav-item nav-link"
                    }
                    href="#creeruncompte"
                    data-toggle="tab">
                    {t("login.register")}
                  </a>
                </nav>
                <div className="tab-content">
                  <div
                    className={menu == 1 ? "tab-pane  active" : "tab-pane"}
                    id="seconnecter">
                    <div className="connectform">
                      <SocialLoging key={menu} />
                      {!isReset ? (
                        <form onSubmit={handleSubmit}>
                          {/*---------- Affichage des erreurs de validation -------------- */}
                          {(!isValidate || errors.length > 0) && (
                            <Alert severity="error" className="mtb-32">
                              <ul style={{ padding: 0, paddingLeft: "10px" }}>
                                {errors.map((error, i) => (
                                  <li key={i}>{t("common.login_error")}</li>
                                ))}
                              </ul>
                            </Alert>
                          )}
                          {/*-----X---- Affichage des erreurs de validation --------X----- */}

                          {/*---------- Affichage des Message de succès -------------- */}
                          {successMessage && (
                            <Alert severity="success" className="mtb-32">
                              {successMessage.content}
                            </Alert>
                          )}
                          {/*-----X---- Affichage des Message de succès --------X----- */}

                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder={t("login.email")}
                              required
                              onChange={(e) =>
                                e.target.value != null
                                  ? handleChange(e, setUsername)
                                  : null
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              <input
                                name="password"
                                type={active ? "text" : "password"}
                                className="form-control"
                                placeholder={t("login.password")}
                                onChange={(e) => handleChange(e, setPassword)}
                                required
                              />
                              <i
                                onClick={() => setActive(!active)}
                                className={
                                  active
                                    ? "bi bi-eye-fill pass"
                                    : "bi bi-eye-slash-fill pass"
                                }></i>
                            </label>
                            <div className="checkcustom">
                              <a
                                href="#"
                                className="text-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsreset(true);
                                }}>
                                {t("login.forgot")}
                              </a>
                            </div>
                          </div>
                          <div className="checkbox_field displayflex">
                            <div className="form-check custom-control custom-checkbox">
                              <label className="checkcustom displayflex">
                                <span>{t("login.notrobot")}</span>
                                <input
                                  type="checkbox"
                                  name="is_name"
                                  required
                                />
                                <span className="checkicon"></span>
                              </label>
                            </div>
                            <button type="submit" className="btnSubmit">
                              {t("login.connexion")}
                              {loading && (
                                <span className="spinner-border spinner-border-sm ml-2"></span>
                              )}
                            </button>
                          </div>

                          <div className="auth-form-action-link">
                            <span>
                              {t("header.do_not_already_have_account")}
                            </span>
                            <a href="#seconnecter" onClick={switchToLogin}>
                              &nbsp;{t("header.signup")}
                            </a>
                          </div>
                        </form>
                      ) : (
                        <form onSubmit={sendResetPasswordLink}>
                          {/*---------- Affichage des erreurs de validation -------------- */}
                          {(!isValidate || errors.length > 0) && (
                            <Alert severity="error" className="mtb-32">
                              <ul style={{ padding: 0, paddingLeft: "10px" }}>
                                {errors.map((error, i) => (
                                  <li key={i}>{t("common.not_found")}</li>
                                ))}
                              </ul>
                            </Alert>
                          )}
                          {/*-----X---- Affichage des erreurs de validation --------X----- */}

                          {/*---------- Affichage des Message de succès -------------- */}
                          {showResetSuccessCard && (
                            <Alert severity="success" className="mtb-32">
                              {t("login.success")}
                            </Alert>
                          )}
                          {/*-----X---- Affichage des Message de succès --------X----- */}

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("login.email")}
                              required
                              onChange={(e) =>
                                e.target.value != null
                                  ? handleChange(e, setUsername)
                                  : null
                              }
                            />
                          </div>

                          <div className="checkbox_field displayflex">
                            <div className=" ">
                              <label className="displayflex">
                                <a
                                  href="#"
                                  className="text-white"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsreset(false);
                                  }}>
                                  {t("login.login")}
                                </a>
                              </label>
                            </div>
                            <button type="submit" className="btnSubmit">
                              {t("common.send")}
                              {loading && (
                                <span className="spinner-border spinner-border-sm ml-2"></span>
                              )}
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                  <div
                    className={menu == 2 ? "tab-pane  active" : "tab-pane"}
                    id="creeruncompte">
                    <div className="connectform">
                      <SocialLoging key={menu} />

                      <form onSubmit={saveUser}>
                        {/* <ul className="btn_nb displayflex">
                          <li
                            // onClick={() => setStep(1)}
                            className={step == 1 && "active"}
                          >
                            1
                          </li>
                          <li
                            // onClick={() => setStep(2)}
                            className={step == 2 && "active"}
                          >
                            2
                          </li>
                        </ul> */}
                        {errors.length > 0 && !showRegislationSuccessCard && (
                          <Alert severity="error" className="mtb-32">
                            {errors.map((error, i) => (
                              <li key={i}>
                                {error?.includes("email:")
                                  ? t("common.email_error")
                                  : error?.includes("username:")
                                  ? t("common.username_error")
                                  : error}
                              </li>
                            ))}
                          </Alert>
                        )}
                        {showRegislationSuccessCard && (
                          <Alert severity="success" className="mtb-32">
                            {t("login.success1")}
                            <b>{email}</b> <br />
                            {t("login.thank")}
                          </Alert>
                        )}
                        {step == 1 && !showRegislationSuccessCard && (
                          <div id="etape1" className="etape1">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("login.name")}
                                name="name"
                                required
                                defaultValue={firstName}
                                onChange={(e) =>
                                  e.target.value != null
                                    ? handleChange(e, setFirstName)
                                    : null
                                }
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                placeholder={t("login.email")}
                                required
                                name="email"
                                onChange={(e) => {
                                  if (e.target.value != null)
                                    handleChange(e, setEmail);
                                  checkData(e.target.value, "email");
                                }}
                                defaultValue={email}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("login.registration_coupon")}
                                name="registration_coupon"
                                defaultValue={registrationCoupon}
                                onChange={(e) =>
                                  e.target.value != null
                                    ? handleChange(e, setRegistrationCoupon)
                                    : null
                                }
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("login.username")}
                                required
                                name="username"
                                onChange={(e) => {
                                  if (e.target.value != null)
                                    handleChange(e, setUsername);
                                  checkData(e.target.value, "username");
                                }}
                                pattern="[a-zA-Z0-9]*"
                                defaultValue={username}
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                <input
                                  type={active ? "text" : "password"}
                                  className="form-control"
                                  placeholder={t("login.password")}
                                  required
                                  name="password"
                                  onChange={(e) =>
                                    e.target.value != null
                                      ? handleChange(e, setPassword)
                                      : null
                                  }
                                  defaultValue={password}
                                />
                                <i
                                  onClick={() => setActive(!active)}
                                  className={
                                    active
                                      ? "bi bi-eye-fill pass"
                                      : "bi bi-eye-slash-fill pass"
                                  }></i>
                              </label>
                            </div>
                            <div className="form-group">
                              <label>
                                <input
                                  type={active ? "text" : "password"}
                                  className="form-control"
                                  placeholder={t("login.confirmPass")}
                                  required
                                  name="cpassword"
                                  onChange={(e) =>
                                    e.target.value != null
                                      ? handleChange(e, setPasswordConfirm)
                                      : null
                                  }
                                  defaultValue={passwordConfirm}
                                />
                                <i
                                  onClick={() => setActive(!active)}
                                  className={
                                    active
                                      ? "bi bi-eye-fill pass"
                                      : "bi bi-eye-slash-fill pass"
                                  }></i>
                              </label>
                            </div>
                            <div className="form-group">
                              <PasswordChecklist
                                rules={[
                                  "minLength",
                                  "number",
                                  "capital",
                                  "match",
                                ]}
                                minLength={6}
                                value={password}
                                valueAgain={passwordConfirm}
                                onChange={(val) => setIsValid(val)}
                                messages={{
                                  minLength:
                                    "Le mot de passe comporte au moins 6 caractères.",
                                  number:
                                    "Le mot de passe comporte un chiffre.",
                                  capital:
                                    "Le mot de passe comporte une majuscule.",
                                  match: "Les mots de passe correspondent.",
                                }}
                                className="text-left text-white"
                              />
                            </div>
                            <div className="form-group">
                              <div className="form-check custom-control custom-checkbox">
                                {" "}
                                <label className="checkcustom displayflex">
                                  <span>
                                    {t("login.read")}{" "}
                                    <Link
                                      to="/terms"
                                      style={{ color: "#fff", opacity: 0.5 }}>
                                      {t("login.accept")}
                                    </Link>
                                  </span>
                                  <input
                                    type="checkbox"
                                    name="is_name"
                                    required
                                  />
                                  <span className="checkicon"></span>
                                </label>
                              </div>
                            </div>
                            <div className="checkbox_field displayflex">
                              <div></div>
                              <button
                                type={"submit"}
                                disabled={!isValid}
                                className="btnSubmit">
                                {t("login.continue")}
                                {loading && (
                                  <span className="spinner-border spinner-border-sm ml-2"></span>
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                        {step == 2 && !showRegislationSuccessCard && (
                          <div id="etape2" className="etape2">
                            <div className="form-group date_naissance">
                              <label>{t("login.date")}</label>
                              <div className="select_group displayflex">
                                <div className="form-group select_field">
                                  <select className="form-control" name="day">
                                    <option>{t("login.day")}</option>
                                    {day.map((j, i) => (
                                      <option value={j}>{j}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="form-group select_field">
                                  <select className="form-control" name="month">
                                    <option>{t("login.month")}</option>
                                    {month.map((j, i) => (
                                      <option value={j}>{j}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="form-group select_field">
                                  <select className="form-control" name="year">
                                    <option>{t("login.year")}</option>
                                    {year.map((j, i) => (
                                      <option value={j}>{j}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>{t("login.genre")}</label>
                              <div className="group_genre checkbox_group displayflex">
                                <div className="form-check custom-control custom-checkbox">
                                  <label className="checkcustom displayflex">
                                    <span>{t("login.male")}</span>
                                    <input
                                      type="radio"
                                      name="sexe"
                                      value="MAN"
                                    />
                                    <span className="checkicon"></span>
                                  </label>
                                </div>
                                <div className="form-check custom-control custom-checkbox">
                                  <label className="checkcustom displayflex">
                                    <span>{t("login.female")}</span>
                                    <input
                                      type="radio"
                                      name="sexe"
                                      value="WOMAN"
                                    />
                                    <span className="checkicon"></span>
                                  </label>
                                </div>
                                <div className="form-check custom-control custom-checkbox">
                                  <label className="checkcustom displayflex">
                                    <span>{t("login.other")}</span>
                                    <input
                                      type="radio"
                                      name="sexe"
                                      value="OTHER"
                                    />
                                    <span className="checkicon"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>{t("login.profession")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Profession"
                                name="profession"
                              />
                            </div>
                            <div className="form-group pays_residence">
                              <label>{t("login.country")}</label>
                              <div className="select_group displayflex">
                                <div className="select_field">
                                  <select className="form-control" name="pays">
                                    <option value="CM">Cameroun</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>{t("login.city")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Massasuchet"
                                name="ville"
                              />
                            </div>
                            <div className="file_field form-group">
                              <label>{t("login.profil")}</label>
                              <div className="formfile">
                                <label for="input-file" className="displayflex">
                                  <span>{t("login.click")}</span>
                                </label>
                                <input
                                  id="input-file"
                                  type="file"
                                  name="image"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="form-check custom-control custom-checkbox">
                                {" "}
                                <label className="checkcustom displayflex">
                                  <span>{t("login.accept")}</span>
                                  <input
                                    type="checkbox"
                                    name="is_name"
                                    required
                                  />
                                  <span className="checkicon"></span>
                                </label>
                              </div>
                            </div>
                            <div className="form-group group_submit">
                              <div className="pass_to_premium">
                                <a href="#" title="Passez à Premium (1$)">
                                  {t("header.gotopremium")}(1$)
                                </a>
                              </div>
                              <button type="submit" className="btnSubmit">
                                {t("login.continue")}
                                {loading && (
                                  <span className="spinner-border spinner-border-sm ml-2"></span>
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="auth-form-action-link">
                          <span>{t("header.already_have_account")}</span>
                          <a href="#creeruncompte" onClick={switchToRegister}>
                            &nbsp;{t("header.signin")}
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer id="footer">
        <div className="footer_bottom bottom_connexion">
          <div className="container">
            <div className="footer_bottom_cover displayflex">
              <div className="foot_copyright">
                &copy; <script>document.write(new Date().getFullYear())</script>{" "}
                Dastudy LTD - {t("footer.allright")}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <style jsx>
        {`
          .form-control {
            background: #fff;
            border: 1px solid #00000029;
            font-size: 20px;
            color: #000;
            width: 100%;
            line-height: 50px;
            height: 50px;
            padding: 0 20px 5px;
            outline: none;
            box-shadow: none;
            border-radius: 0;
            cursor: pointer;
            opacity: 0.5;
          }
          .form-group {
            margin-bottom: 2rem;
          }
        `}
      </style>
    </div>
  );
};

export default Login;
