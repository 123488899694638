import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import ForumItem from "../../../components/items/ForumItem";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Pagination";
import {
  allCategories,
  forumPost,
  saveForumPost,
  useQuery,
} from "../../../services/main.service";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import Ad from "../../../components/ad";
const Forum = (props) => {
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const search = (val) => {
    setValue(val);
  };
  const { page } = useParams();
  const [currentPage, setCurrentPage] = React.useState(page ? page + "" : "1");
  const { t } = useTranslation();
  const [pageCount, setPageCount] = React.useState(0);
  const [load, setLoad] = React.useState(true);
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [categorie, setCateorie] = React.useState("");
  const [filterCategorie, setFilterCateorie] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  React.useEffect(() => {
    forumPost(currentPage, filterCategorie?.pk).then((res) => {
      setLoad(false);
      setPageCount(res.data?.count||0);
      if (res.status == 200) setData(res.data.results);
    });
    allCategories(props?.book?.pk).then((res) => {
      if (res.status == 200) setCategories(res.data);
    });
  }, [currentPage, filterCategorie]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    saveForumPost({
      message,
      title,
      category: categorie,
    })
      .then((res) => {
        setLoading(false);
        setMessage("");
        setTitle("");
        setCateorie("");
        const result = [res.data];
        setData([...result, ...data]);
        setPageCount(pageCount + 1);
        setSuccessMessage("Post créé avec succès");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Header menu={5} title={t("common.forum")} />
      <div
        id="content"
        className={
          value
            ? "content page_forum_response page_forum page_forumsearch"
            : "content page_forum "
        }
      >
        <div className="contentbar displayflex">
          <div className="centerbar">
            {!value ? (
              <div className="details_bloctitle">
                <h1 className="titleh1">{t("forum.title")}</h1>
              </div>
            ) : (
              <div class="filterbloc accounttop displayflex">
                <div class="details_bloctitle">
                  <h1 class="titleh1">Forum</h1>
                </div>
                <div class="nbresults_infos resultats">
                  {t("common.result")} <span>1-{data?.length}</span>{" "}
                  {t("common.to")} <span>{pageCount}</span>
                </div>
              </div>
            )}
            <div className="topbannersearch displayflex">
              <form
                id="searchform"
                className="searchform displayflex"
                action="#"
                method="#"
              >
                <div className="form-group search_field">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Recherchez sur Dastudy"
                    defaultValue={value}
                    onChange={(e) => search(e.target.value)}
                  />
                  <button type="submit"></button>
                </div>
              </form>
            </div>
            <div className="postdetailscover">
              {!value && props?.user && (
                <div className="forumform">
                  <form onSubmit={onSubmit}>
                    <p>{t("forum.question")}</p>
                    <div className="form-group">
                      {successMessage && (
                        <Alert severity="success" className="mtb-32">
                          {successMessage}
                        </Alert>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("forum.titre")}
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        name="title"
                      />
                    </div>
                    <div className="form-group select_field">
                      <select
                        name="category"
                        className="form-control"
                        onChange={(e) => setCateorie(e.target.value)}
                        value={categorie}
                      >
                        <option>{t("forum.category")}</option>
                        {categories?.map((cat, i) => (
                          <option value={cat.pk}>{cat.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="textarea form-control"
                        placeholder={t("forum.message")}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        value={message}
                      ></textarea>
                    </div>

                    <div className="submitfield">
                      <button type="submit" className="blue_button btn_submit">
                        {t("common.send")}
                        {loading && (
                          <span className="spinner-border spinner-border-sm ml-2"></span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {!value && (
                <Ad />
              )}
              {!value && (
                <div className="filterbloc accounttop displayflex">
                  <label>{t("forum.last")}</label>
                  <div className="nbresults_infos resultats">
                    {t("common.result")}{" "}
                    <span>
                      {data?.length}-{data?.length}
                    </span>{" "}
                    {t("common.to")} <span>{pageCount}</span>
                  </div>
                  <div className="maintenance">
                    <div className="catfliter">
                      <label className="current_choice">
                        <span>
                          {filterCategorie
                            ? filterCategorie.name +
                              `(${filterCategorie && pageCount})`
                            : "Filtrer"}
                        </span>
                        <i className="bi bi-chevron-down"></i>
                      </label>
                      <ul
                        style={{ maxHeight: "300px", overflowY: "auto", zIndex: 2 }}
                      >
                        <li onClick={(e) => setFilterCateorie(null)}>
                          {t("forum.all")}
                        </li>
                        {categories.map((cat, i) => (
                          <li onClick={(e) => setFilterCateorie(cat)}>
                            {cat.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <div className="details_list_users forum_listpost">
                {data.map((item, i) => (
                  <ForumItem item={item} />
                ))}
              </div>
            </div>
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount / 10}
              />
            </div>
          </div>
          {!value && <SidebarAdd number={1} />}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  book: state.menus.book,

}))(Forum);
