import React, { useEffect, useState } from "react";
import useAiDocumentAnalyzer from "../hooks/useAiDocumentAnalyzer";


export default function AiChatAssistant() {

    // const token = localStorage.getItem('token')
    const tokenL = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE0NTMzNjcyLCJpYXQiOjE3MTMzMjQwNzIsImp0aSI6IjdjMmQ1ZTU2YWMxMjQ4NDJhYzVmOGNlNmMyOTZmOWI4IiwidXNlcl9pZCI6ImIzYmZjYmIxLTczZTEtNDg2My1iOTdmLWY5MzVlMTU5NTE4MiJ9.JnV50VkMboBbMg_Tr3YuxVSp850U7dw_VYop4ymLTuo"
    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE0NTM5NTQwLCJpYXQiOjE3MTMzMjk5NDAsImp0aSI6IjY5ZWVkNTdkZjA1YTQxZGE5YzEwZDM4NzhlNGM3NGUyIiwidXNlcl9pZCI6IjZiMjkwYzgzLWU2MWYtNDJjZi05MDczLTEzNzdiOTc0NTJhMyJ9.TBE_TK317dhQj3g_OZCmCptiyNW8lCDrFG_nJtCwWf8'
    const [text, setText] = useState('')
    let docOnline = "a1b05758-5709-482b-8f98-ffe382cbc325"
    let docOffline = "99d11831-eb50-46e9-a0fe-5ff2a357325c"
    const { ask, isConnected, messageHistory, message, summarize, isWriting } = useAiDocumentAnalyzer({
        documentId: docOffline,
        authToken: token,
        onError: (e) => console.log({ e }),
    })

    const [actionDisabled, setActionDisabled] = useState(!isConnected || isWriting)

    const onSubmit = (e) => {
        e.preventDefault();
        ask(text)
    }

    useEffect(
        () => {
            console.log({actionDisabled, isWriting})
            if (isWriting){
                setActionDisabled(true)
            }else{
                setActionDisabled(!isConnected || isWriting)
            }
            
        },
        [isWriting, actionDisabled, setActionDisabled, isConnected]
    )

    return (
        <div>
            {isWriting === true ? <h3>Ici</h3> : <h3>Not writing</h3> }
            
            <span>{message}</span>
            
            <hr />
            {messageHistory.map((item, id) => <div key={id}> {item}</div>)}
            <form onSubmit={onSubmit}>
                <input disabled={actionDisabled} required type='text' value={text} onChange={(e) => setText(e.target.value)} />
                <button disabled={actionDisabled} type="submit">Submit</button>
            </form>
            <button disabled={actionDisabled} onClick={() => summarize()}>Resumer ce text</button>
        </div>
    )
}