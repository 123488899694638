import axios from "axios";

const HTTP_SCHEME = "https"
export const HTTP_DOMAIN = "api.dastudy.net"

export const PYTHON_API_ENDPOINT = `${HTTP_SCHEME}://${HTTP_DOMAIN}/api/v1/`;
export const API_ENDPOINT = `${HTTP_SCHEME}://${HTTP_DOMAIN}/`;
export const DOWNLOAD_ENDPOINT = "http://download.dastudy.net/api/v1/";

/**
 * Use this to get the user token.
 *
 * @function
 */
export const getToken = () => {
  return localStorage.getItem("token");
};

/**
 * Use this to authorize the user .
 *
 * @function
 * @example
 * return (
 *   "bearer Thjhsdjklldf54659dfjbjdfusjoodlld5dfu";
 * )
 */
export const getAuthorization = () => {
  const token = getToken();
  return token ? "Bearer" + " " + getToken() : " ";
};

const axiosInstance = axios.create({
  baseURL: PYTHON_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add Request interceptors
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    const isLang = localStorage.getItem("lng")?localStorage.getItem("lng"):"fr";
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (isLang) {
      config.headers["Accept-Language"] = isLang+"-"+isLang.toUpperCase();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export default axiosInstance;

export const LOGIN_API_ENPOINT = `accounts/login`;
export const REGISTER_API_ENPOINT = `accounts/register/`;
export const CHECK_REGISTATION = `accounts/check-registration`;
export const LOGOUT_API_ENPOINT = `accounts/logout/`;
export const REGISTER_EMAIL_API_ENPOINT = `accounts/register-email/`;
export const RESET_PASSWORD_API_ENPOINT = `accounts/reset-password/`;
export const SEND_RESET_PASSWORD_LINK_API_ENPOINT = `accounts/send-reset-password-link/`;
export const ACCOUNT_VERIFY_EMAIL_API_ENPOINT = `accounts/verify-email/`;
export const VERIFY_REGISTRATION_API_ENPOINT = `accounts/verify-registration/`;
export const ALL_CATEGORY = `menu`;
export const DOCUMENTS = `document`;
export const SOFTWARE = `software`;
export const FORUM_POST = `ai-question`;
export const FORUM_COMMENT = `forum-comment`;
export const MAIN_MENU = `main-menu`;
export const CATALOGUES = `catalogue`;
export const TUTORIAL = `video`;
export const PROFIL = `profile`;
export const USER_CONTENT = `profile/content`;
export const HOME = `home`;
export const REVIEWS = `reviews`;
export const UPLOAD = `upload`;
export const PRIVATE_VIDEO = `private-video`;
export const PRIVATE_DOCUMENT = `private-document`;
export const PRIVATE_SOFTWARE = `private-software`;
export const MY_UPLOADS = `profile/content`;
export const PAGE = `page`;
export const REQUEST_AD = `request-advert`;
export const BOOKMARK = `bookmark`;
export const FOLLOW = `follow`;
export const UNFOLLOW = `un-follow`;
export const SEARCH = `search`;
export const SALES = `profile/sales`;
export const PURCHASED = `profile/purchased`;
export const ADVERT = `promotions`;
export const RANDOM = `promotions/random`;
export const INTEREST = `interest`;
export const SOLDE = `profile/solded`;
export const SUBCCRIPTION = `subscription`;
export const ORDER = `order`;
export const PAYPAL = `paypal-payment`;
export const MOMO = `faroty-payment`;
export const STRIPE = `stripe-payment`;
export const STRIPESUBMIT = `submit-stripe-payment`;
export const BILLING_PLAN = `billing-plan`;
export const PAYDUNYA = `paydunya-form`;
export const MAKE_DONATION = `make-donation`;
export const DOWNLOAD = `download`;
export const SUBMIT_FEEDBACK = "submit-feedback";
export const LANGUES = "languages";
export const NOTIFICATIONS = "notifications";
export const COUNTRIES = "country";
export const SOCIAL = "social-auth/";
export const PDF = "pdf";
export const PRIVATE_LINK = "private-link-builder/";
export const VIEWPAY = "viewpay-payment";
export const VIEWPAY_REFRESH = "viewpay-payment-status-check";
export const CINETPAY = "cpay-payment";
export const FORUM_GET = "ai-my-questions";
export const FORUM_GET_TOKEN = "user-socket-token";
export const PAYPAL_CREATE = "create-paypal-order";
export const PAYPAL_CAPTURE = "capture-paypal-order";


