import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


import {
    REGISTER_URL,
    PASSWORD_FORGOT_URL
} from '../../constants/url'

import { AuthLayoutHigherOrderComponent } from '../../components'


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


function showForm(classes) {
    return <>

        <Typography component="h1" variant="h5">MOT DE PASSE OUBLIÉ</Typography>
        <form className={classes.form} noValidate>
            <Typography classes={'text-msg'} component="h6" variant="h6">
                Veuillez entrer votre adresse email
                 </Typography>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
            />


            <Button

                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                VALIDER
                    </Button>

            <Grid container>
                <Grid item>
                    <RouterLink to={REGISTER_URL} variant="body2">
                        {"Connectez vous"}
                    </RouterLink>
                </Grid>
            </Grid>
        </form>


    </>
}

function showMessage(classes) {
    return <>

        <Typography component="h1" variant="h5">MOT DE PASSE OUBLIÉ</Typography>
        <form className={classes.form} noValidate>
            <Typography classes={'text-msg'} component="h6" variant="h6">
                Un email de récupération de mot de passe vous a été envoyé. Veuillez consulter votre boite de reception.
                 </Typography>

        </form>


    </>
}

const PasswordForgot = () => {
    const classes = useStyles();

    return <>

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>

                {showMessage(classes)}

            </div>


            <Box mt={8}>
                <span style={{ color: '#F50057', marginLeft: '16px' }}>DASTUDY, Explorer votre passion</span>
            </Box>
        </Container>


    </>
}

export default AuthLayoutHigherOrderComponent(PasswordForgot)