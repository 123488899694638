import React from "react";
import { connect } from "react-redux";

import "./index.css";

import { Banner, Presentation } from "../index";

import { asyncComponent } from "../../helpers";
import { LOGIN_SUCCESS } from "../../redux/actions/types";

import Header from "../header";
import Footer from "../footer";
import { useTranslation } from "react-i18next";

const $ = window.jQuery;

const App = () => {
  const { t } = useTranslation();


  const AsyncPresentation = asyncComponent(Presentation);

  return (
    <>
      <Header menu={1} title={t("header.home1")}/>
      <div style={{ overflow: "hidden" }} id="content" className="content">
        <Banner />
        <Presentation />
      </div>
      <Footer />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logIn: (user) => dispatch({ type: LOGIN_SUCCESS, payload: { user } }),
  };
};

export default connect((state) => null, mapDispatchToProps)(App);
