import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header title={t("about.team_title")} />
      <div id="content" className="content page_cms">
        <div className="container">
          <div className="page_cms_cover team_cover">
            <h1
              className="titleh1"
              dangerouslySetInnerHTML={{ __html: t("about.title") }}
            ></h1>

            {/* <div className="teammembers displayflex">
   					<div className="team_item"><div className="team_itemcover"><img className="lazy" src="assets/images/team1.jpg" alt="Dastudy"/></div></div>
   					<div className="team_item"><div className="team_itemcover"><img className="lazy" src="assets/images/team2.jpg" alt="Dastudy"/></div></div>
   					<div className="team_item"><div className="team_itemcover"><img className="lazy" src="assets/images/team3.jpg" alt="Dastudy"/></div></div>
   					<div className="team_item"><div className="team_itemcover"><img className="lazy" src="assets/images/team1.jpg" alt="Dastudy"/></div></div>
   					<div className="team_item"><div className="team_itemcover"><img className="lazy" src="assets/images/team1.jpg" alt="Dastudy"/></div></div>
   				</div> */}

            <div
              dangerouslySetInnerHTML={{ __html: t("about.team") }}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Team;
