import React from 'react'
import { Route } from 'react-router-dom'
import nprogress from 'nprogress'

import 'nprogress/nprogress.css'


class FancyRoute extends React.Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        nprogress.configure({ easing: 'ease', speed: 1000, showSpinner: false, trickleSpeed: 200 })
        nprogress.start()
        console.log('LOADING START.........')
    }

    componentDidMount() {
        nprogress.done()
        console.log('LOADING STOP..........')
    }

    render() {
        return (
            <Route {...this.props} />
        )
    }

}

export default FancyRoute