import React from "react";
import { connect } from "react-redux";
import { advert } from "../../../services/main.service";

const SidebarAdd = (props) => {
  const [adverts, setAdverts] = React.useState([]);
  let i = 0;
  React.useEffect(() => {
    advert(
      props.category
        ? props.category
        : props.type == "book"
          ? props.book?.pk
          : props.software?.pk
    )
      .then((res) => {
        if (res.status == 200) {
          if (res.data.results && res.data.results.length == 0) {
            advert(props.type == "book" ? props.book?.pk : props.software?.pk)
              .then((res) => {
                if (res.status == 200) {
                  setAdverts(res.data.results);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setAdverts(res.data.results);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.category]);
  return (
    <>
      {/* ------------Sidebar --------------*/}

      <div className="sidebar">
        {adverts?.map((a, key) => {
          if (a.location == 1) {
            i++;
            return (
              i > 0 &&
              i <= 3 && (
                <div id={"banniere" + key} key={key} className="banniere" style={{ marginBottom: "10px" }}>
                  <a href={a.clickable_link ? a.clickable_link : "#"}>
                    <div
                      className="homebloc_cover banniere_content displayflex"
                      style={{
                        background: "#F7F7F7",
                        width: "100%",
                        minHeight: "450px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "30px",
                        color: "#000",
                      }}
                    >
                      <img
                        src={a.images[0]?.image}
                        style={{ height: "100%", objectFit: "cover" }}
                      />
                    </div>
                  </a>
                </div>
              )
            );
          }
        })}
      </div>

      {/* -----x-----Sidebar ------x-------*/}
    </>
  );
};

export default connect((state) => ({
  category: state.advertcategory.category,
  book: state.menus.book,
  software: state.menus.software,
}))(SidebarAdd);
