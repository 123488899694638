import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const UploadedDocItem = ({ item, maxWidth = '18rem'}) => {

  const { t } = useTranslation();
  const coverImageUrl = item.cover_picture ?? "https://s3.us-east-2.amazonaws.com/dastudy.assets/media/doc-imag.avif"
  return (
    <div className="card mx-auto" style={{maxWidth: maxWidth,  height: '100%'}}>
      <img src={coverImageUrl} alt={item.name} className="card-img-top" />
      <div className="card-body">
        <h5 className="card-title">{item.name}</h5>
        <p className="card-text">{item.description}</p>
        <Link target={'_blank'} to={`/analyse/${item.pk}`} className="btn btn-outline-primary btn-block">{t('chatdoc.analyse_btn_title')}</Link>
      </div>
    </div>
  );
};

export default UploadedDocItem;
