import React from "react";
import ParseMessage from "../ParseMessage";

const ForumItem = (props) => {
  const copierDansLePressePapiers = async (texte) => {
    try {
      await navigator.clipboard.writeText(texte);
    } catch (err) {
      console.error("Échec de la copie du texte : ", err);
    }
  };

  return (
    <>
      {props.type === "user" && (
        <div className="lguser displayflex">
          <div className="lguser_pictur">
            <img
              src={props.user?.profile_picture || "/assets/images/avatar.png"}
              alt="Dastudy"
              style={{
                height: "60px",
                width: "60px",
                borderRadius: "100%",
                objectFit: "cover",
                marginRight: "15px",
              }}
            />
          </div>
          <div className="lguser_texte displayflex ml-20">
            <div className="lguser_question">{props.val}</div>
          </div>
        </div>
      )}
      {props.type === "assistant" && (
        <div className="lguser displayflex">
          <div className="lguser_pictur">
            <img
              src={"/assets/images/ialogo.png"}
              alt="dastudy"
              style={{
                height: "60px",
                width: "60px",
                borderRadius: "100%",
                objectFit: "cover",
                marginRight: "15px",
              }}
            />
          </div>
          <div className="lguser_texte displayflex ml-20 d-flex flex-column">
            <div className="lguser_question">
              <ParseMessage message={props.val} />
            </div>
            <div>
              <button onClick={() => copierDansLePressePapiers(props.val)} 
                className='btn btn-outline-primary btn-sm ml-2' style={{height: '30px'}}
                >
                Copier
              </button>
            </div>
            
          </div>
          
        </div>
      )}
    </>
  );
};

export default ForumItem;
