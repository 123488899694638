import React, { useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import "react-pdf/dist/Page/AnnotationLayer.css";

function FlipBook(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(2);
  const [width, setWidth] = useState(290);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  var elmnt = null;
  React.useEffect(() => {
    elmnt = document.getElementById("reader");
    setWidth(elmnt?.offsetWidth);
  }, []);
  return (
    <>
      <HTMLFlipBook
        width={width}
        height={1020}
        showCover={true}
        mobileScrollSupport={true}
      >
          <div className="demoPage">
            <Document file={{ url: props?.file }}>
              <Page
                key={`page_${1}`}
                pageNumber={1}
                height={1020}
                width={width}
              />
            </Document>
          </div>
          <div className="demoPage"></div>
          <div className="demoPage"></div>
      </HTMLFlipBook>
    </>
  );
}

export default FlipBook;
