import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import ForumItem from "../../../components/items/ForumItem";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Pagination";
import {
  allCategories,
  deleteQuestionHistory,
  documents,
  forumPost,
  forumToken,
  randomItems,
} from "../../../services/main.service";
import { connect } from "react-redux";
import BookItem from "../../../components/items/BookItem";
import useWebSocket from "react-use-websocket";
import Slider from "react-slick";
import PdfAudio from "../../../components/PdfAudio";
import swal from "sweetalert";
import DiscussionHistoryItem from "./DiscussionHistoryItem";


const Discussion = (props) => {
  const [discussions, setDicussions] = React.useState([]);
  const [similares, setSimilares] = React.useState([]);
  const [current, setCurrent] = React.useState(1);
  const [isCopy, setIsCopy] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [categories, setCategories] = React.useState([]);

  const { page } = useParams();
  const [currentPage, setCurrentPage] = React.useState(page ? page + "" : "1");
  const { t, i18n } = useTranslation();
  const [pageCount, setPageCount] = React.useState(0);
  const [load, setLoad] = React.useState(true);
  const [load1, setLoad1] = React.useState(false);
  const [categorie, setCateorie] = React.useState("");
  const [responseMessage, setResponseMessage] = React.useState("");
  const [responseMessages, setResponseMessages] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);
  const [refresh1, setRefresh1] = React.useState(false);

  const [userMessages, setUserMessages] = React.useState([]);

  const history = useHistory();
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    "wss://api.dastudy.net/aiws",
    {
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 10,
      //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    }
  );
  const settings = {
    autoplay: true,
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "100px",
        },
      },
    ],
  };
  React.useEffect(() => {
    forumPost(currentPage).then((res) => {
      setLoad(false);
      setPageCount(res.data?.count || 0);
      if (res.status == 200) setDicussions(res.data.results);
    });
  }, [currentPage, refresh1]);

  React.useEffect(() => {
    allCategories(props?.book?.pk).then((res) => {
      if (res.status == 200) setCategories(res.data);
    });
    forumToken().then((res) => {
      sendData(res.data?.token);
    });
    setInterval(() => {
      forumToken().then((res) => {
        sendData(res.data?.token);
      });
    }, 900000);
  }, []);

  React.useEffect(() => {
    if (lastMessage !== null) {
      let val = JSON.parse(lastMessage.data);
      if (
        val.choices &&
        val.choices.length > 0 &&
        val.choices[0].delta &&
        val.choices[0].delta.content
      ) {
        setValue("");
        const msgContent = responseMessage + val.choices[0].delta.content;
        setResponseMessage(msgContent); // <--
        setResponseMessages((prev) => {
          prev[0] = msgContent;
          return prev;
        });
      }
      if (
        val.choices &&
        val.choices.length > 0 &&
        val.choices[0].finish_reason == "stop"
      ) {
        setLoad(false);
        setRefresh1(!refresh1);
        setLoad1(false);
        const msgContent = responseMessage + val.choices[0].delta.content;
        setResponseMessage(msgContent); // <--
        // setResponseMessages((prev) => [msgContent, ...prev]);
      }
      if (val && (val.type == "billinplanerror" || val.type == "dailylimit"))
        paymentModal(val.type);
    }
  }, [lastMessage]);
  const sendData = (val) => {
    sendMessage(val);
  };

  React.useEffect(() => {
    if (categorie) {
      randomItems(categorie)
        .then((res) => {
          setLoad(false);
          if (res.status == 200 && res.data && res.data.length > 0) {
            setSimilares(res.data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      documents(1, categorie)
        .then((res) => {
          setLoad(false);
          if (
            res.status == 200 &&
            res.data &&
            res.data.results &&
            res.data.results.length > 0
          ) {
            setSimilares(res.data.results);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [refresh]);

  const displayResponseMessage = () => {
    return responseMessages.map((r, index) => (
      <div key={index}>
        <ForumItem val={r} type={"assistant"} />
        <ForumItem user={props.user} val={userMessages[index]} type={"user"} />
      </div>
    ));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (load1) return;
    if (!props.user) {
      // history.push("/login");
      loginModal();
      return;
    }

    if (!props.isActiveSubcription && pageCount >= 5) {
      paymentModal("billinplanerror");
      return;
    }

    setLoad(true);
    setLoad1(true);
    let d = JSON.stringify({
      type: "question",
      question: {
        content: value,
        language: i18n.language?.toUpperCase,
        category: categorie,
        mode: "A",
      },
    });
    // setUserMessage(value);
    setUserMessages((prev) => [value, ...prev]);
    setResponseMessages((prev) => ["...", ...prev]);
    setResponseMessage(""); // <--
    // setIsFirstMsgPart(true);
    sendData(d);
    setRefresh(!refresh);
  };

  const resetMessages = () => {
    setUserMessages([]);
    setResponseMessages([]);
  };

  const handleCategoryChange = (e) => {
    setCateorie(e.target.value);
    resetMessages();
  };

  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    if (words.length <= 1000) {
      setCount(words.length);
      setValue(text);
    }
  };

  const paymentModal = (val) => {
    swal(t("common." + val), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/subscription-plan");
    });
  };

  const loginModal = () => {
    swal(t("common.login"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/login");
    });
  };

  const copyToClipBoard = async (value) => {
    try {
      await navigator.clipboard.writeText(value);
      setIsCopy(true);
      setTimeout(() => {
        setIsCopy(false);
      }, 4000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteHistory = (item) => {
    deleteQuestionHistory(item.pk).then(() => {
      setDicussions(discussions.filter((i) => i.pk != item.pk));
    });
  };

  return (
    <div>
      <Header menu={5} title={t("common.forum")} />
      <div id="content" className={"content page_forum  page_documents "}>
        <div className="contentbar displayflex">
          <div className="centerbar">
            <div className="details_bloctitle">
              <h4
                className="font-size-18"
                dangerouslySetInnerHTML={{ __html: t("forum.title") }}
              ></h4>
              <span
                dangerouslySetInnerHTML={{ __html: t("forum.example") }}
              ></span>
            </div>
            <div className="topbannersearch displayflex">
              <form
                id="searchform"
                className="searchform displayflex ds-discussion"
                onSubmit={onSubmit}
              >
                <div className="form-group select_field">
                  <select
                    name="category"
                    className="form-control"
                    onChange={handleCategoryChange}
                    value={categorie}
                    required
                  >
                    <option value="">{t("forum.category")}</option>
                    {categories?.map((cat, i) => (
                      <option value={cat.pk} key={cat.pk}>
                        {cat.name}
                      </option>
                    ))}
                  </select>

                  <i className="bi bi-chevron-down"></i>
                </div>
                <div className="form-group search_field mt-2">
                </div>

                <div
                  className="lguser_comment"
                  style={{ position: "relative", width: "100%" }}
                >
                  <textarea
                    className="textarea form-control"
                    placeholder={t("forum.question")}
                    value={value}
                    onChange={(e) => countWords(e.target.value)}
                    rows={5}
                    required
                  ></textarea>
                  <div className="submitfield">
                    {!load1 && <button type="submit" className="blue_button btn_submit">
                      Envoyer
                    </button>}
                  </div>
                </div>
                <p className="text-right w-100">{count}/1000 mots</p>
              </form>
            </div>
            <div className="postdetailscover forum-content">
              <div className="details_list_users forum_listpost">
                {displayResponseMessage()}
              </div>
            </div>
            <div className="CGU">
              <span
                className="CGU"
                dangerouslySetInnerHTML={{ __html: t("forum.allucine") }}
              ></span>
            </div>
            {responseMessage && !load1 && (
              <div>
                <PdfAudio isRead message={responseMessage} />
                <button
                  className="btn btn-primary float-right"
                  style={{ marginTop: "-30px" }}
                  onClick={() => copyToClipBoard(responseMessage)}
                >
                  {t(isCopy ? "forum.copied" : "forum.copy")}
                </button>
              </div>
            )}
            {props.user && (
              <div>
                <div id="logigiels" className="logiciels slideblo slidepadding">
                  <div className="homebloc_cover">
                    <h2 className="homebloctitle">
                      <span>{t("common.know_more")}</span>
                    </h2>
                    <div className="homebloc_content">
                      <Slider {...settings}>
                        {similares.map((item) => (
                          <BookItem item={item} key={item.id} isCustom={true} />
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
                {discussions.length > 0 && (
                  <>
                    <div
                      className="fa_content mt-4"
                      style={{ maxWidth: "100%" }}
                    >
                      <h2 className="homebloctitle">
                        <span>{t("common.titleForum")}</span>
                      </h2>
                      <div className="fcnt_left_cover">
                        {discussions.map((item, index) => (
                          <DiscussionHistoryItem
                            key={index}
                            item={item}
                            current={current}
                            setCurrent={setCurrent}
                            index={index}
                            categories={categories}
                            onDeleteHistory={handleDeleteHistory}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="pagination">
                      <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount / 5}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <SidebarAdd number={1} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  book: state.menus.book,
  isActiveSubcription: state.auth.isActiveSubcription,
}))(Discussion);
