import React, { Component } from "react";

import Header from "../../../components/header";
import Footer from "../../../components/footer";

class ForumNewTopic extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Header />
        <section class='page-title py-5'>
          <div class='container clearfix'>
            <h2 class='d-inline-block float-lg-left'>
              Créer un nouveau sujet
              <small>
                <a href='forum.html'>
                  <i class='fas fa-home'></i> Acceuil
                </a>{" "}
                > <a href='topic.html'>Nom de la catégorie</a> > Créer nouveau
                sujet
              </small>
            </h2>
            <form action='' class='form-inline float-lg-right'>
              <input
                type='text'
                class='form-control mr-3 mb-2  mb-sm-0'
                placeholder='rechercher sur le forum'
              />
              <button class='btn btn-info'>
                <i class='fas fa-search'></i>
              </button>
            </form>
          </div>
        </section>
        <section class='container pt-4'>
          <form action='' class='mb-3'>
            <div class='form-group'>
              <label for='topic'>Sujet:</label>
              <input
                name='topic'
                id='topic'
                rows='10'
                class='form-control'
                placeholder='Entrez le titre de votre sujet'
                required
              />
            </div>
            <div class='form-group'>
              <label for='comment'>Commentaire:</label>
              <textarea
                name='comment'
                id='comment'
                rows='10'
                class='form-control'
                placeholder='Ecrivez votre réponse ici'
                required
              ></textarea>
            </div>
            <div class='form-check'>
              <label class='form-check-label'>
                <input
                  type='checkbox'
                  class='form-check-input'
                  id='checkbox'
                  value='notification'
                />
                Me notifier en cas de réponse.
              </label>
            </div>
            <button type='submit' class='btn btn-primary'>
              Créer le sujet
            </button>
            <button type='reset' class='btn btn-danger'>
              Effacer
            </button>
          </form>
        </section>
        <Footer />
      </>
    );
  }
}

export default ForumNewTopic;
