import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Payment from "../components/Payment";

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      tags: [],
    };
  }

  render() {
    return (
      <>
        <Header />
        <div class='overlay'></div>
        <main className='container col-md-8 mt-48'>
          <div class='pricing  card-deck flex-column flex-md-row mb-3'>
            <div class='card col-md-4 card-pricing text-center px-3 mb-4'>
              <span class='h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm'>
                Starter
              </span>
              <div class='bg-transparent card-header pt-4 border-0'>
                <h1
                  class='h1 font-weight-normal text-primary text-center mb-0'
                  data-pricing-value='15'
                >
                  $<span class='price'>3</span>
                  <span class='h6 text-muted ml-2'>/ per month</span>
                </h1>
              </div>
              <div class='card-body pt-0'>
                <ul class='list-unstyled mb-4'>
                  <li>Up to 5 users</li>
                  <li>Basic support on Github</li>
                  <li>Monthly updates</li>
                  <li>Free cancelation</li>
                </ul>
                <button type='button' class='btn btn-outline-secondary mb-3'>
                  Renouveler
                </button>
              </div>
            </div>
          </div>
          <div
        className="modal fade"
        id="donation"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h1 className="tileh1">Effectuer le paiement</h1>
              <p>Sélectionnez une méthode paiement</p>
            </div>
            <Payment />
          </div>
        </div>
      </div>
        </main>
        <Footer />
      </>
    );
  }
}

export default Subscriptions;
