import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../redux/actions/types";
import { APP_ROOT_URL } from "../../constants/url";
import { STORED_USER_KEY } from "../../constants/app";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import swal from "sweetalert";
import { connect } from "react-redux";

const Account = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(["token"]);

  const logOut = (e) => {
    e.preventDefault();
    dispatch({
      type: LOGOUT,
      payload: {},
    });
    localStorage.setItem(STORED_USER_KEY, "");
    setCookie("token", "", { path: "/", maxAge: 0 });
    history.push(APP_ROOT_URL);
  };
  const paymentModal = () => {
    if (!props.isActiveSubcription) {
      swal(t("common.gopremium"), {
        icon: "info",
        buttons: {
          confirm: {
            text: "OK",
            closeModal: true,
          },
          cancel: {
            text: "Annuler",
            visible: true,
          },
        },
        dangerMode: false,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) history.push("/subscription-plan");
      });
    } else {
      history.push("/favourites");
    }
  };
  const dev = () => {
    swal("Cette fonctionalité est en cours de dévélopement!", {
      icon: "info",
    });
  };
  return (
    <>
      <Header menu={8} />
      <div id="content" className="content">
        <div id="moncompte" className="moncompte pagecontent">
          <div className="container">
            <div className="espace_account displayflex">
              <h1 className="titleh1">
                <span>{t("profil.welcome")}</span> {t("profil.space")}
              </h1>
              <ul className="">
                <li>
                  <a href="#" title="Se déconnecter" onClick={(e) => logOut(e)}>
                    {t("common.logout")}
                  </a>
                </li>
                {!props.isActiveSubcription && (
                  <li className="pass_premium">
                    <Link to="/subscription-plan">
                      {t("header.gotopremium")} (500 FCFA)
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <div className="moncompte_list row">
              <div className="account_item col-xs-12 col-sm-3 col-md-3">
                <div className="account_item_cover">
                  <Link
                    className="displayflex"
                    to="/personal-information"
                    title={t("profil.info")}>
                    <span className="acconut_icon">
                      <img
                        className="lazy"
                        src="/assets/images/ico_mesinfos.svg"
                        alt="Dastudy"
                      />
                    </span>
                    <span className="acconut_texte">{t("profil.info")}</span>
                  </Link>
                </div>
              </div>
              <div className="account_item col-xs-12 col-sm-3 col-md-3">
                <div className="account_item_cover">
                  <Link
                    className="displayflex"
                    to="/pages"
                    title={t("profil.pages")}>
                    <span className="acconut_icon">
                      <img
                        className="lazy"
                        src="/assets/images/ico_mespages.svg"
                        alt="Dastudy"
                      />
                    </span>
                    <span className="acconut_texte">{t("profil.pages")}</span>
                  </Link>
                </div>
              </div>
              <div className="account_item col-xs-12 col-sm-3 col-md-3">
                <div className="account_item_cover">
                  <Link
                    className="displayflex"
                    to="/uploads"
                    title={t("profil.uploads")}>
                    <span className="acconut_icon">
                      <img
                        className="lazy"
                        src="/assets/images/ico_uploads.svg"
                        alt="Dastudy"
                      />
                    </span>
                    <span className="acconut_texte">{t("profil.uploads")}</span>
                  </Link>
                </div>
              </div>
              <div className="account_item col-xs-12 col-sm-3 col-md-3">
                <div className="account_item_cover">
                  <Link
                    className="displayflex"
                    to="/ads"
                    title={t("profil.pub")}>
                    <span className="acconut_icon">
                      <img
                        className="lazy"
                        src="/assets/images/ico_mespubs.svg"
                        alt="Dastudy"
                      />
                    </span>
                    <span className="acconut_texte">{t("profil.pub")}</span>
                  </Link>
                </div>
              </div>
              <div className="account_item col-xs-12 col-sm-3 col-md-3">
                <div className="account_item_cover">
                  <Link
                    className="displayflex"
                    to="/subscribers"
                    title={t("profil.cell")}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   dev();
                    // }}
                  >
                    <span className="acconut_icon" style={{ color: "#1382bf" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        style={{ width: "60px" }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                        />
                      </svg>

                      {/* <img
                        className="lazy"
                        src="/assets/images/ico_mesventes.svg"
                        alt="Dastudy"
                      /> */}
                    </span>
                    <span className="acconut_texte">{t("profil.cell")}</span>
                  </Link>
                </div>
              </div>
              <div className="account_item col-xs-12 col-sm-3 col-md-3">
                <div className="account_item_cover">
                  <Link
                    className="displayflex"
                    to="/subscriptions"
                    title={t("profil.subcription")}>
                    <span className="acconut_icon">
                      <img
                        className="lazy"
                        src="/assets/images/ico_messubscribes.svg"
                        alt="Dastudy"
                      />
                    </span>
                    <span className="acconut_texte">
                      {t("profil.subcription")}
                    </span>
                  </Link>
                </div>
              </div>
              {/* <div className="account_item col-xs-12 col-sm-3 col-md-3">
                <div className="account_item_cover">
                  <Link
                    className="displayflex"
                    to="#"
                    title={t("profil.bill")}
                    onClick={(e) => {
                      e.preventDefault();
                      dev();
                    }}>
                    <span className="acconut_icon">
                      <img
                        className="lazy"
                        src="/assets/images/ico_mesfactures.svg"
                        alt="Dastudy"
                      />
                    </span>
                    <span className="acconut_texte">{t("profil.bill")}</span>
                  </Link>
                </div>
              </div> */}
              <div className="account_item col-xs-12 col-sm-3 col-md-3">
                <div className="account_item_cover">
                  <Link
                    className="displayflex"
                    to="#"
                    title={t("profil.favoris")}
                    onClick={() => paymentModal()}>
                    <span className="acconut_icon">
                      <img
                        className="lazy"
                        src="/assets/images/ico_mesfavoris.svg"
                        alt="Dastudy"
                      />
                    </span>
                    <span className="acconut_texte">{t("profil.favoris")}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  isActiveSubcription: state.auth.isActiveSubcription,
}))(Account);
