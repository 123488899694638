import React from "react";
import ParseMessage from "../../../components/ParseMessage";
import Moment from "react-moment";
import swal from "sweetalert";
import { CircularProgress } from "@material-ui/core";

const DiscussionHistoryItem = ({
  item,
  categories,
  index,
  current,
  setCurrent,
  onDeleteHistory,
}) => {
  const [deleting, setDeleting] = React.useState(false);

  React.useEffect(() => {
    setDeleting(false);
  }, [item]);

  const handleDeleteHistory = () => {
    swal("Voulez-vous vraiment supprimer cet historique ?", {
      icon: "warning",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: true,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) {
        console.log("Suppp!!");
        setDeleting(true);
        onDeleteHistory(item);
      }
    });
  };

  return (
    <div
      key={item?.pk}
      className={current == index + 1 ? "panel current" : "panel"}>
      <div
        className={current == index + 1 ? "paneltitle active" : "paneltitle"}
        onClick={() => setCurrent(index + 1)}
        dangerouslySetInnerHTML={{
          __html: item?.content,
        }}></div>
      <div
        className="panelcontent"
        style={{
          display: current == index + 1 && "block",
          opacity: "0.9",
        }}>
        {(item.responses[0]?.response?.text ||
          item.responses[0]?.response?.choices?.length > 0) && (
          <ParseMessage
            message={
              item.responses[0]?.response?.text ||
              item.responses[0]?.response?.choices[0]?.message?.content
            }
          />
        )}
        <div className="d-flex">
          <p className="text-primary w-50">
            {categories?.find((val) => val.pk == item.category)?.name}
          </p>
          {/* text-right */}
          <p className=" w-50">
            <Moment format="DD/MM/YYYY">{item.created}</Moment>
          </p>

          {deleting && <CircularProgress />}

          {!deleting && (
            <div
              onClick={handleDeleteHistory}
              style={{
                color: "red",
                opacity: 0.8,
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                cursor: "pointer",
                marginBottom: "1rem",
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{ width: "20px", marginLeft: "1rem" }}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
              Delete
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscussionHistoryItem;
