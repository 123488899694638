import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getBookmark, saveBookmark, search } from "../services/main.service";

const Search = (props) => {
  const [load, setLoad] = React.useState(false);
  const [value, setValue] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  
  const search = (e) => {
    history.push("/search?q=" + value+"&type="+props.type);
  };

  return (
      <form
        id="searchform"
        className="searchform displayflex"
        onSubmit={search}
      >
        <div className="form-group search_field">
          <input
            type="text"
            className="form-control"
            placeholder={t("common.search")}
            onChange={(e) => setValue(e.target.value)}
            required
          />

          <button type="submit">
            {load && (
              <span className="spinner-border spinner-border-sm ml-2"></span>
            )}
          </button>
        </div>
      </form>
  );
};

export default Search;
