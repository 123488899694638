import React, { useEffect, useState } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/a11y-dark.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

export default function ParseMessage(props) {
 
  return (
    <ReactMarkdown
      children={props.message}
      components={{
        pre({ node, ...props }) {
          return <pre {...props}  className="hljs" />;
        },
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "scss");
          const lang = match? match[1]:"scss";
          return !inline && lang? (
              <code
                {...props}
                className={`${className} language-${lang}`}
                dangerouslySetInnerHTML={{
                  __html: hljs.highlight(String(children), {
                    language: lang,
                    ignoreIllegals: true,
                  }).value,
                }}
              ></code>
             
          ) : (
              <code
                className={
                  className
                }
                {...props}
              >
                {children}
              </code>
          );
        },
      }}
      remarkPlugins={[remarkGfm]}
    />
  );
}
