import React from 'react'
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useQuery } from "../services/main.service";
const Pagination = (props) => {
    let history = useHistory();
    let location = useLocation();
    const { t } = useTranslation();
    const query = new URLSearchParams(location.search);

    const pagginationHandler = (page) => {
        const currentPath = location.pathname;
        props.setCurrentPage(page.selected + 1)
        query.set("page", page.selected + 1)

        history.push({
          pathname: currentPath,
          search: query.toString(),
        });
      };
    return <>
        {/* -------- Pagination---------------*/}
        <ReactPaginate
            previousLabel="<" 
            nextLabel=">"
            breakLabel={"..."}
            breakClassName={"break-me"}
            activeClassName={"active"}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            initialPage={parseInt(props?.currentPage,10) - 1}
            pageCount={props.pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={pagginationHandler}
          />
        {/* ---x---- Pagination-----x---------*/}

    </>
}

export default Pagination