import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert'

import { verifyRegistration, login } from '../../redux/actions/auth'
import { AuthLayoutHigherOrderComponent } from '../../components'
import { log } from '../../helpers'
import { LOGIN_URL } from '../../constants/url'


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



const ConfirmAccount = () => {

    const classes = useStyles()
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (location && location.search) {
            const data = queryString.parse(location.search)
            // log('PPP', data)

            dispatch(verifyRegistration({ user_id: data.user_id, timestamp: data.timestamp, signature: data.signature }))
                .then(response => {
                    log('Page response', response)

                    if (response && response.status === 200) {

                        setTimeout(() => {
                            log('REDIRECTION', 'redirect')
                            history.push(LOGIN_URL)
                        }, 2000)

                    } else {
                        alert(response && response.error ? response.error : 'Impossible de joindre le serveur')
                    }

                })
                .catch(error => {

                })
        }
    })

    return <>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div style={{ paddingTop: '10vh' }} className={classes.paper}>

                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">CONFIRMATION DE COMPTE</Typography>

                <br /><br />

                <div className="card">
                    <div className="card-body">
                        <div className="alert alert-info" role="info">
                            Veuillez patienter SVP. <br />
                            <div style={{ marginTop: '16px' }} className="progress" >
                                <br />
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }} ></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Box style={{ textAlign: 'center', }} mt={5}>
                <span style={{ color: '#F50057', marginLeft: '16px' }}>DASTUDY, Explorer votre passion</span>
            </Box>
        </Container>

    </>
}

export default AuthLayoutHigherOrderComponent(ConfirmAccount)