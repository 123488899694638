import React from 'react'
import { Link } from 'react-router-dom'

import './index.css'

import { APP_ROOT_URL } from '../../constants/url'



function AuthLayoutHigherOrderComponent(WrappedComponent) {
    class AuthLayoutClass extends React.Component {



        render() {
            return <>
                <div className="auth-overlay"></div>
                <div className="auth-card">
                    <div className="logo-card">
                        <Link to={APP_ROOT_URL}>
                            <img src={require('../../assets/img/logok.png')} alt="" />
                        </Link>
                    </div>

                    <div className="auth-card-inner">
                        <WrappedComponent />
                    </div>
                </div>
            </>
        }
    }

    return AuthLayoutClass
}

export default AuthLayoutHigherOrderComponent