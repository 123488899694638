import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import { purchased } from "../../../services/main.service";
import Moment from "react-moment";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";
import PdfReader from "../../../components/PdfReader";
const MyBill = () => {
  const [data, setData] = React.useState([]);
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = React.useState();
  React.useEffect(() => {
    purchased().then((res) => {
      console.log(res);
      if (res.status == 200) setData(res.data);
    });
  }, []);
  return (
    <>
      <Header menu={8} />
      <div id="content" className="content">
        <div className="account_page pagecontent">
          <div className="container">
            <div className="account_page_cover displayflex">
              <div className="mes_favoris_left">
                <div className="accounttop displayflex">
                  <h1 className="titleh1 text-black">{t("profil.bill")}</h1>
                  <div className="nbresults_infos resultats">
                    {t("common.result")}{" "}
                    <span>
                      {data.length}-{data.length}
                    </span>{" "}
                    {t("common.to")}
                    <span>{data.length}</span>
                  </div>
                </div>
                <div className="list_mes_factures list_mes_uploads">
                  {data.map((item, key) => (
                    <div className="result_item">
                      <div className="favoris_listperdate">
                        <div className="result_content displayflex">
                          <div className="result_content_left displayflex">
                            <div className="result_img">
                              <img
                                className="lazy"
                                src={item?.cover_picture}
                                alt="Dastudy"
                              />
                            </div>
                            <div className="result_texte displayflex">
                              <div className="result_texte_top">
                                {/* <div className="result_achat">
                                Achat du livre/logiciel/tutoriel
                              </div> */}
                                <div className="result_name">{item?.name}</div>
                                <div className="result_from">
                                  {item?.author}
                                </div>
                              </div>
                              <div className="result_texte_bottom">
                                <div className="uploadresult_date">
                                  <Moment locale={i18n.language} fromNow>
                                    {item?.pub_date}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="result_content_right displayflex">
                            <div className="result_telecharger">
                              <a href={item?.full_content}
                                title="Télécharger"
                              >
                                {t("common.download")}
                              </a>
                            </div>
                            {item._type == "DOCUMENT" && (
                              <div className="result_link">
                                <a 
                                  href="#"
                                  title="Voir"
                                  data-toggle="modal"
                                  data-target="#preview"
                                  onClick={() => setCurrent(item)}
                                >
                                  {t("common.see")}
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <SidebarAdd number={1} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div
        className="modal fade"
        id="preview"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h1 className="tileh1">Prévisualisation</h1>
            </div>
            <div className="modal-body">
              <div className="preview_cover">
                <div className="preview_content">
                  {current && <PdfReader file={current.full_content} />}
                </div>
                <div className="preview_button displayflex">
                  <a
                    className="blue_button btn"
                    href={current?.full_content}
                  >
                    Télécharger
                  </a>
                  {/* <div className="file_size">5Mo</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyBill;
