import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import React from "react";

const SubscribersItem = ({ subscriber }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        // image="/assets/images/avatar.png"
        // title="subscriber"
      />
      <CardContent>
        <span className="abonne_picture">
          <img
            className="lazy"
            src={"/assets/images/avatar.png"}
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
            alt="Dastudy"
          />
        </span>

        <Typography variant="h5" component="div">
          {subscriber.first_name} {subscriber.last_name}
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species
        </Typography> */}
      </CardContent>
      {/* <CardActions>
                          <Button size="small">Voir Profile</Button>
                        </CardActions> */}
    </Card>
  );
};

export default SubscribersItem;
