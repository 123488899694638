import React from "react";
import $ from "jquery";
import Speech from "react-speech";
import { getPdf } from "../services/main.service";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

const PdfAudio = (props) => {
  const [pdfPage, setPdfPage] = React.useState(0);
  const [pdfText, setPdfText] = React.useState(props.message);
  const [load, setLoad] = React.useState(true);
  const [play, setPlay] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const getPageText = (type = "") => {
    setLoad(false);
    let page =
      type == "next" ? pdfPage + 1 : pdfPage > 1 ? pdfPage - 1 : pdfPage;
    setPdfPage(page);
    getPdf({ page: page, resource: props.item?.id }).then((res) => {
      setLoad(true);
      if (res.status == 200) setPdfText(res.data.text);
    });
  };
  const paymentModal = () => {
    swal(t("common.gopremium"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/subscription-plan");
    });
  };
  const loginModal = () => {
    swal(t("common.login"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/login");
    });
  };
  const handlePlay = (e) => {
    e.preventDefault();
    if (props.user) {
      if (props.isActiveSubcription || props.isRead) {
        setPlay(true);
        $(".rs-play").click();
      } else {
        paymentModal();
      }
    } else {
      loginModal();
    }
  };
  React.useEffect(() => {
    getPageText();
  }, [props.item, pdfPage]);
  return (
    <>
      {props.isRead ? (
        <button
          className="btn btn-primary"
          style={{ marginTop: "-50px" }}
          onClick={(e) => {
           handlePlay(e);
          }}
        >
          {t("common.heart")}
         <i className="bi bi-play-circle-fill ml-2"></i>
        </button>
      ) : (
        <div
          className="displayflex pdfaudio"
          style={props?.isRead ? { background: "#0069d9" } : {}}
        >
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={() => getPageText("preview")}
          ></i>

          {!play && (
            <i
              className="bi bi-play-circle-fill"
              onClick={(e) => handlePlay(e)}
            ></i>
          )}
          {play && (
            <i
              className="bi bi-pause-circle-fill"
              onClick={() => {
                setPlay(false);
                $(".rs-pause").click();
              }}
            ></i>
          )}
          <i
            className="bi bi-arrow-right-circle-fill"
            onClick={() => getPageText("next")}
          ></i>
        </div>
      )}
      {load && (
        <Speech
          pause
          lang="fr-FR"
          voice="Google français"
          style={{ height: "0px" }}
          text={pdfText}
        />
      )}
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  isActiveSubcription: state.auth.isActiveSubcription,
}))(PdfAudio);
