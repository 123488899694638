import React, { Component } from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";

import TutorialContent from "../../../components/app/TutorialContent";
import TutorialItem from "../../../components/items/TutorialItem";
import SidebarFilter from "../../../components/SidebarFilter";
import { tutotiels } from "../../../services/main.service";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const $ = window.jQuery;

const Builder = (props) => {
	const [data, setData] = React.useState([]);
	const { t } = useTranslation();
	React.useEffect(() => {

		tutotiels().then((res) => {
			if (res.status == 200) setData(res.data.results);
			$('.slidetuto').slick({
				autoplay: true,
				dots: false,
				arrows: true,
				infinite: true,
				speed: 300,
				slidesToShow: 3,
				slidesToScroll: 3,
				centerMode: true,
				centerPadding: '270px',
				responsive: [
					{
						breakpoint: 1499,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3,
							centerPadding: '120px'
						}
					}, {
						breakpoint: 1399,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3,
							centerPadding: '80px'
						}
					}, {
						breakpoint: 1199,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							centerPadding: '120px'
						}
					}, {
						breakpoint: 820,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							centerPadding: '60px'
						}
					}, {
						breakpoint: 767,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							centerPadding: '50px'
						}
					}, {
						breakpoint: 680,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							centerPadding: '120px'
						}
					}
				]
			});
		  });
	}, [])

	return (
		<>
			<Header menu={3} title={t("common.tutorials")}  />
			<div id="content" className="content page_categorie page_tutoriels">
				<div className="contentbar displayflex">
					<SidebarFilter path="tutorials"  />
					<TutorialContent />
				</div>
				<div id="tutoriels" className="tutoriels homebloc slidebloc slidepadding">
					<div className="container">
						<div className="homebloc_cover">
							<h2 className="homebloctitle">
								<span>{props?.user ? t("common.recent_read"):t("common.must_read")}</span>
							</h2>
							<div className="homebloc_content">
								<div className="slidetuto">

									{data.map((item, i) => (
										<TutorialItem item={item} />
									))}
								</div>
							</div>
						</div>
					</div>
				</div> </div>

			<Footer />
		</>
	);
}

export default connect((state) => ({
	user: state.auth.user,
  }))(Builder);