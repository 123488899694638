import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import { sales, solde } from "../../../services/main.service";
import Moment from 'react-moment';
import 'moment/locale/fr';
import { useTranslation } from "react-i18next";
const MyCell = () => {
  const [data, setData] = React.useState([]);
  const [sale, setSale] = React.useState();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    sales().then((res) => {
      console.log(res);
      if (res.status == 200) setSale(res.data);
    });

    solde().then((res) => {
      console.log("Solde", res);
      if (res.status == 200) setData(res.data);
    });
  }, []);
  return (
    <>
      <Header menu={8} />
      <div id="content" className="content">
        <div className="account_page pagecontent mes_ventes">
          <div className="container">
            <div className="account_page_cover displayflex">
              <div className="mes_favoris_left">
                <div className="mes_ventes_top displayflex">
                  <div className="mes_ventes_left">
                    <h1 className="titleh1 text-black">{t("profil.cell")}</h1>
                    <div className="decaissement">
                      <div className="bloccaisse">
                        <ul>
                          <li className="displayflex">
                             {t("cell.revenu")}
                            <span>{sale?.revenue} fcfa</span>
                          </li>
                          <li className="displayflex">
                            {t("cell.comission")}
                            <span>
                              {sale?.commission ? sale?.commission : 0}%
                            </span>
                          </li>
                          <li className="displayflex">
                            {t("cell.sold")}
                            <span>{sale?.current_worth} fcfa</span>
                          </li>
                        </ul>
                      </div>
                      <div className="decaisse_link">
                        <button
                          className="btn blue_button"
                          type="submit"
                          data-toggle="modal"
                          data-target="#decaisser"
                        >
                           {t("cell.get")}
                        </button>
                      </div>
                    </div>
                    <div className="filterbloc displayflex">
                      <nav className="nav nav-pills">
                        <a
                          className="nav-item nav-link active"
                          href="#documents"
                          data-toggle="tab"
                        >
                          {t("common.documents")}
                        </a>
                        <a
                          className="nav-item nav-link"
                          href="#tutoriels"
                          data-toggle="tab"
                        >
                          {t("common.tutorials")}
                        </a>
                        <a
                          className="nav-item nav-link"
                          href="#logiciels"
                          data-toggle="tab"
                        >
                          {t("common.softwares")}
                        </a>
                      </nav>
                      <div className="resultats">
                      {t("common.result")}{" "}
                        <strong>
                          {data.length}-{data.length}
                        </strong>{" "}
                        {t("common.to")} <strong>{data.length}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-content">
                  <div className="tab-pane active" id="documents">
                    <div className="list_mes_factures list_mes_uploads">
                      {data.map((item, key) => item._type=="DOCUMENT" && (
                        <div className="result_item">
                          <div className="favoris_listperdate">
                            <div className="result_content displayflex">
                              <div className="result_content_left displayflex">
                                <div className="result_img">
                                  <img
                                    className="lazy"
                                    src={item?.cover_picture}
                                    alt="Dastudy"
                                  />
                                </div>
                                <div className="result_texte displayflex">
                                  <div className="result_texte_top">
                                    <div className="result_achat">
                                      Achat du document
                                    </div>
                                    <div className="result_name">
                                    {item.name}
                                    </div>
                                    <div className="result_from">
                                      {item.price} Fcfa
                                    </div>
                                  </div>
                                  <div className="result_texte_bottom">
                                    <div className="uploadresult_date">
                                    <Moment locale={i18n.language} fromNow>{item?.pub_date}</Moment>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="tab-pane" id="tutoriels">
                  <div className="list_mes_factures list_mes_uploads">
                      {data.map((item, key) => item._type=="VIDEO" && (
                        <div className="result_item">
                          <div className="favoris_listperdate">
                            <div className="result_content displayflex">
                              <div className="result_content_left displayflex">
                                <div className="result_img">
                                  <img
                                    className="lazy"
                                    src={item?.resource?.cover_picture}
                                    alt="Dastudy"
                                  />
                                </div>
                                <div className="result_texte displayflex">
                                  <div className="result_texte_top">
                                    <div className="result_achat">
                                      Achat du tutoriel
                                    </div>
                                    <div className="result_name">
                                    {item.name}
                                    </div>
                                    <div className="result_from">
                                      {item.price} Fcfa
                                    </div>
                                  </div>
                                  <div className="result_texte_bottom">
                                    <div className="uploadresult_date">
                                    <Moment locale={i18n.language} fromNow>{item?.pub_date}</Moment>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                 
                  </div>
                  <div className="tab-pane" id="logiciels">
                  <div className="list_mes_factures list_mes_uploads">
                      {data.map((item, key) => item._type=="SOFTWARE" && (
                        <div className="result_item">
                          <div className="favoris_listperdate">
                            <div className="result_content displayflex">
                              <div className="result_content_left displayflex">
                                <div className="result_img">
                                  <img
                                    className="lazy"
                                    src={item?.resource?.cover_picture}
                                    alt="Dastudy"
                                  />
                                </div>
                                <div className="result_texte displayflex">
                                  <div className="result_texte_top">
                                    <div className="result_achat">
                                      Achat du logiciel
                                    </div>
                                    <div className="result_name">
                                    {item.name}
                                    </div>
                                    <div className="result_from">
                                      {item.price} Fcfa
                                    </div>
                                  </div>
                                  <div className="result_texte_bottom">
                                    <div className="uploadresult_date">
                                    <Moment locale={i18n.language} fromNow>{item?.pub_date}</Moment>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                 
                  </div>
                </div>
              </div>
              <SidebarAdd number={1} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default MyCell;
