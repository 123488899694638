import React, { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";
import { search } from "../../services/main.service";
import { connect } from "react-redux";
import { useQuery } from "../../services/main.service";
import BookItem from "../../components/items/BookItem";
import SofwareListItem from "../../components/items/SofwareListItem";
import PageItem from "../../components/items/PageItem";

const SearchResult = (props) => {
  const [data, setData] = React.useState([]);
  const query = useQuery();
  const { page } = useParams();
  const [currentPage, setCurrentPage] = React.useState(page ? page + "" : "1");
  const [value, setValue] = React.useState(query.get("q"));
  const { t } = useTranslation();
  const [pageCount, setPageCount] = React.useState(0);
  const [type, setType] = React.useState(
    query.get("type") ? query.get("type") : "DOCUMENT"
  );
  const [load, setLoad] = React.useState(true);
  const [refresh, setRefresh] = React.useState(true);

  const [countDocuments, setCountDocuments] = useState(0);
  const [countSoftwares, setCountCountSoftwares] = useState(0);
  const [countPages, setCountPages] = useState(0);
  React.useEffect(() => {
    setLoad(true);
    search(value).then((res) => {
      setLoad(false);
      if (res.status == 200) {
        const docs = res.data.results?.filter(
          (item) => item._type === "DOCUMENT"
        );
        const softs = res.data.results?.filter(
          (item) => item._type === "SOFTWARE"
        );
        const pages = res.data.pages;

        setCountDocuments(docs?.length||0);
        setCountCountSoftwares(softs?.length || 0);
        setCountPages(pages?.length || 0);
        setData(
          type == "SOFTWARE"
            ? softs
            : type == "DOCUMENT"
            ? docs
            : type == "PAGE" && pages
        );
        if (type == "SOFTWARE") setPageCount((softs ? softs.length : 0) / 12);
        else setPageCount((docs ? docs.length : 0) / 12);
      }
    });
  }, [refresh, query.get("type"), query.get("q")]);
  
  const change = (type) => {
    setType(type);
    query.set("type", type);
    query.set("page", 1);
    props.history.replace({
      search: query.toString(),
    });
  };

  return (
    <>
      <Header menu={10} />
      <div
        id="content"
        className="content page_forum_response page_forum page_forumsearch"
      >
        <div className="mt-50">
          <div className="centerbar">
            <div className="topbannersearch displayflex ">
              <form
                id="searchform"
                className="searchform displayflex"
                onSubmit={(e) => {
                  e.preventDefault();
                  setRefresh(!refresh);
                }}
              >
                <div
                  className="form-group search_field"
                  style={{ marginTop: "70px" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Recherchez sur Dastudy"
                    defaultValue={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <button type="submit">
                    {load && (
                      <span className="spinner-border spinner-border-sm ml-2"></span>
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div className="details_bloctitle">
              <span dangerouslySetInnerHTML={{ __html: t("forum.discuss") }}
              ></span>
              <br/><br/>
            </div>
            <div className="filterbloc accounttop displayflex">
              <div className="details_bloctitle">
                <h1 className="titleh1">Résultats</h1>
              </div>
              <div className="nbresults_infos resultats">
                résultat{" "}
                <span>
                  {data?.length}-{data?.length}
                </span>{" "}
                sur un total de <span>{data?.length}</span>
              </div>
            </div>
            <div className="postdetailscover">
              <div className="filterbloc displayflex">
                <nav className="nav nav-pills">
                  <a
                    className={`nav-item nav-link ${
                      type == "DOCUMENT" && " active"
                    }`}
                    href="#documents"
                    data-toggle="tab"
                    onClick={() => {
                      change("DOCUMENT");
                    }}
                  >
                    {t("common.documents")} {`(${countDocuments})`}
                  </a>

                  <a
                    className={`nav-item nav-link ${
                      type == "SOFTWARE" && " active"
                    }`}
                    href="#logiciels"
                    data-toggle="tab"
                    onClick={() => {
                      change("SOFTWARE");
                    }}
                  >
                    {t("common.softwares")} {`(${countSoftwares})`}
                  </a>
                  <a
                    className={`nav-item nav-link ${
                      type == "PAGE" && " active"
                    }`}
                    href="#pages"
                    data-toggle="tab"
                    onClick={() => {
                      change("PAGE");
                    }}
                  >
                    Pages {`(${countPages})`}
                  </a>
                </nav>
              </div>
              <div
                className={
                  type == "DOCUMENT"
                    ? "details_list_users forum_listpost row"
                    : "blc_category logiciels documents listebook row"
                }
              >
                
                {data?.length > 0 &&
                  data
                    .slice(
                      (parseInt(currentPage) - 1) * 12,
                      (parseInt(currentPage) - 1) * 12 + 12
                    )
                    .map((item, i) => (
                      <>
                        {type == "DOCUMENT" ? (
                          <BookItem
                            item={item}
                            cat={localStorage.getItem("livre")}
                          />
                        ) : type == "SOFTWARE" ? (
                          <SofwareListItem
                            item={item}
                            cat={localStorage.getItem("software")}
                            marginTop={20}
                          />
                        ) : (
                          <PageItem item={item} marginTop={20} />
                        )}
                      </>
                    ))}
              </div>
              <div className="pagination">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(SearchResult);
