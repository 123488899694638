import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DOWNLOAD_ENDPOINT } from "../api/pythonBackend";
import swal from "sweetalert";

const Download = (props) => {
  const [load, setLoad] = React.useState(false);
  const { t } = useTranslation();
  let history = useHistory();
  
  const loginModal=()=>{
    swal(t("common.login"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete)=>{
        if(willDelete) history.push("/login")
    })
  }
  const handleClick = (e) => {
    e.preventDefault();
    if (props?.user) {

      const link = `${DOWNLOAD_ENDPOINT}download/${
        props?.version?.id||props?.item?.id
      }?type=${props.type}&token=Bearer ${localStorage.getItem("token")}`;
      window.open(link, '_blank');
    } else {
      loginModal()
    }
  };
  return (
    <a
      className="btn_link blue_button btn"
      //   href={props?.item?.full_content}
      href="#"
      onClick={(e) => handleClick(e)}
    >
      {t("common.download")}{" "}
      {load && <span className="spinner-border spinner-border-sm ml-2"></span>}
    </a>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(Download);
