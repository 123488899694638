import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import ForumItem from "../../../components/items/ForumItem";
import {
  forumPostDetail,
  forumSimilar,
  useQuery,
} from "../../../services/main.service";
import { connect } from "react-redux";
import ForumComment from "../../../components/ForumComment";

const ForumTopic = (props) => {
  const [data, setData] = React.useState([]);
  const [item, setItem] = React.useState(null);
  const [load, setLoad] = React.useState(true);

  const query = useQuery();
  React.useEffect(() => {
    forumPostDetail(query?.get("id")).then((res) => {
      setLoad(false);
      if (res.status == 200) setItem(res.data);
    });
    forumSimilar(query?.get("id")).then((res) => {
      console.log("similar", res);
      setLoad(false);
      if (res.status == 200) setData(res.data);
    });
  }, [query?.get("id")]);

  return (
    <>
      <Header menu={5} title={item?.title} />
      <div id="content" className="content page_forum page_forum_response ">
        <div className="contentbar displayflex">
          <div className="centerbar">
            <div className="postdetailscover">
              <div className="lguser_response_details details_list_users lguser displayflex">
                <div className="lguser_fction">{item?.category?.name}</div>
                <div className="lguser_question">{item?.title}</div>
                <div className="lguser_excert">{item?.message}</div>
                <div className="lguser_response displayflex">
                  <div className="lguser_picture">
                    <img
                      src={
                        item?.author?.profile_picture ||
                        "/assets/images/avatar.png"
                      }
                      alt="Dastudy"
                    />
                  </div>
                  <div className="lguser_texte displayflex">
                    <div className="lguser_name displayflex">
                      {item?.author?.first_name
                        ? item?.author?.first_name
                        : item?.author?.email}
                      <div className="lguser_date">
                        {new Date(item?.created).toLocaleString()}
                      </div>
                    </div>
                    <div className="cat_infos displayflex">
                      Catégorie - <span> {item?.category_obj?.name}</span>
                    </div>
                  </div>
                </div>
              </div>
              {item && <ForumComment data={data} id={query?.get("id")} />}

              

              <div className="bloc_sujets_similaires forumbloc">
                <div className="titledubloc displayflex">
                  <h2>Sujets similaires</h2>
                  <span>Top 10</span>
                </div>
                <div className="details_list_users forum_listpost">
                  {data?.map((item, i) => (
                    <ForumItem item={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(ForumTopic);
