import React, { Component } from "react";

import Header from "../../../components/header";
import Footer from "../../../components/footer";
import ForumPost from "../../../components/ForumPost";

class ForumPostThread extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Header />
        <section class='page-title py-5'>
          <div class='container clearfix'>
            <h2 class='d-inline-block float-lg-left'>
              Nom de la Catégorie
              <small>
                <a href='forum.html'>
                  <i class='fas fa-home'></i> Acceuil
                </a>{" "}
                > <a href='topic.html'>Nom de la catégorie</a> > Titre du sujet
                qui peut etre plus ou moins long
              </small>
            </h2>
            <form action='' class='form-inline float-lg-right'>
              <input
                type='text'
                class='form-control mr-3 mb-2  mb-sm-0'
                placeholder='rechercher sur le forum'
              />
              <button class='btn btn-info'>
                <i class='fas fa-search'></i>
              </button>
            </form>
          </div>
        </section>
        <section class='container pt-4'>
          <h2 class='h4 category mb-0 p-4 rounded-top text-light'>
            Titre du sujet qui peut etre plus ou moins long
          </h2>
          <table class='table table-striped table-bordered table-responsive-lg'>
            <thead class='thead-light'>
              <tr>
                <th scope='col'>Auteur</th>
                <th scope='col'>Message</th>
              </tr>
            </thead>
            <tbody>
              <ForumPost />
            </tbody>
          </table>
          <div class='mb-3 clearfix'>
            <nav aria-label='Navigation post pages' class='float-lg-right'>
              <ul class='pagination pagination-sm mb-lg-0'>
                <li class='page-item active'>
                  <a href='#' class='page-link'>
                    1 <span class='sr-only'>(current)</span>
                  </a>
                </li>
                <li class='page-item'>
                  <a href='#' class='page-link'>
                    2
                  </a>
                </li>
                <li class='page-item'>
                  <a href='#' class='page-link'>
                    3
                  </a>
                </li>
                <li class='page-item'>
                  <a href='#' class='page-link'>
                    4
                  </a>
                </li>
                <li class='page-item'>
                  <a href='#' class='page-link'>
                    5
                  </a>
                </li>
                <li class='page-item'>
                  <a href='#' class='page-link'>
                    &hellip; 20
                  </a>
                </li>
                <li class='page-item'>
                  <a href='#' class='page-link'>
                    Suivant
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <form action='' class='mb-3'>
            <div class='form-group'>
              <label for='comment'>Répondre à ce post:</label>
              <textarea
                name='comment'
                id='comment'
                rows='10'
                class='form-control'
                placeholder='Ecrivez votre réponse ici'
                required
              ></textarea>
            </div>
            <button type='submit' class='btn btn-primary'>
              <i class='fas fa-share'></i> Répondre
            </button>
            <button type='reset' class='btn btn-danger'>
              Effacer
            </button>
          </form>
        </section>

        <Footer />
      </>
    );
  }
}

export default ForumPostThread;
