import React from 'react'

const asyncComponent = (importComponent) => {

    return class extends React.Component {
        state = {
            component: null
        }

        componentDidMount() {
            importComponent().then(cmp => this.setState({ component: cmp.default }))
        }

        render() {
            const LoadedComponent = this.state.component
            return LoadedComponent ? <LoadedComponent {...this.props} /> : null
        }
    }

}

export default asyncComponent