import React from "react";
import Ad from "../../ad";
import BookItem from "../../items/BookItem";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "../../Pagination";
import {
  documents,
  documentDetail,
  getReviews,
} from "../../../services/main.service";
// import PdfReader from "../../PdfReader";
import { connect } from "react-redux";
import Comments from "../../Comments";
import Skeleton from "react-loading-skeleton";
import AddBookmark from "../../AddBookmark";
import UserFollow from "../../UserFollow";
import Search from "../../Search";
import ReactAudioPlayer from "react-audio-player";
import Like from "../../Like";
import Buy from "../../Buy";
import Download from "../../Download";
import FlipBook from "../../FlipBook";
import ScrollReader from "../../scrollReader/ScrollReader";
import PdfAudio from "../../PdfAudio";
import GetAppIcon from "@material-ui/icons/GetApp";

import swal from "sweetalert";
import { stringToSlug } from "../../../helpers/functions";

const BookContent = (props) => {
  const [data, setData] = React.useState([]);
  const [reviews, setReviews] = React.useState([]);
  const { page, type, slug, category, id } = useParams();
  const [currentPage, setCurrentPage] = React.useState(page ? page + "" : "1");
  const { t } = useTranslation();
  const [pageCount, setPageCount] = React.useState(0);
  const [load, setLoad] = React.useState(true);
  const [item, setItem] = React.useState(null);
  const [isFeuillete, setIsFeuillete] = React.useState(false);
  let isDetailsPage = false;
  const cat = props.menus?.find((item) => stringToSlug(item.name) == slug);
  const history = useHistory();
  const [refresh, setRefresh] = React.useState(false);
  const [nature, setNature] = React.useState(0);
  const [countBook, setCountBook] = React.useState(0);
  const [countExercice, setCountExercice] = React.useState(0);
  React.useEffect(() => {
    if (props?.type === "detail") {
      setRefresh(false);
      documentDetail(id).then((res) => {
        if (res.status === 200) {
          setItem(res.data);
          setRefresh(true);
        }

        // setIsDetailsPage(true);
        // isDetailsPage = true;
      });

      getReviews(id).then((res) => {
        if (res.status === 200) setReviews(res.data);
        setLoad(false);
      });
    } else {
      documents(currentPage, cat?.pk != "null" ? cat?.pk : "").then((res) => {
        setPageCount(res.data.count / 10);
        if (res.status === 200) {
          const datas = res.data.results;
          const len = datas.filter((item) => item.nature == 0).length;
          setCountBook(len);
          setCountExercice(datas.filter((item) => item.nature == 1).length);
          if (len > 0) setNature(0);
          else setNature(1);
          setData(datas);
        }
        setLoad(false);
      });
    }
  }, [currentPage, id, cat]);
  const onPlay = () => {
    if (props.user) {
      if (!props.isActiveSubcription) paymentModal();
    } else {
      loginModal();
    }
  };
  // console.log("Item: ", item);

  const paymentModal = () => {
    swal(t("common.gopremium"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/subscription-plan");
    });
  };
  const loginModal = () => {
    swal(t("common.login"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/login");
    });
  };
  const share = () => {
    const div = <div></div>;
    const link = window.location.href;
    swal({
      title: "Partager le contenue",
      text: link,
    });
  };

  // if (item && item.is_free === false) {
  //   history.push("/subscription-plan");
  // }

  return (
    <>
      <div className="centerbar">
        <div className="topbannersearch displayflex">
          <Search type={"DOCUMENT"} />
        </div>
        {props?.type === "detail" ? (
          <>
            <div className="beadcrumbs displayflex">
              <ul className="displayflex">
                <li>
                  <a href="#" title="#">
                    {t("common.documents")}
                  </a>
                </li>
                {props.category && (
                  <li>
                    <a
                      href="#"
                      title="#"
                      onClick={() => history.push("/documents")}
                    >
                      {props.category.name}
                    </a>
                  </li>
                )}
                {props.subCategory && (
                  <li>
                    <a href="#" onClick={() => history.goBack()}>
                      {props.subCategory.name}
                    </a>
                  </li>
                )}
                <li>{item?.name}</li>
              </ul>
              {item && <AddBookmark id={item?.id} />}
            </div>
            <div className="details_bloctitle">
              <h1 className="titleh1">{item?.name}</h1>
              <p>{item?.description}</p>
            </div>
            <div
              style={{ marginBottom: 10 }}
              className="_details_img"
              id="reader"
            >
              {item && props.user ? (
                <>
                  {isFeuillete ? (
                    <FlipBook
                      file={
                        item?.is_free
                          ? item.full_content
                          : item?.preview_content
                      }
                    />
                  ) : (
                    refresh && (
                      <ScrollReader
                        file={
                          item?.is_free
                            ? item.full_content
                            : item?.preview_content
                        }
                      />
                    )
                  )}
                </>
              ) : (
                !props.user && (
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      position: "relative",
                    }}
                    className=""
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "500px",
                        objectFit: "contain",
                        zIndex: "1111",
                      }}
                      src={item?.cover_picture}
                      alt=""
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        backgroundColor: "#00000099",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        style={{
                          color: "white",
                          width: "fit-content",
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                        class="ds-btn primary"
                        to="/login"
                      >
                        {" "}
                        {t("common.connect_to_read")}
                      </Link>
                    </div>
                    {/* <div
                    style={{
                      backgroundPosition: "cover",
                      backgroundImage: `url(${item?.cover_picture})`,
                    }}
                    className="no-login"
                  >
                    <div class="ds-btn primary">
                      <Link to="/login" style={{ color: "#1382bf" }}>
                        {t("common.connect_to_read")}
                      </Link>
                  </div> */}
                  </div>
                )
              )}
            </div>
            <div className="details_links displayflex">
              <div className="preview_cover displayflex">
                <div className="preview_button displayflex">
                  {item?.is_free ? (
                    <Download item={item} type="document" />
                  ) : (
                    <Buy item={item} />
                  )}
                  {item && !item.is_free && (
                    <div className="btn_link file_size">{item.price} FCFA</div>
                  )}
                </div>
                {/* {props.isActiveSubcription && (
                  <div className="lecture_feuilletee">
                    <a
                      className="btn_link blue_button"
                      href="#"
                      title=""
                      onClick={(e) => setIsFeuillete(!isFeuillete)}
                    >
                      {isFeuillete ? t("common.scrollread") : t("common.read")}
                    </a>
                  </div>
                )} */}
              </div>
            </div>
            <div className="postdetailscover">
              <div className="details_version_audio details_lguser displayflex">
                {!props.isActiveSubcription && (
                  <p className="smalltitle">
                    Ecoutez aussi la <strong>version audio</strong>
                  </p>
                )}
                <div className="lguser_btn displayflex">
                  <div onClick={() => share()} className="share_btn">
                    <a title="#"></a>
                  </div>
                  <div className="view_btn">
                    <a href="#" title="#">
                      {item?.number_seen || 0}
                    </a>
                  </div>
                  {item && <Like likes={item.interests?.likes} id={item.id} slug={item.slug} type="document" />}
                  <div style={{ marginLeft: 4 }}>
                    <GetAppIcon
                      fontSize="medium"
                      htmlColor="#1382bf"
                      title="Téléchargements"
                    />{" "}
                    {item?.number_download || 0}
                  </div>
                </div>
              </div>

              <div className="lecture_audio">
                {item?.audio_version ? (
                  <ReactAudioPlayer
                    src={item.audio_version}
                    controls
                    style={{ width: "100%", marginBottom: "20px" }}
                    onPlay={() => onPlay()}
                  />
                ) : (
                  item && <PdfAudio item={item} />
                )}
              </div>

              {/* <div className="lecture_audio">
                 <PdfAudio item={item} />
              </div> */}
              <div className="details_lguser displayflex">
                <div className="displayflex">
                  {item && props.user?.id != item.owner?.id && (
                    <UserFollow owner={item.owner} authorname={item.author} />
                  )}
                </div>
              </div>
              {item && !load && <Comments data={reviews} id={item?.id} />}
            </div>
          </>
        ) : (
          <>
            <div className="beadcrumbs displayflex">
              <ul className="displayflex">
                <li>
                  <a href="#" title="#">
                    {t("common.documents")}
                  </a>
                </li>
                {props.category && (
                  <li>
                    <a
                      href="#"
                      title="#"
                      onClick={() => history.push("/documents")}
                    >
                      {props.category.name}
                    </a>
                  </li>
                )}
                {props.subCategory && <li>{props.subCategory.name}</li>}
              </ul>
              {props?.user && (
                <div className="ds-btn primary">
                  <Link to="/upload?item=book">
                    <a title={t("common.add")}>
                      + {t("common.add")} {t("common.book")}
                    </a>
                  </Link>
                </div>
              )}
            </div>
            <div className="filterbloc displayflex">
              <nav className="nav nav-pills">
                <a
                  className={`nav-item nav-link ${nature == 0 && " active"}`}
                  href="#documents"
                  data-toggle="tab"
                  onClick={() => {
                    setNature(0);
                  }}
                >
                  {t("common.books1")} {`(${countBook})`}
                </a>

                <a
                  className={`nav-item nav-link ${nature == 1 && " active"}`}
                  href="#logiciels"
                  data-toggle="tab"
                  onClick={() => {
                    setNature(1);
                  }}
                >
                  {t("common.exercices")} {`(${countExercice})`}
                </a>
              </nav>
            </div>

            <div className="tab-content">
              <div className="tab-pane active" id="livres">
                <div className="tutoriels documents listebook row">
                  {load && <Skeleton count={3} height={100} />}
                  {data
                    .filter((item) => item.nature == nature)
                    .map((item, i) => (
                      <BookItem item={item} />
                    ))}
                  {data.length === 0 && (
                    <div style={{ marginLeft: "20px" }}>
                      {t("common.noresult")}
                    </div>
                  )}
                </div>
              </div>
              <div className="tab-pane" id="livres"></div>
              {!isDetailsPage && (
                <div className="pagination">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageCount={pageCount}
                  />
                </div>
              )}

            </div>
          </>
        )}
        <Ad category={props.book?.pk} position={4} type="book" />

      </div>
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  isActiveSubcription: state.auth.isActiveSubcription,
  category: state.menus.category,
  subCategory: state.menus.subCategory,
  book: state.menus.book,
  menus: state.menus.menus,
}))(BookContent);
