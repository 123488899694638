import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const Banner = () => {
  const history = useHistory();
  const [value, setValue] = React.useState("");
  const { t } = useTranslation();
  const search = (e) => {
    history.push("/search?q=" + value);
  };
  return (
    <>
      {/*--------- Banner --------------- */}
      <div id="topbanner" className="topbanner">
        <div className="bgtopbanner">
          <img src="/assets/images/bg_topbanner.jpg" alt="Dastudy" />
        </div>
        <div className="topbanner_cover">
          <div className="container">
            <div
              style={{
                backgroundImage: "url('/assets/images/topbanner.png')",
              }}
              className="topbannersearch displayflex"
            >
              <div className="bannertexte">
                <h1 className="main-text">{t("banner.explore")}</h1>
                <p className="">
                  <span>
                    <Link
                      to={
                        "/categories/documents?m=" +
                        localStorage.getItem("livre")
                      }
                    >
                      {t("banner.doc")}
                      &nbsp;
                    </Link>
                  </span>
                  {/* <span>
                    <Link
                      to={
                        "/categories/tutorials?m=" +
                        localStorage.getItem("tutoriel")
                      }
                    >
                      {t("banner.video")}
                    </Link>
                  </span> */}
                  <span>
                    <Link
                      to={
                        "/categories/softwares?m=" +
                        localStorage.getItem("logiciel")
                      }
                    >
                      {t("banner.software")}
                    </Link>
                  </span>
                </p>
              </div>
              <div className="separateur"></div>
              <form
                id="searchform"
                className="searchform displayflex"
                action="#"
                method="#"
              >
                <div className="form-group search_field">
                  <form onSubmit={(e) => search(e)}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("common.search")}
                      onChange={(e) => setValue(e.target.value)}
                    />

                    <button type="submit"></button>
                  </form>
                </div>
              </form>
              {/* <!--<div className="topbanner_img">
			   				<img src="assets/images/topbanner.png" alt="Dastudy" />
			   			</div>--> */}
            </div>
          </div>
        </div>
      </div>
      {/*--------- Banner --------------- */}
    </>
  );
};

export default Banner;
