import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { connect } from "react-redux";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const UploadType = (props) => {
  const { t } = useTranslation();

  React.useEffect(() => {}, []);

  return (
    <>
      <Header menu={6} title="Upload type" />
      <div id="content" className="content">
        <div className="account_page pagecontent mew_publicites">
          <div className="container">
            <div className="account_page_cover mes_info_perso">
              <h1 className=" text-black">{t("upload.type")}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("upload.type_description"),
                }}
              ></p>
              <div className="mes_info_perso_cover row p-2">
                <div
                  className="col-md-5 link_item mb-1"
                  style={{
                    padding: '0px'
                  }}
                >
                  <img src="/img/visible.jpg" alt=""/>
                  <div className="type_text" style={{minHeight: '177px'}}>
                    <p
                      dangerouslySetInnerHTML={{ __html: t("upload.item1") }}
                    ></p>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div
                  className="col-md-5 link_item mb-1"
                  style={{
                    padding: '0px'
                  }}
                >
                  <img src="/img/users.jpg" alt=""/>
                  <div className="type_text" >
                    <p
                      dangerouslySetInnerHTML={{ __html: t("upload.item2") }}
                    ></p>
                  </div>
                </div>
                <h2 style={{marginTop: '30px',marginBottom:'30px'}}> {t("upload.title1")}</h2>

                <Link
                  to="/upload?item=book"
                  className="col-md-5 link_item mb-1"
                   style={{padding: '0px'}}
                >
                  <img src="/img/bbook.jpg" alt=""/>
                  <div className="type_text">
                    <b>{t("upload.item3")}</b>
                  </div>
                </Link>
                <div className="col-md-2"></div>
                <Link
                  to="/upload?item=software"
                  className="col-md-5 link_item mb-1"
                  style={{padding: '0px'}}
                >
                  <img src="/img/software.jpg" alt=""/>
                  <div className="type_text">
                    <b>{t("upload.item4")}</b>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />

      <Footer />
    </>
  );
};

export default connect((state) => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
}))(UploadType);
