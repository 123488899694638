import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { connect, useDispatch } from "react-redux";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import {
  cities,
  countries,
  updateProfil,
} from "../../../services/main.service";
import { LOGIN_SUCCESS } from "../../../redux/actions/types";
import { useTranslation } from "react-i18next";

const generateArrayOfYears = () => {
  var max = new Date().getFullYear();
  var min = max - 100;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};
const PersonalInformation = (props) => {
  const day = Array.from({ length: 31 }, (_, i) =>
    i + 1 < 10 ? "0" + (i + 1) : "" + (i + 1)
  );
  const month = Array.from({ length: 12 }, (_, i) =>
    i + 1 < 10 ? "0" + (i + 1) : "" + (i + 1)
  );

  const year = generateArrayOfYears();
  const [image, setImage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [country, setCountry] = React.useState([]);
  const [genre, setGenre] = React.useState(props?.user?.gender);
  const [city, setCity] = React.useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSubmit = (e) => {
    e.preventDefault();
    const datas = new FormData(e.target);
    setLoading(true);
    const year = datas.get("year");
    const month = datas.get("month");
    const day = datas.get("day");

    if ( year && month && day ) {
      datas.append("birth_date", year + "-" + month + "-" + day);
    }
    updateProfil(datas, props.user.id)
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: res.data },
        });
        NotificationManager.success("Profil modifié avec succès");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCity = (val) => {
    cities(val)
      .then((res) => {
        if (res.status == 200) setCity(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    countries()
      .then((res) => {
        if (res.status == 200) {
          setCountry(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (props.user && props.user.country) getCity(props.user.country);
  }, []);

  return (
    <>
      <Header menu={8} />
      <div id="content" className="content">
        <div className="account_page pagecontent mew_publicites">
          <div className="container">
            <div className="account_page_cover mes_info_perso">
              <h1 className="titleh1 text-black">
                {t("profil.personnal_info")}
              </h1>
              <div className="mes_info_perso_cover">
                <form
                  className="modif_infosform"
                  onSubmit={onSubmit}
                  encType="multipart/form-data"
                >
                  <div className="file_field form-group">
                    <label style={{ marginTop: 0, marginBottom: "20px" }}>
                      {t("login.profil")}
                    </label>
                    <div class="formfile">
                      <label for="input-file" className="displayflex">
                        <img
                          className="lazy"
                          src={
                            image
                              ? image
                              : props.user?.profile_picture
                              ? props.user?.profile_picture
                              : "/assets/images/avatar.png"
                          }
                          style={{ objectFit: "cover", height: 100 }}
                        />
                        <span>{t("login.click")}</span>
                      </label>
                      <input
                        id="input-file"
                        type="file"
                        name="profile_picture"
                        onChange={(e) =>
                          setImage(URL.createObjectURL(e.target.files[0]))
                        }
                        accept="image/*"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("login.name")}
                      defaultValue={props?.user?.first_name}
                      name="first_name"
                    />
                  </div>
                  <div className="form-group date_naissance">
                    <label>{t("login.date")}</label>
                    <div className="select_group displayflex">
                      <div className="form-group select_field">
                        <select className="form-control" name="day">
                          <option>{t("login.day")}</option>
                          {day.map((j, i) => (
                            <option
                              value={j}
                              selected={
                                props?.user?.birth_date?.split("-")[2] == j
                              }
                            >
                              {j}
                            </option>
                          ))}
                        </select>
                      </div>                       
                      <div className="form-group select_field">
                        <select className="form-control" name="month">
                          <option>{t("login.month")}</option>
                          {month.map((j, i) => (
                            <option
                              value={j}
                              selected={
                                props?.user?.birth_date?.split("-")[1] == j
                              }
                            >
                              {j}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group select_field">
                        <select className="form-control" name="year">
                          <option>{t("login.year")}</option>
                          {year.map((j, i) => (
                            <option
                              value={j}
                              selected={
                                props?.user?.birth_date?.split("-")[0] == j
                              }
                            >
                              {j}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("login.genre")}</label>
                    <div className="group_genre checkbox_group displayflex">
                      <div className="form-check custom-control custom-checkbox">
                        <label className="checkcustom displayflex">
                          <span>{t("login.male")}</span>
                          <input
                            type="radio"
                            name="is_name"
                            value="MAN"
                            checked={genre === "MAN"}
                            onChange={(e) => setGenre(e.target.value)}
                          />
                          <span className="checkicon"></span>
                        </label>
                      </div>
                      <div className="form-check custom-control custom-checkbox">
                        <label className="checkcustom displayflex">
                          <span>{t("login.female")}</span>
                          <input
                            type="radio"
                            name="is_name"
                            value="WOMAN"
                            checked={genre === "WOMAN"}
                            onChange={(e) => setGenre(e.target.value)}
                          />
                          <span className="checkicon"></span>
                        </label>
                      </div>
                      <div className="form-check custom-control custom-checkbox">
                        <label className="checkcustom displayflex">
                          <span>{t("login.other")}</span>
                          <input
                            type="radio"
                            name="is_name"
                            value="OTHER"
                            checked={genre === "OTHER"}
                            onChange={(e) => setGenre(e.target.value)}
                          />
                          <span className="checkicon"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("login.profession")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="profession"
                      defaultValue={props?.user?.profession}
                      name="profession"
                    />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      name="description"
                    >
                      {props?.user?.description}
                    </textarea>
                  </div>
                  <div className="form-group pays_residence">
                    <label>{t("login.country")}</label>
                    <div className="select_group displayflex">
                      <div className="select_field">
                        <select className="form-control" name="country">
                          {country.map((p) => (
                            <option
                              value={p.id}
                              selected={props?.user?.country == p.id}
                            >
                              {p.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("login.city")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("login.city")}
                      defaultValue={props?.user?.town}
                      name="town"
                    />
                  </div>
                  <div className="groupe_identification">
                    <label>{t("login.email")}</label>
                    <div className="groupe_identification form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        defaultValue={props?.user?.email}
                        readOnly
                      />
                    </div>
                    <label>{t("login.username")}</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("login.username")}
                        defaultValue={props?.user?.username}
                        name="username"
                        pattern="[a-zA-Z0-9]*"
                      />
                    </div>
                    {/* <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mot de passe"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Confirmer mot de passe"
                      />
                    </div> */}
                    <div className="submit_field btn_button displayflex">
                      <button type="button" className="borderbuton text-white">
                        {t("common.cancel")}
                      </button>
                      <button type="submit" className="blue_button">
                        {t("common.save")}
                        {loading && (
                          <span className="spinner-border spinner-border-sm ml-2"></span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />

      <Footer />
    </>
  );
};

export default connect((state) => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
}))(PersonalInformation);
