import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
} from "@material-ui/core";
import { subscribers } from "../../../services/main.service";
import SubscribersItem from "./SubscribersItem";

const MySubscribers = () => {
  
  const [subscribersList, setSubscribersList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    subscribers().then((res) => {
      setSubscribersList(res.data.results);
      setLoading(false);
    });
  }, []);

  const showSubscribersList = () => {
    if (loading) return <CircularProgress />;

    return subscribersList.map((subscriber) => (
      <div
        key={subscriber.id}
        class="post_item col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <SubscribersItem subscriber={subscriber} />
      </div>
    ));
  };

  return (
    <>
      <Header menu={12} title={"subscribers"} />
      <Box padding={24}>
        <div class="espace_account displayflex">
          <h1 class="titleh1">Mes Abonnées</h1>
        </div>

        <div
          id="logigiels"
          className="logiciels homebloc slidebloc slidepadding">
          <div className="container">
            <div className="homebloc_cover">
              <h2 className="homebloctitle">
              </h2>
              <div className="homebloc_content">
                <div className="sliderecentsposts">
                  
                  <div className="tutoriels documents  listebook row">
                    {showSubscribersList()}
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
      {/* </div> */}

      <Footer />
    </>
  );
};

export default MySubscribers;
