import { SET_MESSAGE, CLEAR_MESSAGE } from '../actions/types'

import { log } from '../../helpers'

const initialState = {}

export default function (state = initialState, action) {
    const { type, payload } = action

    //log('Message reducer', action)

    switch (type) {
        case SET_MESSAGE:
            return { ...state, type: payload.type, content: payload.content }

        case CLEAR_MESSAGE:
            return { ...state, message: initialState }

        default:
            return state
    }
}