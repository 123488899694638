import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import "react-pdf/dist/Page/AnnotationLayer.css";

const PdfReader = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(290);
  var elmnt = null;
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  React.useEffect(() => {
    elmnt = document.getElementById("doc");
    setWidth(elmnt?.offsetWidth);
  }, []);
  return (
    <div id="doc">
      <Document
        file={{ url: props?.file }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
         {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} height={700} width={width} />
      ))}
      </Document>
     
    </div>
  );
};

export default PdfReader;
