import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import { deleteBookmark, getBookmark } from "../../../services/main.service";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Pagination";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const MyFavourites = () => {
  const [data, setData] = React.useState([]);
  const { page } = useParams();
  const [currentPage, setCurrentPage] = React.useState(page ? page + "" : "1");
  const { t } = useTranslation();
  const [pageCount, setPageCount] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  const [refresh, setRefresh] = React.useState(false);
  const [type, setType] = React.useState("DOCUMENT");
  const [load, setLoad] = React.useState(true);
  const [typeLink, setTypeLink] = React.useState("documents/");

  React.useEffect(() => {
    setLoad(true);
    getBookmark(type, currentPage).then((res) => {
      if (res.status == 200) {
        setTotal(res.data.count);
        setPageCount(res.data.count / 10);
        setData(res.data.results);
        setLoad(false);
      }
    });
  }, [currentPage, type]);

  const deleteItem = (e, id) => {
    e.preventDefault();
    swal({
      title: "Voulez-vous réellement supprimer?",
      text: "",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Oui",
          closeModal: false,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: true,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) {
        deleteBookmark(id)
          .then((res) => {
            setRefresh(!refresh);
            swal("Suppression terminée!", {
              icon: "success",
            }).then((val) => {});
          })
          .catch((err) => {
            swal("Erreur survenue lors de la suppression", {
              icon: "error",
            });
          });
      }
    });
  };
  return (
    <>
      <Header menu={8} />
      <div id="content" className="content">
        <div className="account_page pagecontent mew_publicites">
          <div className="container">
            <div className="account_page_cover displayflex">
              <div className="mes_favoris_left">
                <h1 className="titleh1 text-black">{t("profil.favoris")}</h1>
                <div className="filterbloc displayflex">
                  <nav className="nav nav-pills">
                    <a
                      className="nav-item nav-link active"
                      href="#documents"
                      data-toggle="tab"
                      onClick={() => {
                        setType("DOCUMENT");
                        setCurrentPage("1");
                        setTypeLink("documents/");
                      }}
                    >
                      {t("common.documents")}
                    </a>
                    <a
                      className="nav-item nav-link"
                      href="#logiciels"
                      data-toggle="tab"
                      onClick={() => {
                        setType("SOFTWARE");
                        setCurrentPage("1");
                        setTypeLink("softwares/");
                      }}
                    >
                      {t("common.softwares")}
                    </a>
                  </nav>
                  <div className="catfliter">
                    {/* <label className="current_choice">
                      Filtrer par {" "}
                      <span>Catégorie</span>
                      <i className="bi bi-chevron-down"></i>
                    </label>
                    <ul>
                      <li>catégorie</li>
                      <li>tuto</li>
                      <li>doc</li>
                    </ul> */}
                  </div>
                  <div className="nbresults_infos results_uploads">
                    {t("common.result")}{" "}
                    <strong>
                      {data?.length}-{data?.length}
                    </strong>{" "}
                    {t("common.to")} <strong>{total}</strong>
                  </div>
                </div>
                <div className="tab-content">
                  <div className="tab-pane active" id="documents">
                    {load && (
                      <div className="result_item">
                        <Skeleton count={5} height={100} />
                      </div>
                    )}
                    {!load &&
                      data.map((item, key) => (
                        <div className="result_item" key={key}>
                          {/* <div className="result_date">Aujourd'hui</div> */}
                          <div className="favoris_listperdate">
                            <div className="result_content displayflex">
                              <div className="result_content_left displayflex">
                                <div
                                  className="result_img"
                                  style={{ maxWidth: "40%" }}
                                >
                                  <img
                                    className="lazy"
                                    src={item?.resource?.cover_picture}
                                    alt="Dastudy"
                                    style={{ objectFit: "cover" }}
                                  />
                                </div>
                                <div className="result_texte">
                                  <div className="result_name">
                                    {item.resource_name}
                                  </div>
                                  <div className="result_domaine">
                                    {item.resource?.category?.name}
                                  </div>
                                  <div className="result_from">
                                    {" "}
                                    {item.resource?.owner?.first_name}
                                  </div>
                                </div>
                              </div>
                              <div className="result_content_right displayflex">
                                <div
                                  className="result_delete"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => deleteItem(e, item.id)}
                                >
                                  {" "}
                                  {t("common.delete")}
                                </div>
                                <div className="result_link">
                                  <Link
                                    to={
                                      typeLink +
                                      item?.resource?.category?.slug +
                                      "/detail/" +
                                      item?.resource?.slug
                                    }
                                    title="Voir"
                                  >
                                    {t("common.see")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    <div className="pagination">
                      <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount / 10}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <SidebarAdd number={1} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default MyFavourites;
