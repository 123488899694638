import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import history from "./helpers/history";

import AppLayout from "./components/layouts/AppLayout";

import { FancyRoute } from "./components";

import useUserSubscription from "./hooks/useUserSubscription";

import {
  Profile,
  Upload,
  Uploads,
  Account,
  Donate,
  ForumTopic,
  PersonalInformation,
  Login,
  Logout,
  Register,
  ResetPassword,
  PasswordForgot,
  ConfirmAccount,
  Builder,
  MyPages,
  MyAds,
  MyCell,
  MySubscription,
  MyBill,
  MyFavourites,
  Subscription,
  Team,
  Feedsbacks,
  Faq,
  Terms,
  AiChatAssistant,
  AIPDFChat,
} from "./pages";
// import Category from "./pages/app/softwares/SoftwareList";
import AddAd from "./pages/AddAd";
import Subscriptions from "./pages/Subscritions";
import ScrollIntoView from "./components/ScrollIntoView";
import SeachResult from "./pages/app/SeachResult";
import Categories from "./pages/app/categories/Categories";
import Success from "./pages/Succes";
import PayError from "./pages/PayError";
import UploadType from "./pages/app/uploads/UploadType";
import Content from "./pages/app/Content";
import ContentDetail from "./pages/app/ContentDetail";
import AppContent from "./pages/app/AppContent";
import Discussion from "./pages/app/forum/Discussion";
import MySubscribers from "./pages/app/subscribers/MySubscribers";
import Analyse from "./pages/app/analyse/Analyse";
import AnalyseUpload from "./pages/app/analyse/AnalyseUpload";
import { getToken } from "./api/pythonBackend";

const AppRoutes = () => {
  const token = getToken();
  const { featureMap, loadingSubscription } = useUserSubscription({
    token: token,
  });
  // useUserProfile({token: token})
  // console.log({ featureMap, loadingSubscription });

  return (
    <BrowserRouter history={history}>
      <Suspense fallback={<div>Loading </div>}>
        <ScrollIntoView>
          <Switch>
            <FancyRoute
              {...{
                title: "Acceuil",
                path: "/",
                exact: true,
                component: AppLayout,
              }}
            />
            <FancyRoute
              {...{
                title: "Acceuil",
                path: "/paypal-cancelled/",
                exact: true,
                component: AppLayout,
              }}
            />
            <FancyRoute
              {...{
                title: "Connexion",
                path: "/login/",
                exact: true,
                component: Login,
              }}
            />
            <FancyRoute
              {...{
                title: "Déconnexion",
                path: "/logout/",
                exact: true,
                component: Logout,
              }}
            />
            <FancyRoute
              {...{
                title: "Inscription",
                path: "/register/",
                exact: true,
                component: Register,
              }}
            />
            <FancyRoute
              {...{
                title: "Vérification de compte",
                path: "/verify-user/",
                exact: true,
                component: ConfirmAccount,
              }}
            />
            <FancyRoute
              {...{
                title: "Mot de passe oublié",
                path: "/password-forgot/",
                exact: true,
                component: PasswordForgot,
              }}
            />
            <FancyRoute
              {...{
                title: "Réinitialisation du mot de passe",
                path: "/reset-password/",
                exact: true,
                component: ResetPassword,
              }}
            />

            <FancyRoute
              {...{
                title: "Tutoriels",
                path: "/tutorials/",
                exact: true,
                component: () => <Builder />,
              }}
            />

            <Route exact path="/topic/" component={ForumTopic} />
            {/* <Route exact path="/forum/" component={Forum} /> */}
            <Route exact path="/discussion/" component={Discussion} />
            <Route exact path="/analyse/:id" component={Analyse} />
            <Route exact path="/analyse-upload/" component={AnalyseUpload} />

            <Route exact path="/upload/" component={Upload} />
            <Route exact path="/add-ad/" component={AddAd} />
            <Route exact path="/uploads/" component={Uploads} />
            <Route exact path="/test-new-ai/" component={AiChatAssistant} />
            <Route exact path="/test-chat-pdf/" component={AIPDFChat} />

            <Route
              exact
              path="/:type/:sub_category"
              component={() => <Categories type="sub-cat" />}
            />
            <FancyRoute
              {...{
                title: "Livres",
                path: "/:type/:category/:slug",
                exact: true,
                component: Content,
              }}
            />
            <Route path="/team/" component={Team} />
            <Route path="/feedsbacks/" component={Feedsbacks} />
            <Route path="/faq/" component={Faq} />
            <Route path="/terms/" component={Terms} />
            <Route
              path="/:type/:category/detail/:id"
              component={ContentDetail}
            />
            {/* <Route path="/tutorial-detail" component={TutorialDetail} /> */}
            <Route path="/search/" component={SeachResult} />
            <Route path="/donate/" component={Donate} />
            <Route path="/success/" component={Success} />
            <Route path="/pay_error/" component={PayError} />
            <Route path="/subscription-plan/" component={Subscription} />

            {localStorage.getItem("token") && (
              <Switch>
                {/* <Route path="/annoncepub/" component={Category} /> */}
                <Route path="/account/" component={Account} />
                <Route exact path="/profile/" component={Profile} />
                <Route exact path="/subscritions/" component={Subscriptions} />
                <Route
                  path="/personal-information/"
                  component={PersonalInformation}
                />
                <Route path="/pages/" component={MyPages} />
                <Route path="/subscribers/" component={MySubscribers} />
                <Route path="/ads/" component={MyAds} />
                <Route path="/cell/" component={MyCell} />
                <Route path="/subscriptions/" component={MySubscription} />
                <Route path="/factures/" component={MyBill} />
                <Route path="/favourites/" component={MyFavourites} />
                <Route path="/upload-type/" component={UploadType} />
                <Route exact path="/:type/" component={() => <AppContent />} />
              </Switch>
            )}
            <Route exact path="/:type/" component={() => <AppContent />} />
          </Switch>
        </ScrollIntoView>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
