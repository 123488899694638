import { useState, useEffect, useCallback } from "react";
import {
  IS_SUBSCRIBE,
  SET_CURRENT_SUBSCRIPTION,
  FEATURE_MAP_UPDATE,
} from "../redux/actions/types";
import { currentSubcription } from "../services/main.service";
import { useDispatch } from "react-redux";

/**
 * handle everything related to the user subscription management.
 * it connect to the backend, retrieve all the data and perform any
 * necessary operation to morph them into the a shaped than can be used
 * easily
 * @author Adonis SIMO <simoadonis@yahoo.fr>
 */
export default function useUserSubscription({
  /**
   * The JWT token of this user, it's used to communicate with the backend.
   */
  token,
}) {
  const dispatch = useDispatch();

  const [userCurrentSubscription, setUserCurrentSubscription] = useState(null);
  const [isFreePlan, setIsFreePlan] = useState(true); // by default the user is on a free plan
  const [planItemValues, setPlanItemValues] = useState([]);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [featureMap, setFeatureMap] = useState(
    {
      NUM_DWN: {
        value: 5,
        type: "INT",
        constraints: {},
      },
      ONL_READ: {
        value: true,
        type: "BOOLEAN",
        constraints: {},
      },
      AUD_READ: {
        value: false,
        type: "BOOLEAN",
        constraints: {},
      },
      ADS: {
        value: true,
        type: "BOOLEAN",
        constraints: {},
      },
      ADD_BOOKMARK: {
        value: false,
        type: "BOOLEAN",
        constraints: {},
      },
      PUB_SORT: {
        value: false,
        type: "BOOLEAN",
        constraints: {},
      },
      AI_FORUM: {
        value: false,
        type: "BOOLEAN",
        constraints: {},
      },
      PRIV_CLOUD: {
        value: false,
        type: "BOOLEAN",
        constraints: {},
      },
      NUM_ASK: {
        value: 10,
        type: "INT",
        constraints: {},
      },
      AI_CHATDOC: {
        value: true,
        type: "BOOLEAN",
        constraints: {},
      },
    }
    // this will contains a mapping thayt tells the UI if the user can do something or not.
  );

  /**
   * process if the subsciption is a free plan or not
   */
  useEffect(() => {
    if (userCurrentSubscription !== null) {
      if (parseInt(userCurrentSubscription.billing_plan.price) === 0) {
        setIsFreePlan(true);
      }

      let _featureMap = {};
      userCurrentSubscription.billing_plan.items.map(
        ({ item, value }, index) => {
          _featureMap[item.code] = {
            value: value,
            type: item.data_type,
            constraints: {}, // TODO: load constraints from the backend
          };
        }
      );
      setFeatureMap(_featureMap);
    }
  }, [userCurrentSubscription]);

  /**
   * Everytime the feature map changes it should be saved into the store.
   * @author Adonis SIMO <simoadonis@yahoo.fr>
   */
  useEffect(() => {
    dispatch({
      type: FEATURE_MAP_UPDATE,
      payload: featureMap,
    });
  }, [featureMap]);

  /**
   * load the current subscription from the api once
   */
  useEffect(() => {
    if (token !== "") {
      setLoadingSubscription(true);
      currentSubcription()
        .then((res) => {
          if (res.status === 200) {
            setUserCurrentSubscription(res.data);
            dispatch({
              type: IS_SUBSCRIBE,
              payload: {
                isActiveSubcription: res.data.is_active,
              },
            });
            dispatch({
              type: SET_CURRENT_SUBSCRIPTION,
              payload: { currentSubcription: res.data },
            });
          }
          setLoadingSubscription(false);
        })
        .catch((error) => {
          console.log({ error });
          console.log("error while getting the subscription");
          // TODO: report to an error handling service and store in the state
          setLoadingSubscription(false);
        });
    }
  }, []);

  /**
   * Is used to verify is a user can perform a specific action based on his subscription
   * @param {string} actionCode the code of the action based on the billing plan spec
   * @author Adonis SIMO <simoadonis@yahoo.fr>
   */
  const canPerformAction = useCallback(
    (actionCode) => {
      return featureMap.hasOwnProperty(actionCode);
      // check if the actionCode is recognized
      // check it's value (bool, string, or int)
      // if ( featureMap.hasOwnProperty(actionCode)){
      //   let planValueItem = userCurrentSubscription.billing_plan.items.filter(
      //     (ite) => ite.item.code === actionCode
      //   )[0];
      //   if (planValueItem.data_type === 'BOOLEAN'){

      //   }
      // }
    },
    [featureMap]
  );

  return {
    userCurrentSubscription,
    featureMap,
    planItemValues,
    isFreePlan,
    loadingSubscription,

    canPerformAction,
  };
}
