import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";

import SidebarAdd from "../../../components/app/books/SidebarAdd";
import BookItem from "../../../components/items/BookItem";
import { userPageContent, userPageId } from "../../../services/main.service";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import UserBlock from "../../../components/UserBlock";
import Content from "./Content";
const $ = window.jQuery;

const Page = (props) => {
  const [data, setData] = React.useState();
  const [page, setPage] = React.useState();
  const [load, setLoad] = React.useState(true);
  const { type } = useParams();
  const { t } = useTranslation();
  const init = () => {
    $(".sliderecentsposts").slick({
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: true,
      centerPadding: "150px",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "100px",
          },
        },
      ],
    });
  };
  React.useEffect(() => {
    userPageId(type).then((res) => {
      if (res.status == 200) {
        setPage(res.data);
      }
    });
    userPageContent(type).then((res) => {
      
      if (res.status == 200) {
        setData(res.data);
        
        init();
        setLoad(false);
      }
    });
  }, []);
  return (
    <>
      <Header menu={12} title={page?.first_name} />

      <div id="content" className="content page_categorie page_documents">
        {!load && (
          <div className="contentbar displayflex mx-auto">
            <UserBlock page={page} />
            <Content data={data} />
            <SidebarAdd type="book" />
          </div>
        )}
        <div
          id="logigiels"
          className="logiciels homebloc slidebloc slidepadding"
        >
          <div className="container">
            <div className="homebloc_cover">
              <h2 className="homebloctitle">
                <span>{t("common.similar_page")}</span>
              </h2>
              <div className="homebloc_content">
                <div className="sliderecentsposts">
                  {data?.documents
                    ?.sort((a, b) => b.number_seen - a.number_seen)
                    ?.slice(0, 10)
                    .map((item, i) => (
                      <BookItem item={item} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(Page);
