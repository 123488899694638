import React from "react";
import { useParams } from "react-router-dom";
import Books from "./books/Books";
import SoftwareList from "./softwares/SoftwareList";

const Content = () => {
  const { type } = useParams();
  return <>{type === "documents" ? <Books /> : <SoftwareList />}</>;
};

export default Content;
