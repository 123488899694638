import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  documents,
  documentDetail,
  getReviews,
} from "../../../services/main.service";
// import PdfReader from "../../PdfReader";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

import swal from "sweetalert";
import { stringToSlug } from "../../../helpers/functions";
import Search from "../../../components/Search";
import BookItem from "../../../components/items/BookItem";
import Pagination from "../../../components/Pagination";
import Ad from "../../../components/ad";
import SofwareListItem from "../../../components/items/SofwareListItem";

const Content = (props) => {
  const [data, setData] = React.useState([]);
  const { page} = useParams();
  const [currentPage, setCurrentPage] = React.useState(page ? page + "" : "1");
  const { t } = useTranslation();
  const [pageCount, setPageCount] = React.useState(0);
  const [load, setLoad] = React.useState(true);
  const [nature, setNature] = React.useState(0);
  const [countBook, setCountBook] = React.useState(
    props?.data?.documents?.length || 0
  );
  const [countSofware, setCountSofware] = React.useState(
    props?.data?.software?.length || 0
  );
  React.useEffect(() => {
    if (nature == 0) {
      setPageCount(props.data?.documents?.length / 12||1);
      setData(props.data?.documents);
    } else {
      setPageCount(props.data?.software?.length / 10||1);
      setData(props.data?.software);
    }
  }, [currentPage, nature]);

  return (
    <>
      <div className="centerbar">
        <>
          <div className="filterbloc displayflex">
            <nav className="nav nav-pills">
              <a
                className={`nav-item nav-link ${nature == 0 && " active"}`}
                href="#documents"
                data-toggle="tab"
                onClick={() => {
                  setNature(0);
                }}
              >
                {t("common.books")} {`(${countBook})`}
              </a>

              <a
                className={`nav-item nav-link ${nature == 1 && " active"}`}
                href="#logiciels"
                data-toggle="tab"
                onClick={() => {
                  setNature(1);
                }}
              >
                {t("common.softwares")} {`(${countSofware})`}
              </a>
            </nav>
            <div className="topbannersearch displayflex search-page">
              <Search type={"DOCUMENT"} />
            </div>
          </div>

          <div className="tab-content">
            <div className="tab-pane active" id="livres">
              <div className={nature==0?"tutoriels documents listebook row": "blc_category logiciels documents listebook row"}>
                {load && <Skeleton count={3} height={100} />}
                {data?.slice(
                    (parseInt(currentPage) - 1) * 12,
                    (parseInt(currentPage) - 1) * 12 + 12
                  )
                  .map((item, i) =>
                    i === 5 ? (
                      <>
                        {nature == 0 ? (
                          <BookItem item={item} />
                        ) : (
                          <SofwareListItem item={item} />
                        )}
                        <Ad
                          category={props.book?.pk}
                          position={4}
                          type="book"
                        />
                      </>
                    ) : nature == 0 ? (
                      <BookItem item={item} />
                    ) : (
                      <SofwareListItem item={item} />
                    )
                  )}
                {data?.length === 0 && (
                  <div style={{ marginLeft: "20px" }}>
                    {t("common.noresult")}
                  </div>
                )}
              </div>
            </div>
            <div className="tab-pane" id="livres"></div>
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
              />
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  isActiveSubcription: state.auth.isActiveSubcription,
  category: state.menus.category,
  subCategory: state.menus.subCategory,
  book: state.menus.book,
  menus: state.menus.menus,
}))(Content);
