import React from 'react'

import {
    AuthLayoutHigherOrderComponent,
    Login as LoginComponent
} from '../../components'


const Login = () => <LoginComponent />

export default Login