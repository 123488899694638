import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";

import SidebarFilter from "../../../components/SidebarFilter";
import SidebarAdd from "../../../components/app/books/SidebarAdd";
import SoftwareContent from "../../../components/app/softwares/SoftwareContent";
import SofwareGridItem from "../../../components/items/SoftwareGridItem";
import { software } from "../../../services/main.service";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { stringToSlug } from "../../../helpers/functions";
import { useParams } from "react-router-dom";

const $ = window.jQuery;

const Detail = (props) => {
  const [data, setData] = React.useState([]);
  const { t, i18n } = useTranslation();
  const { category, id} = useParams();
  const cat=props.menus?.find(item=>stringToSlug(item.name)==category)

  React.useEffect(() => {
    software(1,cat?.pk||"").then((res) => {
      if (res.status == 200) setData(res.data.results.filter(item=>item.id!=id));
      $(".sliderecentsposts").slick({
        autoplay: true,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 3,
        centerMode: true,
        centerPadding: "150px",
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              centerPadding: "60px",
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              centerPadding: "100px",
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "100px",
            },
          },
        ],
      });
    });
  }, []);

  return (
    <>
      <Header menu={4} title={t("common.softwares")} />
      <div
        id="content"
        className="content page_categorie categorie_logiciels page_detail_logicel page_detail"
      >
        <div className="contentbar displayflex">
          <SidebarFilter path="softwares" />
          <SoftwareContent type="detail" />
          <SidebarAdd number={1} />
        </div>
        <div
          id="logigiels"
          className="logiciels homebloc slidebloc slidepadding"
        >
          <div className="container">
            <div className="homebloc_cover">
              <h2 className="homebloctitle">
                <span>
                  {t("common.similar_soft")}
                </span>
              </h2>
              <div className="homebloc_content">
                <div className="sliderecentsposts">
                  {data.map((item, i) => (
                    <SofwareGridItem item={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  menus: state.menus.menus
}))(Detail);
