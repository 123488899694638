import React from "react";
import { useParams } from "react-router-dom";
import Categories from "./categories/Categories";
import Page from "./userpages/Page";

const AppContent = () => {
  const { type } = useParams();
  return <>{type === "documents" || type === "softwares"  ? <Categories type="cat" /> : <Page />}</>;
};

export default AppContent;
