import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const [current, setCurrent]=React.useState(1)
  return (
    <>
      <Header title="F.A.Q" />
      <div id="content" className="content page_faq">
        <div className="container">
          <div className="fa_content">
            <div className="faq_title">
              <h1 className="title_h1" dangerouslySetInnerHTML={{ __html: t("faq.title") }}></h1>
            </div>
            <div className="fcnt_left_cover">
              <div className={current==1?"panel current": "panel"}>
                <div className={current==1?"paneltitle active":"paneltitle"} onClick={()=>setCurrent(1)} dangerouslySetInnerHTML={{ __html: t("faq.quest1") }}></div>
                <div className="panelcontent" style={{ display: current==1&& "block" }}dangerouslySetInnerHTML={{ __html: t("faq.resp1") }}></div>
              </div>
              <div className={current==2?"panel current": "panel"}>
                <div className={current==2?"paneltitle active":"paneltitle"} onClick={()=>setCurrent(2)} dangerouslySetInnerHTML={{ __html: t("faq.quest2") }}></div>
                <div className="panelcontent" style={{ display: current==2&& "block" }} dangerouslySetInnerHTML={{ __html: t("faq.resp2") }}></div>
              </div>
              <div className={current==3?"panel current": "panel"}>
                <div className={current==3?"paneltitle active":"paneltitle"} onClick={()=>setCurrent(3)} dangerouslySetInnerHTML={{ __html: t("faq.quest3") }}></div>
                <div className="panelcontent" style={{ display: current==3&& "block" }} dangerouslySetInnerHTML={{ __html: t("faq.resp3") }}></div>
              </div>
              <div className={current==4?"panel current": "panel"}>
                <div className={current==4?"paneltitle active":"paneltitle"} onClick={()=>setCurrent(4)} dangerouslySetInnerHTML={{ __html: t("faq.quest4") }}></div>
                <div className="panelcontent" style={{ display: current==4&& "block" }} dangerouslySetInnerHTML={{ __html: t("faq.resp4") }}></div>
              </div>
              <div className={current==5?"panel current": "panel"}>
                <div className={current==1?"paneltitle active":"paneltitle"} onClick={()=>setCurrent(5)} dangerouslySetInnerHTML={{ __html: t("faq.quest5") }}></div>
                <div className="panelcontent" style={{ display: current==5&& "block" }} dangerouslySetInnerHTML={{ __html: t("faq.resp5") }}></div>
              </div>
              <div className={current==5?"panel current": "panel"}>
                <div className={current==6?"paneltitle active":"paneltitle"} onClick={()=>setCurrent(6)} dangerouslySetInnerHTML={{ __html: t("faq.quest6") }}></div>
                <div className="panelcontent" style={{ display: current==6&& "block" }} dangerouslySetInnerHTML={{ __html: t("faq.resp6") }}></div>
              </div>
              <div className={current==6?"panel current": "panel"}>
                <div className={current==7?"paneltitle active":"paneltitle"} onClick={()=>setCurrent(7)} dangerouslySetInnerHTML={{ __html: t("faq.quest7") }}></div>
                <div className="panelcontent" style={{ display: current==7&& "block" }} dangerouslySetInnerHTML={{ __html: t("faq.resp7") }}></div>
              </div>
              <div className={current==8?"panel current": "panel"}>
                <div className={current==8?"paneltitle active":"paneltitle"} onClick={()=>setCurrent(8)}dangerouslySetInnerHTML={{ __html: t("faq.quest8") }}></div>
                <div className="panelcontent" style={{ display: current==8&& "block" }} dangerouslySetInnerHTML={{ __html: t("faq.resp8") }}></div>
              </div>
              <div className={current==9?"panel current": "panel"}>
                <div className={current==9?"paneltitle active":"paneltitle"} onClick={()=>setCurrent(9)} dangerouslySetInnerHTML={{ __html: t("faq.quest9") }}></div>
                <div className="panelcontent" style={{ display: current==9&& "block" }} dangerouslySetInnerHTML={{ __html: t("faq.resp9") }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Faq;
