import React from 'react'

const Aside = () => {
    return <>
        <div className="aside">
            pub
        </div>
    </>
}

export default Aside