import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import PdfAudio from "../../../components/PdfAudio";
import ForumItem from "../../../components/items/ForumItem";
import { getAnalyzedBookQuestions } from "../../../services/main.service";

import useAiDocumentAnalyzer from "../../../hooks/useAiDocumentAnalyzer";
import { blueGrey } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";

const AnalyseDiscussion = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const { t } = useTranslation();

  const [pageCount, setPageCount] = React.useState(0);
  const [isCopy, setIsCopy] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [load1, setLoad1] = React.useState(false);
  const [currentError, setCurrentError] = React.useState(null);
  const [responseMessage, setResponseMessage] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);
  const [conversationHistory, setConversationHistory] = React.useState([]); // will be loaded from the api

  const { ask, message, isWriting, summarize, error } = useAiDocumentAnalyzer({
    documentId: id,
    authToken: token,
    onError: (e) => console.log({ e }),
  });

  /**
   * Will update the conversation list according to the data being streamed.
   * @author Adonis SIMO <simoadonis@yahoo.fr>
   **/
  React.useEffect(() => {
    if (isWriting) {
      setConversationHistory((previous) => {
        let lastItemIdx = previous.length - 1;
        let lastItem = previous[lastItemIdx];
        let computedLastItem = {
          ...lastItem,
          assistantMessage: lastItem.assistantMessage + message,
        };
        previous[lastItemIdx] = computedLastItem;
        return [...previous];
      });
    } else {
      setLoad(false);
      setLoad1(false);
    }
  }, [isWriting, message]);

  /**
   * Retrieve all the questions asked against the book
   * @author Adonis SIMO <simo.adonis@yahoo.fr>
   */
  React.useEffect(() => {
    getAnalyzedBookQuestions(id)
      .then((response) => {
        let questions = [];
        if (response.status === 200) {
          response.data.map((question) => {
            questions = [
              ...questions,
              {
                id: question.id,
                userMessage: question.question,
                assistantMessage: question.response
                  ? question.response.replace("####", "")
                  : "",
              },
            ];
            return questions;
          });
          setConversationHistory(questions);
        }
      })
      .catch((error) => {
        setCurrentError({
          code: "RETRIEVE_DOCUMENT_QUESTIONS",
          status: error.response.status,
          statusText: error.response.statusText,
        });
      });
    var messageViewer = document.querySelector("#container");
    messageViewer.scrollTop = messageViewer.scrollHeight;
  }, []);

  const paymentModal = React.useCallback((val) => {
    swal(t("common." + val), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/subscription-plan");
    });
  });

  const sendData = (val) => {
    ask(val);
  };

  React.useEffect(() => { 
    
    if(error !== null){
      console.log("An erroe ")
      console.log({error})
    }
  }, [error])

  const onSummarizeText = React.useCallback(
    () => {
      setConversationHistory((previous) => {
        previous = [...previous, {'id': "someid", userMessage: "Resume ce text"}]
        return previous
      })
      const isWriting = summarize()
      console.log({isWriting})
    },
    [summarize]
  )

  const onSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      if (!props.user) {
        history.push("/login");
        return;
      }

      if (!props.isActiveSubcription && pageCount >= 5) {
        paymentModal("billinplanerror");
        return;
      }

      setLoad(true);
      setLoad1(true);

      setConversationHistory((prev) => {
        let _return = [
          ...prev,
          { userMessage: value, assistantMessage: "..." },
        ];
        return _return;
      });
      sendData(value);
      setValue("");
      setRefresh(!refresh);
    },
    [
      value,
      refresh,
      pageCount,
      props.isActiveSubcription,
      props.user,
      history,
      sendData,
    ]
  );

  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    if (words.length <= 1000) {
      setCount(words.length);
      setValue(text);
    }
  };

  const copyToClipBoard = async (value) => {
    try {
      await navigator.clipboard.writeText(value);
      setIsCopy(true);
      setTimeout(() => {
        setIsCopy(false);
      }, 4000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      id="content"
      style={{ padding: 0, margin: 0 }}
      className={"content page_forum  page_documents "}
    >
      <div className="contentbar displayflexf">
        <div
          style={{ maxWidth: "100%", width: "100%", padding: 0, margin: 0 }}
          className="centerbar"
        >
          <div className="d-flex flex-column d-sm-none">
            <i className="font-size-18" style={{ color: "#1482bf" }}>
              Vous analysez le document
            </i>
            <span style={{ fontWeight: "bolder" }}>
              {props.analysedBook !== null ? props.analysedBook.name : ""}
            </span>
          </div>
          {error !== null && <Alert color="error">Une erreur est survenue de notre cote, pas d'inquietude nous nous en occupons. Code Erreur :{error.content}</Alert>}
          <div
            className="postdetailscover forum-content"
            style={{ height: "90vh", marginTop: "6.5px" }}
          >
            <div className="details_list_users forum_listpost" id="container">
              {conversationHistory.map(
                ({ userMessage, assistantMessage }, index) => {
                  return (
                    <div key={index}>
                      <ForumItem
                        user={props.user}
                        val={userMessage}
                        type={"user"}
                      />
                      {assistantMessage !== undefined ||
                      assistantMessage !== "undefined" ? (
                        <ForumItem val={assistantMessage} type={"assistant"} />
                      ) : null}
                    </div>
                  );
                }
              )}
            </div>
            
          </div>
          {/* <div className="w-100 py-2 my-1">
            <button
              style={{ color: blueGrey }}
              onClick={onSummarizeText}
              disabled={load}
              className="btn btn-outline-success"
            >
              Résumer le document
            </button>
          </div> */}

          <div className="topbannersearch displayflex">
            <form
              id="searchform"
              className="searchform displayflex"
              onSubmit={onSubmit}
            >
              <div className="form-group search_field mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("forum.question")}
                  value={value}
                  onChange={(e) => countWords(e.target.value)}
                  required
                />
                <button
                  type="submit"
                  className="forum"
                  disabled={load}
                  style={{ fontWeight: "bold" }}
                >
                  {load ? (
                    <span className="spinner-border spinner-border-sm ml-2"></span>
                  ) : (
                    "Envoyer"
                  )}
                </button>
              </div>
              <p className="text-right w-100">{count}/1000 mots</p>
            </form>
          </div>
          {responseMessage && !load1 && (
            <div>
              <PdfAudio isRead message={responseMessage} />
              <button
                className="btn btn-primary float-right"
                style={{ marginTop: "-30px" }}
                onClick={() => copyToClipBoard(responseMessage)}
              >
                {t(isCopy ? "forum.copied" : "forum.copy")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  book: state.menus.book,
  isActiveSubcription: state.auth.isActiveSubcription,
}))(AnalyseDiscussion);
