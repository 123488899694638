import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Dropzone from "react-dropzone";
import Select from "react-select";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class AddAd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 2,
      pages: null,
    };
  }

  handleSelect = (pages) => {
    this.setState({ pages }, () =>
      console.log(`Option selected:`, this.state.pages)
    );
  };

  onDrop = (files) => {
    // POST to a test endpoint for demo purposes
    // const req = fetch.post("https://httpbin.org/post");

    // files.forEach((file) => {
    //   req.attach(file.name, file);
    // });

    // req.end();

    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  handleNext = () => {
    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  render() {
    return (
      <>
        <Header />
        <div class='overlay'></div>
        <main className='container col-md-8 mt-48'>
          {this.state.currentStep !== 3 && (
            <h1 className='text-center'>Upload content.</h1>
          )}
          {this.state.currentStep === 3 && (
            <h1 className='text-center'>Document Uploaded. Thank you</h1>
          )}
          {this.state.currentStep === 0 && (
            <div>
              <h4 className='mtb-32'>Where do you want your ad to be found?</h4>
              <div className='row '>
                <div className='col-md-4' onClick={this.handleNext}>
                  <i class='fas fa-book fa-10x'></i>
                  <h2 className='fW700 color3 nomar'> Examen </h2>
                </div>
                <div className='col-md-4' onClick={this.handleNext}>
                  <i class='fas fa-file-video fa-10x'></i>
                  <h2 className='fW700 color3 nomar'> Concours </h2>
                </div>
                <div className='col-md-4' onClick={this.handleNext}>
                  <i class='fab fa-windows fa-10x'></i>
                  <h2 className='fW700 color3 nomar'> Concours </h2>
                </div>
              </div>
            </div>
          )}
          {this.state.currentStep === 1 && (
            <div>
              <h4 className='mtb-32'>I want to upload a:</h4>
              <div className='row '>
                <div className='col-md-4' onClick={this.handleNext}>
                  <i class='fas fa-book fa-10x'></i>
                  <h2 className='fW700 color3 nomar'> Examen </h2>
                </div>
                <div className='col-md-4' onClick={this.handleNext}>
                  <i class='fas fa-file-video fa-10x'></i>
                  <h2 className='fW700 color3 nomar'> Concours </h2>
                </div>
                <div className='col-md-4' onClick={this.handleNext}>
                  <i class='fab fa-windows fa-10x'></i>
                  <h2 className='fW700 color3 nomar'> Concours </h2>
                </div>
              </div>
            </div>
          )}
          {this.state.currentStep === 1 && (
            <Dropzone onDrop={this.onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section className='ubox mtb-32'>
                  <div className='mtb-32' {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className='text-center'>
                      <i class='fa fa-upload fa-10x' aria-hidden='true'></i>
                    </div>
                    <p className='mtb-32 text-center'>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          {this.state.currentStep === 2 && (
            <div className='row mtb-32'>
              <div className='col-md-4'></div>
              <div className='col-md-6'>
                <div class='form-group'>
                  <label for='exampleFormControlSelect1'>
                    Which pages do you want you ad to be found in?
                  </label>
                  <Select
                    isMulti
                    value={this.state.pages}
                    onChange={this.handleSelect}
                    options={options}
                  />
                </div>

                <div class='form-group'>
                  <label for='exampleFormControlSelect1'>
                    For how long do you want your ad to run
                  </label>
                  <select class='form-control' id='exampleFormControlSelect1'>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
                <div class='form-group'>
                  <label for='exampleInputEmail1'>
                    How much are yo placing on your ad?
                  </label>
                  <input
                    type='email'
                    class='form-control'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                    placeholder='Enter email'
                  />
                </div>
                <div class='form-check form-check-inline'>
                  <input
                    class='form-check-input'
                    type='checkbox'
                    id='inlineCheckbox1'
                    value='option1'
                  />
                  <label class='form-check-label' for='inlineCheckbox1'>
                    I have read and accepted the terms and condtions
                  </label>
                </div>

                <button
                  type='button'
                  onClick={this.handleNext}
                  class='mtb-32 btn btn-primary'
                >
                  Primary
                </button>
              </div>
            </div>
          )}

          {this.state.currentStep === 3 && (
            <div>
              <h3 className='mtb-32'>
                Which method of payment do you want to use?
              </h3>
              <div className='row '>
                <div className='col-md-4' onClick={this.handleNext}>
                  <img src='img/momo.jpg' className='card-img-top' alt='...' />
                </div>
                <div className='col-md-4' onClick={this.handleNext}>
                  <i class='fab fa-cc-visa fa-10x'></i>
                </div>
                <div className='col-md-4' onClick={this.handleNext}>
                  <i class='fab fa-paypal fa-10x'></i>
                </div>
              </div>
            </div>
          )}

          {this.state.currentStep === 4 && (
            <div className=' ubox mtb-32'>
              <div className='mtb-32 mlr-32'>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Itaque totam aliquid est rerum sit, natus modi molestiae
                  consequuntur voluptatibus nihil laboriosam doloremque eum
                  quaerat non odit minus obcaecati repudiandae blanditiis.
                </p>

                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Id,
                  iure consectetur! Obcaecati deleniti ullam enim hic iure
                  officiis rerum maiores eos voluptate, quod magnam dolore
                  suscipit. Vel corporis illo pariatur?
                </p>
              </div>
            </div>
          )}
        </main>
        <Footer />
      </>
    );
  }
}

export default AddAd;
