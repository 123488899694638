import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import ScrollReader from "../../../components/scrollReader/ScrollReader";
import { PYTHON_API_ENDPOINT } from "../../../api/pythonBackend";

const AnalyseReader = (props) => {

  const { t } = useTranslation();


  const getFileUrl = () => {
    const token = localStorage.getItem("token");
    return `${PYTHON_API_ENDPOINT}preview-document/${props.analysedBook.pk}?auth_token=Bearer ${token}`;
  };

  return (
    <div className="p-2">
      <div
        style={{ marginBottom: 10, border: '1px solid #0682be', height: '90vh' , overflowY: 'hidden'}}
        className="-_details_img rounded "
        id="-reader"
      >
        {props.analysedBook ?
          <ScrollReader
            showToolbox={true}
            file={getFileUrl()}
          />
          : <p>Loading...</p>}
      </div>
    </div>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  isActiveSubcription: state.auth.isActiveSubcription,
  category: state.menus.category,
  subCategory: state.menus.subCategory,
  book: state.menus.book,
  menus: state.menus.menus,
}))(AnalyseReader);
