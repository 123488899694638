import React, { Component } from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import Dropzone from "react-dropzone";

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 2,
      tags: [],
    };
  }

  onTagsChanged = (tags) => {
    this.setState({ tags });
  };

  onDrop = (files) => {
    // POST to a test endpoint for demo purposes
    // const req = fetch.post("https://httpbin.org/post");

    // files.forEach((file) => {
    //   req.attach(file.name, file);
    // });

    // req.end();

    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  handleNext = () => {
    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  render() {
    return (
      <>
        <Header />
        <div class='overlay'></div>
        <main className='container mt-48 col-md-8'>
          {this.state.currentStep === 1 && (
            <div className='row mtb-32'>
              <div className='col-md-3'>
                <img src='img/slida.jpg' className='card-img-top' alt='...' />
              </div>
              <div className='col-md-7'>
                <div class='form-group'>
                  <label for='exampleInputEmail1'>Full name</label>
                  <input
                    type='email'
                    class='form-control'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                    placeholder='Enter email'
                  />
                </div>

                <div class='form-group'>
                  <label for='exampleInputEmail1'>Date of Birth</label>
                  <input
                    type='date'
                    class='form-control'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                    placeholder='Enter email'
                  />
                </div>

                <div class='form-group'>
                  <label for='exampleFormControlSelect1'>I am a </label>
                  <div>
                    <div class='form-check form-check-inline'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='inlineRadioOptions'
                        id='inlineRadio1'
                        value='option1'
                      />
                      <label class='form-check-label' for='inlineRadio1'>
                        Male
                      </label>
                    </div>
                    <div class='form-check form-check-inline'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='inlineRadioOptions'
                        id='inlineRadio2'
                        value='option2'
                      />
                      <label class='form-check-label' for='inlineRadio2'>
                        Female
                      </label>
                    </div>
                  </div>
                </div>

                <div class='form-group'>
                  <label for='exampleInputEmail1'>Profession</label>
                  <input
                    type='email'
                    class='form-control'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                    placeholder='Enter email'
                  />
                </div>

                <div class='form-group'>
                  <label for='exampleInputEmail1'>Country of Residence</label>
                  <input
                    type='email'
                    class='form-control'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                    placeholder='Enter email'
                  />
                </div>

                <div class='form-group'>
                  <label for='exampleInputEmail1'>City of Residence</label>
                  <input
                    type='email'
                    class='form-control'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                    placeholder='Enter email'
                  />
                </div>

                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className='ubox mtb-32'>
                      <div className='mtb-32' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='text-center'>
                          <i class='fa fa-upload fa-10x' aria-hidden='true'></i>
                        </div>
                        <p className='mtb-32 text-center'>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>

                <div class='form-check form-check-inline'>
                  <input
                    class='form-check-input'
                    type='checkbox'
                    id='inlineCheckbox1'
                    value='option1'
                  />
                  <label class='form-check-label' for='inlineCheckbox1'>
                    I have read and accepted the terms and condtions
                  </label>
                </div>

                <div>
                  <button
                    type='button'
                    onClick={this.handleNext}
                    class='mtb-32 btn btn-primary'
                  >
                    Primary
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.currentStep === 2 && (
            <div class='pricing  card-deck flex-column flex-md-row mb-3'>
              <div class='card  card-pricing text-center px-3 mb-4'>
                <span class='h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm'>
                  Starter
                </span>
                <div class='bg-transparent card-header pt-4 border-0'>
                  <h1
                    class='h1 font-weight-normal text-primary text-center mb-0'
                    data-pricing-value='15'
                  >
                    $<span class='price'>3</span>
                    <span class='h6 text-muted ml-2'>/ per month</span>
                  </h1>
                </div>
                <div class='card-body pt-0'>
                  <ul class='list-unstyled mb-4'>
                    <li>Up to 5 users</li>
                    <li>Basic support on Github</li>
                    <li>Monthly updates</li>
                    <li>Free cancelation</li>
                  </ul>
                  <button
                    type='button'
                    class='btn btn-outline-secondary mb-3'
                    onClick={this.handleNext}
                  >
                    Renoveler
                  </button>
                </div>
              </div>
              <div class='card card-pricing text-center px-3 mb-4'>
                <span class='h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm'>
                  Profesional
                </span>
                <div class='bg-transparent card-header pt-4 border-0'>
                  <h1
                    class='h1 font-weight-normal text-primary text-center mb-0'
                    data-pricing-value='15'
                  >
                    $<span class='price'>3</span>
                    <span class='h6 text-muted ml-2'>/ per month</span>
                  </h1>
                </div>
                <div class='card-body pt-0'>
                  <ul class='list-unstyled mb-4'>
                    <li>Up to 5 users</li>
                    <li>Basic support on Github</li>
                    <li>Monthly updates</li>
                    <li>Free cancelation</li>
                  </ul>
                  <button
                    type='button'
                    class='btn btn-outline-secondary mb-3'
                    onClick={this.handleNext}
                  >
                    Renoveler
                  </button>
                </div>
              </div>

              <div class='card card-pricing text-center px-3 mb-4'>
                <span class='h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm'>
                  Enterprice
                </span>
                <div class='bg-transparent card-header pt-4 border-0'>
                  <h1
                    class='h1 font-weight-normal text-primary text-center mb-0'
                    data-pricing-value='15'
                  >
                    $<span class='price'>3</span>
                    <span class='h6 text-muted ml-2'>/ per month</span>
                  </h1>
                </div>
                <div class='card-body pt-0'>
                  <ul class='list-unstyled mb-4'>
                    <li>Up to 5 users</li>
                    <li>Basic support on Github</li>
                    <li>Monthly updates</li>
                    <li>Free cancelation</li>
                  </ul>
                  <button
                    type='button'
                    class='btn btn-outline-secondary mb-3'
                    onClick={this.handleNext}
                  >
                    Renoveler
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.currentStep === 3 && (
            <div>
              <h1>Account Created, Thank you</h1>
              <div className=' ubox mtb-32'>
                <div className='mtb-32 mlr-32'>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Itaque totam aliquid est rerum sit, natus modi molestiae
                    consequuntur voluptatibus nihil laboriosam doloremque eum
                    quaerat non odit minus obcaecati repudiandae blanditiis.
                  </p>

                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Id, iure consectetur! Obcaecati deleniti ullam enim hic iure
                    officiis rerum maiores eos voluptate, quod magnam dolore
                    suscipit. Vel corporis illo pariatur?
                  </p>
                </div>
              </div>
            </div>
          )}
        </main>
        <Footer />
      </>
    );
  }
}

export default Upload;
