import React from "react";
import SofwareListItem from "../../items/SofwareListItem";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "../../Pagination";
import {
  getReviews,
  software,
  softwareDetail,
  useQuery
} from "../../../services/main.service";
import { connect } from "react-redux";
import Comments from "../../Comments";
import Skeleton from "react-loading-skeleton";
import AddBookmark from "../../AddBookmark";
import UserFollow from "../../UserFollow";
import Ad from "../../ad";
import Search from "../../Search";
import Like from "../../Like";
import Download from "../../Download";
import GetAppIcon from "@material-ui/icons/GetApp";
import swal from "sweetalert";
import { stringToSlug } from "../../../helpers/functions";

const SoftwareContent = (props) => {
  const [data, setData] = React.useState([]);
  const [reviews, setReviews] = React.useState([]);
  const [item, setItem] = React.useState(null);
  const { page, slug, id } = useParams();

  const [currentPage, setCurrentPage] = React.useState(page ? page + "" : "1");
  const { t } = useTranslation();
  const [pageCount, setPageCount] = React.useState(0);
  const [load, setLoad] = React.useState(true);
  const cat = props.menus?.find((item) => stringToSlug(item.name) == slug);


  const history = useHistory();

  React.useEffect(() => {
    if (props?.type === "detail") {
      softwareDetail(id).then((res) => {
        console.log("doc", res);
        if (res.status === 200) setItem(res.data);
      });
      getReviews(id).then((res) => {
        if (res.status === 200) setReviews(res.data);
        setLoad(false);
      });
    } else {
      software(currentPage, cat?.pk != "null" ? cat?.pk : "").then((res) => {
        setPageCount(res.data.count / 10);
        if (res.status === 200) setData(res.data.results);
        setLoad(false);
      });
    }
  }, [currentPage, id, cat]);

  const share = () => {
    const link = window.location.href;
    swal({
      title: "Partager le contenue",
      text: link,
    });
  };

  // if (item && item.is_free === false) {
  //   history.push("/subscription-plan");
  // }
  return (
    <>
      <div className="centerbar">
        <div className="topbannersearch displayflex">
          <Search type="SOFTWARE" />
        </div>
        {props?.type === "detail" ? (
          <>
            <div className="beadcrumbs displayflex">
              <ul className="displayflex">
                <li>
                  <a href="#" title="#">
                    {t("common.softwares")}
                  </a>
                </li>
                {props.category && (
                  <li>
                    <a
                      href="#"
                      title="#"
                      onClick={() =>
                        history.push(
                          "/categories/softwares?m=" + props.book?.pk
                        )
                      }
                    >
                      {props.category.name}
                    </a>
                  </li>
                )}
                {props.subCategory && (
                  <li>
                    <a href="#" onClick={() => history.goBack()}>
                      {props.subCategory.name}
                    </a>
                  </li>
                )}
                <li>{item?.name}</li>
              </ul>
              {item && <AddBookmark id={item?.id} />}
            </div>
            <div className="details_bloctitle">
              <h1 className="titleh1">{item?.name}</h1>

              <p>{item?.description}</p>
            </div>
            <div className="details_img">
              <img src={item?.screenshoot} alt="" />
            </div>
            <div className="postdetailscover">
              <div className="details_lguser displayflex">
                <div className="displayflex">
                  <div className="displayflex">
                    {item && props.user?.id != item.owner?.id && (
                      <UserFollow owner={item.owner} authorname={item.author} />
                    )}
                  </div>
                </div>
                <div className="lguser_btn displayflex">
                  <div onClick={() => share()} className="share_btn">
                    <a title="#"></a>
                  </div>
                  <div className="view_btn">
                    <a href="#" title="#">
                      {item?.number_seen || 0}
                    </a>
                  </div>
                  {item && <Like likes={item.interests?.likes} id={item.id} slug={item.slug} type="software" />}
                  <div style={{ marginLeft: 4 }}>
                    <GetAppIcon
                      fontSize="default"
                      htmlColor="#1382bf"
                      title="Téléchargements"
                    />{" "}
                    {item?.number_download || 0}
                  </div>
                </div>
              </div>
              <div className="versions_logiciels">
                {item?.versions.length > 0 ? (
                  <>
                    <h5 style={{ color: "#007bff" }}>Version:</h5>
                    {item?.versions.map((v) => (
                      <>
                        <div className="version_unit displayflex">
                          <div className="version_name">{v?.label}</div>
                          <div className="lguser_button">
                            <Download item={item} version={v} type="software" />
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="version_unit displayflex">
                      <div className="version_name">{item?.name}</div>
                      <div className="lguser_button displayflex">
                        {item?.is_free && (
                          <Download item={item} type="software" />
                        )}
                        {!item?.is_free && (
                          <>
                            <span>{item?.price} XAF</span>

                            <a
                              className="borderbuton"
                              href="#"
                              title={t("common.buy")}
                            >
                              {t("common.buy")}
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {item && !load && <Comments data={reviews} id={item?.id} />}
            </div>
          </>
        ) : (
          <>
            <div className="beadcrumbs displayflex">
              <ul className="displayflex">
                <li>
                  <a href="#" title="#">
                    {t("common.softwares")}
                  </a>
                </li>
                {props.category && (
                  <li>
                    <a
                      href="#"
                      title="#"
                      onClick={() =>
                        history.push(
                          "/categories/softwares?m=" + props.book?.pk
                        )
                      }
                    >
                      {props.category.name}
                    </a>
                  </li>
                )}
                {props.subCategory && <li>{props.subCategory.name}</li>}
              </ul>
              {props?.user && (
                <div className="ds-btn primary">
                  <Link to="/upload?item=software">
                    <a title={t("common.add")}>
                      + {t("common.add")} {t("common.software")}
                    </a>
                  </Link>
                </div>
              )}
            </div>
            <div className="blc_category logiciels documents listebook row">
              {load && <Skeleton count={3} height={100} />}
              {data.map((item) => (
                <SofwareListItem item={item} />
              ))}
              {data.length === 0 && (
                <div style={{ marginLeft: "20px" }}>{t("common.noresult")}</div>
              )}
            </div>
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
              />
            </div>
          </>
        )}
        <Ad category={props.software?.pk} position={4} type="software" />

      </div>
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  category: state.menus.category,
  subCategory: state.menus.subCategory,
  book: state.menus.book,
  software: state.menus.software,
  menus: state.menus.menus,
}))(SoftwareContent);
