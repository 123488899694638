import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/fr";
import { connect } from "react-redux";

import { stringToSlug } from "../../helpers/functions";
const BookItem = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={props?.isCustom ? "" : "col-6 col-sm-3 col-md-3 col-lg-3"}>
      {props?.item ? (
        <Link
          to={
            "/documents/" +
            stringToSlug(props.item?.category?.name) +
            "/detail/" +
            props.item.slug
          }
        >
          <div
            style={{
              margin: 2,
              padding: 6,
              marginBottom: 18,
            }}
            className=""
          >
            <div style={{ height: 300 }}>
              <img
                style={{
                  border: "1px solid #dee2e6",
                  height: "100%",
                  width: "auto",
                }}
                src={props?.item?.cover_picture}
                alt=""
              />
            </div>
            <div className="post_text">
              <div className="displayflex">
                <h3 style={{ marginTop: "5px" }} className="post_name">
                  {props?.item?.name}
                </h3>
              </div>
              <div className="post_date_and_views displayflex">
                <div className="post_date">
                  {props.item.pub_date && (
                    <Moment locale={i18n.language} fromNow>
                      {props?.item?.pub_date}
                    </Moment>
                  )}
                </div>
                {!props.isCat && (
                  <div className="nb_download">
                    {props?.item?.number_seen} {t("common.views")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default connect((state) => ({
  menus: state.menus.menus,
}))(BookItem);
