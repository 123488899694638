import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/fr";
import ShowMoreText from "react-show-more-text";
import { useQuery } from "../../services/main.service";
import { useTranslation } from "react-i18next";
import { stringToSlug } from "../../helpers/functions";

const SofwareGridItem = (props) => {
  const query = useQuery();
  const { t, i18n } = useTranslation();
  return (
    <div className="post_item">
      <div className="post_item_cover">
        <div className="post_img">
          <Link
            to={"/softwares/"+
            stringToSlug(props.item?.category?.name)+
            "/detail/" +
            props.item?.slug}
            title="Avast antivirus"
          >
            <img src={props?.item?.cover_picture} alt="Dastudy" />
          </Link>
        </div>
        <div className="post_text">
          <h3 className="post_name">
            <Link
              to={"/softwares/"+
              stringToSlug(props.item?.category?.name)+
              "/detail/" +
              props.item?.slug }
              title="Avast antivirus"
            >
              {props?.item?.name}
            </Link>
          </h3>
          <div className="post_from">{props?.item?.author}</div>
          <div className="post_date">
            {" "}
            {props.item.pub_date && (
              <Moment locale={i18n.language} fromNow>
                {props?.item?.pub_date}
              </Moment>
            )}
          </div>
          <div className="nb_download">{props?.item?.number_download} {t("common.downloaded")}</div>
        </div>
      </div>
    </div>
  );
};

export default SofwareGridItem;
