import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "../../services/main.service";
import { useDispatch } from "react-redux";
import { stringToSlug } from "../../helpers/functions";
import "moment/locale/fr";
import { ADD_CATEGORY, SET_SUB_CATEGORY } from "../../redux/actions/types";
import { connect } from "react-redux";

const CategoryItem = (props) => {
  const query = useQuery();
  const { t, i18n } = useTranslation();
  const allmenus = props.menus;
  const dispatch = useDispatch();
  const { type, sub_category } = useParams();
  const cat =!sub_category?stringToSlug(props?.item?.name):sub_category
  const redirect = () => {
    if (props.action == "cat") {
      dispatch({
        type: ADD_CATEGORY,
        payload: props.item,
      });
      dispatch({
        type: SET_SUB_CATEGORY,
        payload: null,
      });
    }
    if (props.action == "sub-cat") {
      dispatch({
        type: SET_SUB_CATEGORY,
        payload: props.item,
      });
    }
  };
  return (
    <div className="post_item col-xs-12 col-sm-6 col-md-3 col-lg-3">
      <Link
        to={
          allmenus?.find((item) => item.parent == props?.item?.pk) == undefined
            ? "/" + props.type + "/" +cat+"/"+ stringToSlug(props?.item?.name)
            : "/" +
              props.type +
              "/" +
              stringToSlug(props?.item?.name)
        }
        title="#"
        onClick={() => redirect()}
      > 
        <div className="post_item_cover">
          {(props.action == "cat" || props.action == "sub-cat") && (
            <div className="post_img">
              <img src={props?.item?.cover_picture} alt="" />
            </div>
          )}
          <div className="post_text">
            <div className="displayflex">
              <h3 className="post_name">
                {props?.item?.name} {props?.item?.resource_count&& "("+props?.item?.resource_count+")"}
              </h3>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default connect((state) => ({
  menus: state.menus.menus
}))(CategoryItem);