import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import Payment from "../../../components/Payment";
import { useTranslation } from "react-i18next";
const Donate = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="pagedonation">
        <Header menu={7} title={t("footer.donate")} />
        <div id="content" className="content">
          <div className="donation_cover">
            <div className="don_img">
              <img
                className="lazy"
                src="/assets/images/donation.png"
                alt="Dastudy"
              />
            </div>
            <div className="don_texte">
              <p>
              {t("donate.message")}
              </p>
              <p> {t("donate.thank")}</p>
            </div>
            <div className="don_link">
              <button
                className="btn borderbuton"
                type="submit"
                data-toggle="modal"
                data-target="#donation"
              >
                 {t("donate.send")}
              </button>
            </div>
          </div>
        </div>
        <Footer />
        <div
          className="modal fade"
          id="donation"
          tabindex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h1 className="tileh1"> {t("donate.donate")}</h1>
                {/* <p>{t("common.payment_method")}</p> */}
              </div>
              <Payment isDonnation={true} />
             </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donate;
