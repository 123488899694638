import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import {
  subcriptions,
  currentSubcription,
} from "../../../services/main.service";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import Skeleton from "react-loading-skeleton";

const MySubscription = () => {
  const [data, setData] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = React.useState();
  const { page } = useParams();
  const [currentPage, setCurrentPage] = React.useState(page ? page + "" : "1");
  const [pageCount, setPageCount] = React.useState(0);

  // todo: remove this line, the user's subscription is already in the state.
  React.useEffect(() => {
    subcriptions(currentPage).then((res) => {
      if (res.status == 200) {
        setPageCount(res.data.count / 10);
        setData(res.data.results);
      }
    });
    currentSubcription().then((res) => {
      setLoad(false)
      if (res.status == 200) setCurrent(res.data);
    });
  }, [currentPage]);
  return (
    <>
      <Header menu={8} />
      <div id="content" className="content">
        <div className="account_page pagecontent">
          <div className="container">
            <div className="account_page_cover">
              <div className="mes_souscriptions">
                <div className="souscriptiontop displayflex">
                  <h1 className="titleh1 text-black">
                    <span>{t("subscription.space")}</span>
                    {t("subscription.title")}
                  </h1>
                  <div className="link">
                    <Link
                      className="renouveler"
                      to="/subscription-plan"
                      title="Renouveler"
                    >
                      {t("subscription.renew")}
                    </Link>
                  </div>
                </div>
                <div className="liste_souscription table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{t("subscription.number")}</th>
                        <th>{t("subscription.duration")}</th>
                        <th>{t("subscription.begin")}</th>
                        <th>{t("subscription.end")}</th>
                        <th>{t("subscription.rest")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                    {load && <tr><td colSpan={6}><Skeleton count={6} height={20} /></td></tr>}
                      {data.reverse().map((item, key) => (
                        <tr key={key}>
                          <td>{item.billing_plan?.name}</td>
                          <td>
                            {item.number_months} {t("login.month")}
                          </td>
                          <td>
                            <Moment format="DD/MM/YYYY">{item.start}</Moment>
                          </td>
                          <td>
                           <Moment format="DD/MM/YYYY">{item.end}</Moment>
                          </td>
                          <td>
                          {new Date()>new Date(item.end) && t("subscription.expire")} 
                            <Moment locale={i18n.language} fromNow>
                              {item.end}
                            </Moment>
                          </td>
                          <td>
                            {item.id == current?.id && (
                              <Link
                                className="renouveler"
                                to="/subscription-plan"
                                title="Renouveler"
                              >
                                {t("subscription.renew")}
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageCount={pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default MySubscription;
