import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {bppLabelMap} from './utils'

const SubscriptionPlan = ({ offers, onSubscribe, selectedCycle  }) => {
  const displayPlans = () =>
    offers.map((offer, i) => (
      <SubcriptionPlanItem key={offer.id} offer={offer} onSubscribe={onSubscribe} selectedCycle={selectedCycle} />
    ));
  return <div className="ds-subscription-container">{displayPlans()}</div>;
};

const SubcriptionPlanItem = connect((state) => ({
  currentSubscription: state.auth.currentSubcription.currentSubcription,
}))(
  ({ offer, onSubscribe, selectedCycle, currentSubscription }) => {

  const {
    title,
    subTitle,
    prices,
    options,
  } = offer;
  const { t } = useTranslation();
  const [currentPrice, setCurrentPrice] = React.useState({pk: 0, price: 0})
  const [availablePrices, setPrices] = React.useState(prices);

  React.useEffect(
    () => {
      let _price = availablePrices.filter( (price, idx) => price.price.recurring_cycle === selectedCycle.cycle )
      if (_price.length > 0){
        setCurrentPrice({
          pk: _price[0].pk,
          price: parseFloat(_price[0].price.price)
        })
      }
    },
    [selectedCycle]
  )

  const acceptIconSrc = "/assets/images/accept-icon.svg";
  const rejectIconSrc = "/assets/images/reject-icon.svg";

  const displayDownloadCounts = (downloadsCount) => {
    if (downloadsCount !== null)
      return (
        <>
          <span>
            {downloadsCount < 10 ? "0" : ""}
            {downloadsCount}
          </span>
          &nbsp;{t("subscription.downloads")} / {t("subscription.day")}
        </>
      );

    return (
      <>
        {t("subscription.downloads")} <span>{t("subscription.unlimited")}</span>
      </>
    );
  };

  /**
   * Display a billing plan item line according to the backend
   * @param {planItem} item a item specified in the provided billing lan
   * @param {int} key list key used to avoid collision
   * @returns a component that display a specific item from the billing plan
   * @author Adonis SIMO <simoadonis@yahoo.fr>
   */
  const displayPlanItem = (item, key) => {
    if (item.display_on_front === false){
      return <div key={key}></div>
    }
    var icon = acceptIconSrc
    
    if(item.label){
      if(item.item.data_type === 'BOOLEAN'){
        icon = item.value === true ? acceptIconSrc : rejectIconSrc
      }
      return (
        <div className="ds-sub-list-item" key={key}>
          <span className="ds-sub-icon">
            <img width={24} src={icon} alt="icon" />
          </span>
          <p className="ds-sub-text">
            {item.label}
          </p>
        </div>
      )
    }
    if (item.item.data_type === 'BOOLEAN') {
      icon = item.value === true ? acceptIconSrc : rejectIconSrc
      return (
        <div className="ds-sub-list-item" key={key}>
          <span className="ds-sub-icon">
            <img width={24} src={icon} alt="icon" />
          </span>
          <p className="ds-sub-text">
            {item.item.name}
          </p>
        </div>
      )
    } else if (item.item.data_type === 'INT') {
      return (
        <div className="ds-sub-list-item" key={key}>
          <span className="ds-sub-icon">
            <img width={24} src={icon} alt="icon" />
          </span>
          <p className="ds-sub-text">
            {item.item.name}&nbsp;<span>{item.value === -1 ? 'Illimite' : item.value}</span>
          </p>
        </div>
      )
    }
    else {
      return (
        <div className="ds-sub-list-item" key={key}>
          <span className="ds-sub-icon">
            <img width={24} src={icon} alt="icon" />
          </span>
          <p className="ds-sub-text">
            {item.item.name}&nbsp;<span>{item.value}</span>
          </p>
        </div>
      )
    }
  }

  const displayDiscussionQuota = (maxQuestion) => {
    if (maxQuestion)
      return (
        <>
          <span>{maxQuestion}</span> &nbsp;{t("subscription.maxQuestion1")}
          <br />
          {t("subscription.maxQuestion2")}
        </>
      );

    return (
      <>
        {t("subscription.discussionPro1")}{" "}
        <span>{t("subscription.unlimited")}</span>{" "}
        {t("subscription.discussionPro2")} {t("subscription.discussionPro3")}
      </>
    );
  };

  const handleSubcribe = (offer) => {
    onSubscribe({
      billing_plan_price: currentPrice.pk,
      price: currentPrice.price 
    });
  };

  return (
    <div className="ds-subscription-plan">
      <div className="ds-sub-badge">{title}</div>
      <div className="ds-sub-price" style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 'xxx-large',
          
      }}>
        <span style={{color: '#0682be'}}>{currentPrice.price}</span> <small style={{ paddingLeft: '8px', fontSize: '30px'}}>Fcfa / {bppLabelMap[selectedCycle.cycle]} </small>
      </div>

      <div className="ds-sub-subTitle">{subTitle}</div>

      <div className="ds-sub-list">
        

        <div style={{ height: "40px" }}></div>
        
        {offer.planItems.map((item, i) => displayPlanItem(item, i))}

      </div>
      {currentSubscription.billing_plan.id !== offer.id && (
        <button
          onClick={() => handleSubcribe(offer)}
          className="ds-sub-badge btn">
          {t("subscription.subscribe")}
        </button>
      )}
    </div>
  );
})


export default connect((state) => ({
  currentSubscription: state.auth.currentSubcription.currentSubcription,
}))(SubscriptionPlan);



