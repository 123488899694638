export const APP_ROOT_URL = `/`

// Authentification
export const ACCOUNT_URL = `${APP_ROOT_URL}account/`
export const REGISTER_URL = `${APP_ROOT_URL}register/`
export const LOGIN_URL = `${APP_ROOT_URL}login/`
export const RESET_USER_PASSWORD_URL = `${APP_ROOT_URL}reset-password/`
export const PASSWORD_FORGOT_URL = `${APP_ROOT_URL}password-forgot/`
export const LOGOUT_URL = `${APP_ROOT_URL}logout/`
export const USER_PROFILE_URL = `${APP_ROOT_URL}profile/`
export const PERSONNAL_INFORMATION_URL = `${APP_ROOT_URL}user/personal-information/`
// Account confirmation
export const CONFIRM_USER_ACCOUNT = `${APP_ROOT_URL}verify-user/`

// Readers
export const READER_URL = `${APP_ROOT_URL}reader/`

// Uploads
export const UPLOAD_URL = `${APP_ROOT_URL}upload/`

// Books
export const BOOKS_LIST_URL = `${APP_ROOT_URL}books/`
export const BOOK_FOR_CATEGORY_URL = `${BOOKS_LIST_URL}:category/`
export const BOOK_SHOW_URL = `${BOOKS_LIST_URL}:category/:book/`

// Tutorials
export const TUTORIALS_LIST_URL = `${APP_ROOT_URL}tutorials/`
export const TUTORIAL_FOR_CATEGORY_URL = `${APP_ROOT_URL}tutorials/:category/`
export const TUTORIAL_SHOW_URL = `${APP_ROOT_URL}tutorials/:category/:tutorial/`

// Softwares
export const SOFTWARES_LIST_URL = `${APP_ROOT_URL}softwares/`
export const SOFTWARES_FOR_CATEGORY_URL = `${APP_ROOT_URL}softwares/:category/`
export const SOFTWARES_SHOW_URL = `${APP_ROOT_URL}softwares/:category/:software/`


// Forum
export const FORUM_URL = `${APP_ROOT_URL}forum`
export const NEW_FORUM_TOPIC_URL = `${APP_ROOT_URL}forum/topic/new/`
export const FORUM_THREAD_URL = `${APP_ROOT_URL}forum/topic/thread/`
export const FORUM_TOPIC_URL = `${APP_ROOT_URL}forum/topic/`

export const DONATE_URL = `${APP_ROOT_URL}/donate`

// ???
export const ADD_AD_URL = `${APP_ROOT_URL}add-ad/`

// ???
export const SUBSCRIPTION_URL = `${APP_ROOT_URL}subscription/`

// ???
export const ANNONCE_PUB = `${APP_ROOT_URL}annoncespub/`



