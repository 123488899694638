import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGOUT,
    SET_MESSAGE,
    VERIFY_REGISTRATION_SUCCESS,
} from './types'

import AuthService from '../../services/auth.service'
import { log } from '../../helpers'
//import { getLocalStorageItem } from '../../helpers/functions'


export const register = (registerPayload) => (dispatch) => {
    return AuthService.register(registerPayload)
        .then(
            response => {

                //log('Register response', response)

                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: { user: response.data }
                })

                // dispatch({
                //     type: SET_MESSAGE,
                //     payload: {}
                // })

                return Promise.resolve(response)
            },
            error => {

                log('Register error', error)

                dispatch({ type: REGISTER_FAIL })

                dispatch({
                    type: SET_MESSAGE,
                    payload: error.message
                })

                return Promise.reject()
            }
        )
}

export const login = (username, password) => (dispatch) => {

    return AuthService.login({ email: username, password })
        .then(
            response => {
                //log('Login response', data)

                const user = response.user
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user }
                })

                dispatch({
                    type: SET_MESSAGE,
                    payload: { type: 'success', content: 'Log In Success' }
                })

                return Promise.resolve(response)
            },
            error => {
                log('Login error', error.data.detail)
                dispatch({
                    type: LOGIN_FAIL,
                    message: error,
                    payload: { user: null }
                })

                return Promise.reject(error)
            }
        )

}

export const logout = () => (dispatch) => {
    AuthService.logout()
    dispatch({ type: LOGOUT })

}

export const verifyRegistration = (verifyRegistrationPayload) => (dispatch) => {
    return AuthService.verifyRegistration(verifyRegistrationPayload)
        .then(response => {

            dispatch({ type: VERIFY_REGISTRATION_SUCCESS })

            dispatch({
                type: SET_MESSAGE,
                payload: { type: 'ACCOUNT_VERIFIED', content: 'Votre compte a été activé, vous pouvez désormais vous y connecter' }
            })

            return Promise.resolve(response)
        })
        .catch(error => {
            log('Action error', error)
            return Promise.reject(error)
        })
}

export const resetLink = (resetPayload) => (dispatch) => {
    return AuthService.resetLink(resetPayload)
        .then(
            response => {

                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: { user: response.data }
                })

                return Promise.resolve(response)
            },
            error => {
                dispatch({ type: REGISTER_FAIL })
                dispatch({
                    type: SET_MESSAGE,
                    payload: error.message
                })

                return Promise.reject()
            }
        )
}

export const changePassword = (payload) => (dispatch) => {
    return AuthService.resetPassword(payload)
        .then(
            response => {

                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: { user: response.data }
                })

                return Promise.resolve(response)
            },
            error => {
                dispatch({ type: REGISTER_FAIL })
                dispatch({
                    type: SET_MESSAGE,
                    payload: error.message
                })

                return Promise.reject()
            }
        )
}