import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/fr";
import Ad from "../ad";
import { useTranslation } from "react-i18next";
const TutorialItem = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="post_item col-xs-12 col-sm-4 col-md-4">
      {props?.type == "pub" ? (
        <Ad category={localStorage.getItem("tutoriel")} />
      ) : (
        <div className="post_item_cover">
          <div className="post_img">
            <img src={props?.item?.thumbnail_url} alt="" />
            <Link
              to={"/tutorial-detail?id=" + props?.item?.id }
              className="btn_video"
              title="#"
            ></Link>
          </div>
          <div className="post_text">
            <div className="displayflex">
              <h3 className="post_name">
                <Link
                  to={"/tutorial-detail?id=" + props?.item?.id }
                  title="#"
                >
                  {props?.item?.name}
                </Link>
              </h3>
              <div className="pot_widthkey">
                <span className="post_from">{props?.item?.author}</span>
                <span className="post_key active"></span>
              </div>
            </div>
            <div className="post_date_and_views displayflex">
              <div className="post_date">
                <Moment locale={i18n.language} fromNow>
                  {props?.item?.pub_date}
                </Moment>
              </div>
              <div className="nb_download">
                {props?.item?.number_seen} {t("common.views")}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TutorialItem;
