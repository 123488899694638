import React from "react";
import { connect } from "react-redux";
import { advert } from "../services/main.service";

const Ad = (props) => {
  const [adverts, setAdverts] = React.useState([]);
  let i = 0;
  React.useEffect(() => {
    advert(
      props.category
        ? props.category
        : props.type == "book"
        ? props.book?.pk
        : props.software?.pk
    )
      .then((res) => {
        if (res.status == 200) {
          if (res.data.results && res.data.results.length == 0) {
            advert(props.type == "book" ? props.book?.pk : props.software?.pk)
              .then((res) => {
                if (res.status == 200) {
                  setAdverts(res.data.results);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setAdverts(res.data.results);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.category]);
  return (
    <>
      {adverts?.map((a, key) => {
        if (a.location == props?.position) i++;
        return (
          i > 0 &&
          i <= 1 && (
            <div className="banniere homebloc " style={{marginTop: "10px",width:"100%"}}>
              <div className="container">
                <a href={a.clickable_link ? a.clickable_link : "#"}>
                  <div
                    className="homebloc_cover banniere_content displayflex"
                    style={{
                      // background: "#F7F7F7",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "62px",
                      color: "#000",
                      height:"250px"
                      
                    }}
                  >
                    <img
                      src={a.images[0].image}
                      style={{ height: "100%", objectFit: "cover", width:"100%" }}
                    />
                  </div>
                </a>
              </div>
            </div>
          )
        );
      })}
    </>
  );
};

export default connect((state) => ({
  category: state.advertcategory.category,
  book: state.menus.book,
  software: state.menus.software,
}))(Ad);
