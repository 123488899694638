import { useState, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { HTTP_DOMAIN } from "../api/pythonBackend";

const HTTP_SCHEME = "wss"
/**
 * A high level hook that uses websocket to handle the connection to the backend and interact with the AI ChatBot.
 * It takes:
 *  {sockBaseUrl, authToken, unableToSendMessageCb, onError, onClose, onOpen}
 * it returns
 * {  ask(question: string), messageHistory, connectionStatus,isConnected}
 *
 * @param {Object} param0 various parameters to control where and how to etablish the WS connection
 * @returns Object
 * @author Adonis SIMO <simoadonis@yahoo.fr>
 */
export default function useAiChatAssistant({
  /**
   * The authentication token used for the WebSocket connection.
   * @type {string}
   */
  authToken,

  /**
   * Callback function called when the WebSocket is unable to send a message.
   * @type {function}
   */
  unableToSendMessageCb,

  /**
   * Callback function called when an error occurs in the WebSocket connection.
   * @type {function}
   */
  onError,

  /**
   * Callback function called when the WebSocket connection is closed.
   * @type {function}
   */
  onClose,

  /**
   * Callback function called when the WebSocket connection is opened.
   * @type {function}
   */
  onOpen,
}) {
  const [messageHistory, setMessageHistory] = useState([]);
  const [lastCloseEvent, setlastCloseEvent] = useState();
  const [isConnected, setIsConnected] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [isWriting, setIsWriting] = useState(false);

  let connectionStatus;
  let upstreamUrl = `${HTTP_SCHEME}//${HTTP_DOMAIN}/ws/ai-assistant?token=${authToken}`;
  const { sendMessage, lastMessage, lastJsonMessage, readyState } =
    useWebSocket(upstreamUrl, {
      onOpen: (event) => {
        setIsConnected(true);
        if (onOpen) {
          onError(event);
        }
      },
      onClose: (event) => {
        setIsConnected(false);
        if (onClose) {
          onError(event);
        }
      },
      onError: (event) => {
        setIsConnected(false);
        if (onError) {
          onError(event);
        }
      },
      shouldReconnect: (closeEvent) => {
        if (
          closeEvent.reason === "authentication failed" &&
          closeEvent.isTrusted
        ) {
          setlastCloseEvent(closeEvent);
          setIsConnected(false);
          return false;
        }

        if (closeEvent.reason === "" && closeEvent.isTrusted) {
          setlastCloseEvent(closeEvent);
          setIsConnected(false);
          return false;
        }
        return true;
      },
      reconnectAttempts: 10,
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    });

  useEffect(() => {
    // process if error first
    // various error are: validation-error, json parsing error (we should parse ourselve),
    /**
     *{"error": "billing-error", 'content': 'Unable to read provided data'  }
      {"error": "daily-limit",'content': 'Limit journaliere de token atteinte' }
      {'error': 'auth-error', 'content': 'Authentication credentials were not provided.'}
      {'error': 'billing-error', 'content': 'You do not have permission to perform this action.'}
      {'error': 'validation-error', 'content': e.detail}
      {'error': 'server-error', 'content': str(e)}
     */
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.hasOwnProperty("error")) {
        setError(lastJsonMessage);
        return;
      }

      if (lastJsonMessage["response"] === "####") {
        setIsWriting(false);
      } else {
        setMessageHistory((prev) => prev.concat(lastJsonMessage["response"]));
        setMessage(lastJsonMessage["response"]);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    connectionStatus = {
      [ReadyState.CONNECTING]: "Connecting",
      [ReadyState.OPEN]: "Open",
      [ReadyState.CLOSING]: "Closing",
      [ReadyState.CLOSED]: "Closed",
      [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];
  }, [readyState]);

  const askQuestion = (question, language, category) => {
    if (readyState === ReadyState.OPEN) {
      question = {
        content: question,
        language: language,
        category: category,
      };
      sendMessage(JSON.stringify(question));
      setMessageHistory([]); // reset message history
      return true;
    } else {
      if (unableToSendMessageCb) {
        unableToSendMessageCb(lastCloseEvent);
      }
      return false;
    }
  };

  return {
    /**
     * The function that ask a question about the document
     */
    ask: askQuestion,

    /**
     * Contains the list of emitted messages as history
     */
    messageHistory,

    /**
     * Hold the websocket connection status
     */
    connectionStatus,

    /**
     * Determine if the connection is able to send message or not
     * Boolean
     */
    isConnected,

    /**
     * Contain the last message receive from the connection
     */
    message,

    /**
     * Contain the last JSON formatted error that happened
     */
    error,

    /**
     * Tell if the backend is writing response text or not
     */
    isWriting,
  };
}
