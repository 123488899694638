export { default as importComponent } from './importComponent'
export { default as asyncComponent } from './asyncComponent'
export { default as wait } from './wait'
export { default as log } from './debug'

// export { default as storeInLocalStorage } from './functions'
// export { default as getFromLocalStorage } from './functions'
export { default as validator } from './validator'
export { default as hisotry } from './history'

// export { default as getLocalStorageItem } from './functions'
// export { default as setLocalStorageItem } from './functions'
// export { default as removeLocalStorageItem } from './functions'
// export { default as removeAllLocalStorageItems } from './functions'