import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import {
  analysedDocument,
  uploadDocumentForAnalysis,
} from "../../../services/main.service";
import UploadLoader from "./UploadLoader";

import "./AnalyseUpload.css";
import AnalyseForm from "./analyse-form/AnalyseForm";
import UploadedDocItem from "./UploadedDocItem";
import { getToken } from "../../../api/pythonBackend";
import { Alert } from "@material-ui/lab";


const AnalyseUpload = (props) => {

  const [data, setData] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [progression, setProgression] = React.useState(0);
  const [document, setDocument] = React.useState(null);
  const [loadingAnalysedDoc, setLoadingAnalyseDoc] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("")

  const { t } = useTranslation();
  const token = getToken()


  React.useEffect(() => {

    (async () => {
      setLoadingAnalyseDoc(true);
      analysedDocument().then((res) => {
        setData(res.data.results);
        setLoadingAnalyseDoc(false)
      });

    })();

  }, []);


  const handleUploadDocument = (payload) => {
    setLoad(true);
    uploadDocumentForAnalysis(payload, (progressEvent) => {
      const percentage = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgression(percentage);
    }).then((response) => {
      setDocument(response.data);
      setLoad(false);
      setUploaded(true);
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 500 || error.response.status === 400) {
          if (error.response.data.hasOwnProperty('code'))
            setErrorMessage(t(`chatdoc.errors.${error.response.data.code}`))
          console.log("An error on the backend.", error.response.data)
        }
      }
    });
  };

  let canUpload = false;

  if (props.featureMap.hasOwnProperty('AI_CHATDOC')){
    canUpload = props.featureMap['AI_CHATDOC'].value
  }
 
  return (
    <>
      <Header menu={6} title={t("chatdoc.home_page_title")} />
      <div id="content" className="content page_categorie page_documents">
        <div className="contentbar displayflex">
          <div id="analyse-upload-wrapper">
            <div>
              <h2>{t('chatdoc.title')}</h2>
              <div>
                <p>{t('chatdoc.heading')}</p>
              </div>
            </div>

            {!canUpload && (
              <div className="mx-auto py-4">
                <Alert severity="error" action={<Link className="btn btn-primary btn-sm" to="/subscription-plan/">Plans de souscription disponible</Link>}>
                  Vous n'avez pas access a cette fonctionnalite, veuillez choisir <Link className="text-primary" to="/subscription-plan/"><u>le plan de souscription</u> </Link> adequat  
                </Alert>
              </div>
            )}

            {errorMessage && (
              <div className="mx-auto py-4">
                <Alert severity="error" action={<Link className="btn btn-primary btn-sm" to="/subscription-plan/">Plans de souscription disponible</Link>}>
                  {errorMessage}
                </Alert>
              </div>  
            )}

            {!uploaded && (
              <div>
                {canUpload && ( 
                  <div className="uploader">
                    {(!load ) && (
                      <AnalyseForm
                        handleUpload={handleUploadDocument}
                        user={props.user}
                      />
                    )}

                    {load && (
                      <div className="uploader-loader">
                        <UploadLoader progression={progression} />
                        {progression === 100 && <p> {t('chatdoc.import_loading')}</p>}
                      </div>
                    )}
                  </div>
                )}
              </div>
              
            )}

            {uploaded && (
              <div className="upload-status">
                {!document && (
                  <div>
                    {t('chatdoc.import_success')} 
                  </div>
                )}

                {document && (
                  <div>
                    <p>{t('chatdoc.import_success')} </p>
                    <button className="ds-btn primary">
                      <Link to={`/analyse/${document.pk}`}>
                        {t('chatdoc.analyse_btn_title')}
                      </Link>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {token !== '' &&
          <section className="container">
            <div className="row">
              <div className='col-sm' >
                <h2> {t('chatdoc.analysed_document_title')}&nbsp;
                  {!loadingAnalysedDoc && data && <>({data.length})</>}
                </h2>
              </div>
            </div>
            <div className="row">
              {loadingAnalysedDoc && <div className='col-sm' ><p>Loading....</p></div>}

              {!loadingAnalysedDoc && data && (
                data.map((item, i) => (
                  <div className='col-md-3 col-12 mb-4' key={i}>
                    <UploadedDocItem item={item} index={i} maxWidth="27rem" />
                  </div>
                ))
              )}
            </div>
          </section>}
      </div>
      <Footer />
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
  featureMap: state.auth.featureMap
}))(AnalyseUpload);
