import api, {
    LOGIN_API_ENPOINT,
    REGISTER_API_ENPOINT,
    LOGOUT_API_ENPOINT,
    VERIFY_REGISTRATION_API_ENPOINT,
    SEND_RESET_PASSWORD_LINK_API_ENPOINT,
    RESET_PASSWORD_API_ENPOINT
} from '../api/pythonBackend'

import storage from '../helpers/functions'
import { STORED_USER_KEY } from '../constants/app'
import { log } from '../helpers'
import { useCookies } from 'react-cookie';


/**
 * Save a new user
 * @param {*} registerPayload user credentials
 * required fields are username, email, password and confirm password
 */
const register = (registerPayload) => {

    return api.post(REGISTER_API_ENPOINT, registerPayload)
        .then((response) => {
            return { status: response.status, data: response.data }
        })
        .catch((error) => {
            //log('Register error api ', (error.response ? error.response : error))
            return error.response
                ? error.response
                : { error: 'Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci' }
        })
}



const verifyEmail = (verifyEmailPayload) => {

}


/**
 * Login and create user session
 * @param {*} loginPayload user credentials, Username or email and password
 */
const login = (loginPayload) => {
    //log('Login credentials', loginPayload)
    
    return api.post(LOGIN_API_ENPOINT, loginPayload)
        .then(response => {


            // <if user && user.accessToken>
            if (response.data) {
                
                const user = { accessToken: response.data.access }
              
                localStorage.setItem(STORED_USER_KEY, response.data.access)
                return { status: response.status, message: "Connexion reussi", user }
            }
        })
        .catch(error => {
            //log('Error API', error.response)
            return error.response ? error.response : { error: 'Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci' }
        })
}


/**
 * Destroy user session
 */
const logout = (token) => {
    // api.post(LOGOUT_API_ENPOINT, { revoke_token: true })
    //     .then((response) => {
    //         log('Logout response', response)
    storage.removeLocalStorageItem(STORED_USER_KEY)
    return true
    //     })
    // return false
}

// const resetPassword = (ResetPasswordPayload) => {

// }

const verifyRegistration = (payload) => {
    log('Verify payload', payload)
    return api.post(VERIFY_REGISTRATION_API_ENPOINT, payload)
        .then(response => {
            log('Api Verify response', response)
            return { status: response.status, message: response.data.detail }
        })
        .catch(error => {
            return error.response ? error.response : { error: 'Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci' }
        })
}

/**
 * Send a new reset password link
 * @param {*} resetPayload user credentials
 * required fields are username, email
 */
 const resetLink = (resetPayload) => {

    return api.post(SEND_RESET_PASSWORD_LINK_API_ENPOINT, resetPayload)
        .then((response) => {
            return { status: response.status, data: response.data }
        })
        .catch((error) => {
            //log('Register error api ', (error.response ? error.response : error))
            return error.response
                ? error.response
                : { error: 'Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci' }
        })
}

/**
 * Change password
 * @param {*} payload new password and confirmation
 * required fields are password, passwordConfirm, user_id
 */
 const resetPassword = (payload) => {

    return api.post(RESET_PASSWORD_API_ENPOINT, payload)
        .then((response) => {
            return { status: response.status, data: response.data }
        })
        .catch((error) => {
            //log('Register error api ', (error.response ? error.response : error))
            return error.response
                ? error.response
                : { error: 'Une errueur est survenue lors du traitement. Veuillez réessayer plustard. Merci' }
        })
}
export default {
    register,
    login,
    verifyEmail,
    logout,
    verifyRegistration,
    resetLink,
    resetPassword
}