export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const VERIFY_REGISTRATION_SUCCESS = "VERIFY_REGISTRATION_SUCCESS";
export const VERIFY_REGISTRATION_FAIL = "VERIFY_REGISTRATION_FAIL";
export const SET_CATEGORY = "SET_CATEGORY";
export const CLEAR_CATEGORY = "CLEAR_CATEGORY";
export const IS_SUBSCRIBE = "IS_SUBSCRIBE";
export const SET_CURRENT_SUBSCRIPTION = "SET_CURRENT_SUBSCRIPTION";
export const FEATURE_MAP_UPDATE = "FEATURE_MAP_UPDATE";
export const SET_MENU = "SET_MENU";
export const SET_BOOK = "SET_BOOK";
export const SET_SOFTWARE = "SET_SOFTWARE";
export const ADD_CATEGORY = "ADD_ATEGORY";
export const SET_SUB_CATEGORY = "SET_SUB_CATEGORY";
