import React from "react";
import { useTranslation } from "react-i18next";
import { socialLogin } from "../services/main.service";
import SocialButton from "./SocialButton";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "../redux/actions/types";
import { useCookies } from "react-cookie";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const SocialLoging = (props) => {
  const [type, setType] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["token"]);

  const handleSocialLogin = (user) => {
    const data = {
      access_token: user._token?.accessToken,
      id_token: user._token.idToken,
    };
    let profil = {
      first_name: user._profile?.firstName,
      profile_picture: user._profile?.profilePicURL,
    };
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { user: profil },
    });
    setLoad(true);
    socialLogin(type, data)
      .then((res) => {
        if (res.status == 200) {
          setCookie("token", res.data.access, {
            path: "/",
            maxAge: 3600 * 24 * 3,
          });
          localStorage.setItem("token", res.data.access);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: res.data.user },
          });
          setLoad(false);
          window.location = "/discussion";
        } else {
          setLoad(false);
          NotificationManager.error(
            res.data?.non_field_errors?.length > 0
              ? res.data.non_field_errors[0]
              : "Connexion impossible"
          );
        }
      })
      .catch((err) => {
        setLoad(false);
        NotificationManager.error("Connexion impossible");
        console.log(err);
      });
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };
  return (
    <ul className="socialconnect">
      <SocialButton
        provider="google"
        appId="426488594960-r6ih3o7gsa0o2v5kkau2k400dq65ha5n"
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={handleSocialLoginFailure}
        cla="connect_by_google"
        cli={() => setType("google")}
        key={"426488594960-r6ih3o7gsa0o2v5kkau2k400dq65ha5n"+props.key}
      >
        {t("login.google")}{" "}
        {load && (
          <span className="spinner-border spinner-border-sm ml-2"></span>
        )}
      </SocialButton>
      <li>{t("login.or")}</li>
      <NotificationContainer />
    </ul>
  );
};

export default SocialLoging;
