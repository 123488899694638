import React from "react";
import TutorialItem from "../items/TutorialItem";
import { useTranslation } from "react-i18next";
import Pagination from "../Pagination";
import {
  getReviews,
  tutotielDetail,
  tutotiels,
} from "../../services/main.service";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "../../services/main.service";
import ReactPlayer from "react-player/lazy";
import { connect } from "react-redux";
import Comments from "../Comments";
import Skeleton from "react-loading-skeleton";
import AddBookmark from "../AddBookmark";
import UserFollow from "../UserFollow";
import Search from "../Search";
import Like from "../Like";

const TutorialContent = (props) => {
  const [data, setData] = React.useState([]);
  const [reviews, setReviews] = React.useState([]);
  const { page } = useParams();
  const [currentPage, setCurrentPage] = React.useState(page ? page + "" : "1");
  const { t } = useTranslation();
  const [pageCount, setPageCount] = React.useState(0);
  const [load, setLoad] = React.useState(true);
  const [item, setItem] = React.useState(null);
  const query = useQuery();
  React.useEffect(() => {
    if (props?.type == "detail") {
      tutotielDetail(query?.get("id")).then((res) => {

        if (res.status == 200) setItem(res.data);
      });
      getReviews(query?.get("id")).then((res) => {
        if (res.status == 200) setReviews(res.data);
        setLoad(false);
      });
    } else {
      tutotiels(
        currentPage,
        query?.get("category") != "null" ? query?.get("category") : ""
      ).then((res) => {
        setPageCount(res.data.count / 10);
        if (res.status == 200) setData(res.data.results);
        setLoad(false);
      });
    }
  }, [currentPage, page, query?.get("id"), query?.get("category")]);

  return (
    <>
      <div className="centerbar">
        <div className="topbannersearch displayflex">
          <Search setData={setData} type="software" />
        </div>
        {props?.type == "detail" ? (
          <>
            <div className="beadcrumbs displayflex">
              <ul className="displayflex">
                <li>
                  <a href="#" title="#">
                  {t("common.tutorials")}
                  </a>
                </li>
                {/* <li>
                  <a href="#" title="#">
                    Catégories
                  </a>
                </li>
                <li>Sous catégories</li> */}
              </ul>
              {item && <AddBookmark id={item?.id} />}
            </div>
            <div className="details_img"> 
              <ReactPlayer url={item?.youtube_url} controls={true} />
            </div>
            <div className="postdetailscover">
              <div className="details_lguser displayflex">
                <div className="displayflex">
                  <div className="displayflex">
                    {item && (
                      <UserFollow owner={item.owner} authorname={item.author} />
                    )}
                  </div>
                </div>
                <div className="lguser_btn displayflex">
                  <div className="share_btn">
                    <a href="#" title="#"></a>
                  </div>
                  <div className="view_btn">
                    <a href="#" title="#">
                      {item?.number_seen}
                    </a>
                  </div>
                  {item && <Like likes={item.interests?.likes} id={item.id} /> }
                </div>
              </div>
              {item && !load && <Comments data={reviews} id={item?.id} />}
            </div>
          </>
        ) : (
          <>
            <div className="beadcrumbs displayflex">
              <ul className="displayflex">
                <li>
                  <a href="#" title="#">
                  {t("common.tutorials")}
                  </a>
                </li>
                {/* <li>
                  <a href="#" title="#">
                    Catégories
                  </a>
                </li>
                <li>Sous catégories</li> */}
              </ul>
              <div className="add_doc">
                <Link to="/upload?item=tutoriel">
                  <a title={t("common.add")}>+ {t("common.add")}</a>
                </Link>
              </div>
            </div>
            <div id="tutoriels" className="tutoriels row">
              {load && <Skeleton count={3} height={100} />}
              {data.map((item, i) => (
                <TutorialItem item={item} type={(i == 3 || i == 8) && "pub"} />
              ))}
              {data.length == 0 && (
                <div style={{ marginLeft: "20px" }}>{t("common.noresult")}</div>
              )}
            </div>
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(TutorialContent);
