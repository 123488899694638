import React from "react";

const UploadLoader = ({ progression }) => {
  React.useEffect(() => {
    setProgress(progression);
  }, [progression]);

  const setProgress = (percent) => {
    const progressBar = document.querySelector(
      "#uploader-loading .progress-bar"
    );
    const progressText = document.querySelector(
      "#uploader-loading .progress-text"
    );

    const circumference = 283; // Circonférence du cercle (2 * π * rayon)
    const offset = circumference - (percent / 100) * circumference;

    progressBar.style.strokeDashoffset = offset;
    progressText.textContent = `${percent}%`;
  };

  return (
    <svg id="uploader-loading" class="progress-container" viewBox="0 0 100 100">
      <circle class="progress-circle" cx="50" cy="50" r="45" />
      <circle class="progress-bar" cx="50" cy="50" r="45" />
      <text class="progress-text" x="50" y="50">
        0%
      </text>
    </svg>
  );
};

export default UploadLoader;
