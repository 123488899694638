import React from "react";
import { Link } from "react-router-dom";
import {
  forumComments,
  saveForumComment,
  useQuery,
} from "../services/main.service";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { API_ENDPOINT } from "../api/pythonBackend";
import Like from "./Like";
const ForumComment = (props) => {
  const query = useQuery();
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState();
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const limit = 10;
  const pagginationHandler = (page) => {
    setPage(page.selected + 1);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const datas = new FormData(e.target);
    setLoading(true);
    saveForumComment({
      message: datas.get("message"),
      title: datas.get("message"),
      parent: props?.id,
    })
      .then((res) => {
        setLoading(false);
        setMessage("");
        const result = [res.data];
        setData([...result, ...data]);
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    forumComments(page, props?.id).then((res) => {
      console.log("forum comment", res);
      if (res.status == 200) {
        setData(res.data.results);
        setStart(res.data?.results?.length);
        setEnd(end + res.data?.results?.length);
      }
    });
  }, [page]);

  return (
    <>
      {props.user && (
        <div className="forumform">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <textarea
                className="textarea form-control"
                placeholder="Laisser un commentaire"
                required
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="submitfield">
              <button type="submit" className="blue_button btn_submit">
                Envoyer
                {loading && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
      <div className="bloc_lastcomments accounttop forumbloc">
        <div className="titledubloc displayflex">
          <h2>Derniers commentaires</h2>
          <div className="nbresults_infos resultats">
            résultat{" "}
            <span>
              {start}-{end}
            </span>{" "}
            sur un total de <span>{data?.length}</span>
          </div>
        </div>
        <div className="details_list_users">
          {data?.map((item, i) => (
            <div className="lguser displayflex">
              <div className="lguser_picture">
                <img
                  src={
                    item?.author?.profile_picture || "/assets/images/avatar.png"
                  }
                  alt="Dastudy"
                  style={{ height: "180px", width: "100%", objectFit: "cover" }}
                />
              </div>
              <div className="lguser_texte displayflex">
                <div className="lguser_texte_left displayflex">
                  <div className="lguser_name">{item?.author?.first_name}</div>
                  <div className="lguser_excert">{item?.message}</div>
                  <div className="lguser_date">
                    {new Date(item?.created).toLocaleString()}
                  </div>
                </div>
                <div className="lguser_cmtsandlikes lguser_btn displayflex">
                  {/* <div className="nb_comments">30</div> */}
                  {item && (
                    <Like
                      likes={item.likes?.likes}
                      id={item.pk}
                      type="forum-comment"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel={"..."}
            breakClassName={"break-me"}
            activeClassName={"active"}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            initialPage={page - 1}
            pageCount={data?.length / limit}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={pagginationHandler}
          />
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(ForumComment);
