import React from "react";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";

const PageItem = (props) => {
  return (
    <div
      className="post_item col-xs-12 col-sm-12 col-md-12"
      style={props.marginTop && { marginTop: props.marginTop }}
    >
      <div className="post_item_cover displayflex">
        <div className="post_img">
          <Link to={"/" + props.item?.username} title="">
            <img
              src={props?.item?.profile_picture || "/assets/images/avatar.png"}
              alt="Dastudy"
            />
          </Link>
        </div>
        <div className="post_text">
          <h3 className="post_name">
            <Link to={"/" + props.item?.username} title="Avast antivirus">
              {props?.item?.first_name} {props?.item?.last_name}
            </Link>
          </h3>

          <div className="post_desc">
            <ShowMoreText lines={2} more=" " less=" ">
              {props?.item?.description}
            </ShowMoreText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageItem;
