import React from "react";
import {
  getLikes,
  likeOrDislike,
  isLikeReview,
} from "../services/main.service";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

const Like = (props) => {
  const [isLike, setIsLike] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [likes, setLikes] = React.useState(props?.likes || 0);
  const { t } = useTranslation();
  let history = useHistory();

  const loginModal = () => {
    swal(t("common.login"), {
      icon: "info",
      buttons: {
        confirm: {
          text: "OK",
          closeModal: true,
        },
        cancel: {
          text: "Annuler",
          visible: true,
        },
      },
      dangerMode: false,
      closeOnClickOutside: false,
    }).then((willDelete) => {
      if (willDelete) history.push("/login");
    });
  };
  React.useEffect(() => {
    isLikeReview(props?.slug, props.type, true).then((res) => {
      if (res.status == 200) {
        setIsLike(res.data?.interest);
      }
    });
  }, [refresh]);

  const savelikeOrdislike = (e) => {
    e.preventDefault();
    if (props?.user) {
      if (isLike) setLikes(likes > 0 ? likes - 1 : 1);
      else setLikes(likes + 1);
      const li = !isLike;
      setIsLike(!isLike);
      likeOrDislike(props?.id, li, props?.type).then((res) => {
        if (res.status == 201) {
          setRefresh(!refresh);
        }
        if (res.status == 200) {
          setLikes(res.data?.likes);
        }
      });
    } else {
      loginModal();
    }
  };
  return (
    <div className="like">
      <div className={isLike ? "like_btn" : "nb_likes"}>
        <a href="#" title="#" onClick={(e) => savelikeOrdislike(e)}>
          {likes}
        </a>
      </div>
    </div>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(Like);
