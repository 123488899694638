import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin, ...props } = this.props;
    return (
      <li className={this.props.cla} onClick={this.props.cli}>
        <a className="displayflex" href="#" onClick={triggerLogin} {...props}>
        <i className=""></i>
          {children}
        </a>
      </li>
    );
  }
}

export default SocialLogin(SocialButton);
