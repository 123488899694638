import React from 'react';
import ReactPDF from '@intelllex/react-pdf';

import './ScrollReader.scss';

const ScrollReader = (props) => {
  let _showToolbox = true;
  let _showProgressBar = true

  const { showProgressBar, showToolbox } = props;

  if (showToolbox !== undefined) {
    _showToolbox = showToolbox
  }

  if (showProgressBar !== undefined) {
    _showProgressBar = showProgressBar
  }

  if (!props.file) return <>Impossible d'ouvrir le livre</>;
  else {
    return (
      <div className="scroll-reader">
        <ReactPDF url={props.file} showProgressBar={_showProgressBar} showToolbox={_showToolbox} />
      </div>
    );
  }
};

export default ScrollReader;
