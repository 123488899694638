import React from "react";
import {
  followUser,
  getLikes,
  isFollow,
  likeOrDislike,
  unFollowUser,
  userPageId,
} from "../services/main.service";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Payment from "../components/Payment";

const Buy = (props) => {
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  let history = useHistory();
  React.useEffect(() => {}, [refresh]);

  const handleClick = (e) => {
    e.preventDefault();
    if (props?.user) {
    } else {
      history.push("/login");
    }
  };
  return (
    <>
      <button
        className="btn_link blue_button btn"
        onClick={(e) => handleClick(e)}
        data-toggle={props?.user ? "modal" : ""}
        data-target="#donation"
      >
        {t("common.buy")}
      </button>
      <div
        className="modal fade"
        id="donation"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h1 className="tileh1">{t("billing.pay")}</h1>
              <p>{t("billing.pay_text")} </p>
            </div>
            <Payment item={props.item} link="/factures" />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(Buy);
