import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useTranslation } from "react-i18next";
import { countries, submitFeedback } from "../../../services/main.service";
const Feedsbacks = () => {
  const [loading, setLoading] = React.useState(false);
  const [allCountries, setAllCountries] = React.useState([]);

  const { t } = useTranslation();
  const onSubmit = (e) => {
    e.preventDefault();
    const datas = new FormData(e.target);
    setLoading(true);
    submitFeedback(datas)
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.status == 201) {
          document.getElementById("sendFeedback").reset();
          NotificationManager.success("Votre message a été envoyé avec succès");
        } else {
          NotificationManager.error(
            "Erreur survenue lors de l'envoie du message"
          );
        }
      })
      .catch((err) => {
        NotificationManager.error(
          "Erreur survenue lors de l'envoie du message"
        );
        console.log(err);
      });
  };
  React.useEffect(() => {
    countries().then((res) => {
      if (res.status == 200) setAllCountries(res.data);
    });
  }, []);
  return (
    <>
      <Header title="Feedsbacks" />
      <div id="content" className="content page_feedback">
        <div className="container">
          <div className="feedback_cover row">
            <div className="feedback_image col-xs-12 col-sm-7 col-md-7">
              <img
                className="lazy"
                src="assets/images/feedback.jpg"
                alt="Dastudy"
              />
            </div>
            <div className="feedback_form col-xs-12 col-sm-5 col-md-5">
              <div className="feedback_form_cover">
                <h1 className="titleh1">Feedback</h1>
                <div className="feedback_desc">{t("feedback.title")}</div>
                <form onSubmit={onSubmit} id="sendFeedback">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("login.name")}
                      name="full_name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("login.profession")}
                      name="work"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder={t("login.email")}
                      name="email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="textarea form-control"
                      placeholder="Message"
                      name="message"
                      required
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <div className="select_field">
                      <select className="form-control" name="country" required>
                        <option value="">{t("login.country")}</option>
                        {allCountries.map((country) => (
                          <option value={country.name}>{country.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      name="city"
                      placeholder={t("login.city")}
                    />
                  </div>
                  <div className="submit_field">
                  <div className="form-check custom-control custom-checkbox">
                      <label className="checkcustom displayflex">
                        <span>{t("login.notrobot")}</span>
                        <input
                          type="checkbox"
                          name="is_name"
                          required
                          className="form-control"
                        />
                        <span className="checkicon" style={{background:'#e9ecef'}}></span>
                      </label>
                   </div> 
                    <button type="submit" className="blue_button btnSubmit">
                      {" "}
                      {t("common.send")}
                      {loading && (
                        <span className="spinner-border spinner-border-sm ml-2"></span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Feedsbacks;
